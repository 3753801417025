import { createContext } from 'react';
import { useQuery } from 'react-query';
import { PermintaanUpahProduksiApi } from 'api';
import { useQueryDropdown } from 'config/useQueryDropdown';

export const DropdownTransferUpahContext = createContext();

export const DropdownTransferUpahContextProvider = ({ children }) => {
  const dropdownMenuKey = 'transfer_upah_produksi';

  const dropdownStatusPermintaan = [
    { value: 'waiting', label: 'Waiting' },
    { value: 'on_progress', label: 'On Progress' },
    { value: 'done', label: 'Done' },
  ];

  const dropdownVendor = useQuery(
    ['dropdown', 'vendor', dropdownMenuKey],
    () =>
      PermintaanUpahProduksiApi.getDropdownKontrakUpah({ tipe: 'vendor' }).then(
        (res) =>
          res.data.data.map((k) => ({
            ...k,
            value: k.id_vendor,
            label: k.nama_vendor,
          }))
      ),
    useQueryDropdown
  );

  const dropdownSatuan = useQuery(
    ['dropdown', 'satuan', dropdownMenuKey],
    () =>
      PermintaanUpahProduksiApi.getDropdownKontrakUpah({ tipe: 'satuan' }).then(
        (res) =>
          res.data.data.map((k) => ({
            value: k.id_satuan,
            label: k.nama_satuan,
          }))
      ),
    useQueryDropdown
  );

  const dropdownCustomer = useQuery(
    ['dropdown', 'customer', dropdownMenuKey],
    () =>
      PermintaanUpahProduksiApi.dropdown({ tipe: 'customer' }).then((res) =>
        res.data.data.map((k) => ({
          value: k.id_customer,
          label: k.nama_customer,
        }))
      ),
    useQueryDropdown
  );

  const dropdownProyek = useQuery(
    ['dropdown', 'proyek', dropdownMenuKey],
    () =>
      PermintaanUpahProduksiApi.dropdown({ tipe: 'proyek' }).then((res) =>
        res.data.data.map((k) => ({
          value: k.id_proyek,
          label: k.nama_proyek,
        }))
      ),
    useQueryDropdown
  );

  const dropdownItem = useQuery(
    ['dropdown', 'item', dropdownMenuKey],
    () =>
      PermintaanUpahProduksiApi.dropdown({ tipe: 'item' }).then((res) =>
        res.data.data.map((k) => ({
          value: k.id_item_buaso,
          label: `${k.kode_item} - ${k.nama_item}`,
        }))
      ),
    useQueryDropdown
  );

  return (
    <DropdownTransferUpahContext.Provider
      value={{
        dropdownStatusPermintaan,
        dropdownVendor,
        dropdownSatuan,
        dropdownCustomer,
        dropdownProyek,
        dropdownItem,
      }}
    >
      {children}
    </DropdownTransferUpahContext.Provider>
  );
};

export const withDropdownTransferUpahContextProvider =
  (Component) => (props) => {
    return (
      <DropdownTransferUpahContextProvider>
        <Component {...props} />
      </DropdownTransferUpahContextProvider>
    );
  };
