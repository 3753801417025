import { Fragment, useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col, Tab, Nav, Card } from 'react-bootstrap';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  BackButton,
  ActionButton,
} from 'components';
import { PermintaanUpahProduksiApi } from 'api';
import {
  InfoDetailJobOrder,
  ModalFormKontrak,
  ModalFormRegistrasi,
  ModalKonfirmasi,
  TabLabourCost,
  TabLabourCostFinishing,
} from './Components';
import {
  TransferUpahProduksiContext,
  withDropdownTransferUpahContextProvider,
  withTransferUpahProduksiProvider,
} from './Context';
import { useMockNumber } from './Hooks';
import axios from 'axios';

const NavTab = ({ eventKey, title }) => (
  <Nav.Item>
    <Nav.Link eventKey={eventKey}>{title}</Nav.Link>
  </Nav.Item>
);

const TabPane = ({ eventKey, component }) => (
  <Tab.Pane eventKey={eventKey}>{component}</Tab.Pane>
);

const TambahTransferUpahProduksi = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id_permintaan_produksi } = useParams();
  const [generateMockNumber] = useMockNumber();
  const {
    dataTransfer,
    dataJobOrder,
    setJobOrder,
    modalForm,
    dataPermintaan,
    setDataPermintaan,
    setDataHistory,
    setModalKonfirmasi,
  } = useContext(TransferUpahProduksiContext);

  const [isPageLoading, setPageLoading] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [alert, setAlert] = useState({
    variant: 'primary',
    text: '',
  });

  const onSubmit = () => {
    setSubmitting(true);

    const dataStatusPermintaan = {
      labourcost:
        dataPermintaan?.lc.map((item) => ({
          id_permintaan_produksi_lc: item.id_permintaan_produksi_lc,
          status_permintaan: item.status_permintaan,
        })) ?? [],

      labourfinishing:
        dataPermintaan?.lcf.map((item) => ({
          id_permintaan_produksi_lcf: item.id_permintaan_produksi_lcf,
          status_permintaan: item.status_permintaan,
        })) ?? [],
    };

    const dataKontrakUpah = {
      data: Object.values(dataTransfer)
        .filter((item) => item.length)
        .flat(),
    };

    axios
      .all([
        PermintaanUpahProduksiApi.updateStatusPermintaan(dataStatusPermintaan),
        PermintaanUpahProduksiApi.saveKontrakUpah(dataKontrakUpah),
      ])
      .then(() =>
        history.push('/transaksi/transfer-upah-produksi', {
          alert: {
            variant: 'primary',
            text: 'Tambah Data Transfer Upah Produksi Berhasil',
          },
        })
      )
      .catch(({ response }) =>
        setAlert({
          variant: 'danger',
          text: `Gagal Menyimpan Data Transfer Upah Produksi ${
            response?.data?.message ?? ''
          }`,
        })
      )
      .finally(() => {
        setSubmitting(false);
        setModalKonfirmasi(false);
      });
  };

  const getInitialData = () => {
    setPageLoading(true);

    PermintaanUpahProduksiApi.single({ id_permintaan_produksi })
      .then((res) => {
        const { labour_cost, labour_cost_finishing, history, ...jobOrder } =
          res?.data?.data;

        setJobOrder(jobOrder ?? {});
        setDataPermintaan({
          lc: labour_cost?.map((item) => ({
            ...item,
            status_permintaan: item.status_permintaan ?? 'waiting',
          })),
          lcf: labour_cost_finishing?.map((item) => ({
            ...item,
            status_permintaan: item.status_permintaan ?? 'waiting',
          })),
        });
        setDataHistory({
          lc: history?.filter((item) => item.ref_kontrak_upah === 'klc') ?? [],
          lcf: history?.filter((item) => item.ref_kontrak_upah === 'klf') ?? [],
        });
      })
      .catch(() => setAlert({ text: 'Data gagal dimuat', variant: 'danger' }))
      .finally(() => setPageLoading(false));
  };

  useEffect(() => {
    setNavbarTitle('Transfer Upah Produksi');
    getInitialData();
  }, []);

  return (
    <CRUDLayout>
      <Row className="d-flex align-items-end justify-content-between mb-2">
        <Col>
          <div>Detail Data Job Order </div>
        </Col>
        <Col className="d-flex justify-content-end align-items-end">
          <BackButton onClick={() => history.goBack()} />
        </Col>
      </Row>

      <Alert
        showCloseButton
        className="mt-2"
        show={!!alert?.text?.length}
        variant={alert.variant}
        text={alert.text}
        onClose={() => setAlert((prev) => ({ ...prev, text: '' }))}
      />

      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <Fragment>
          <InfoDetailJobOrder data={dataJobOrder} />

          <Card className="mt-4">
            <Tab.Container defaultActiveKey="lc">
              {/* Tab Navigation */}
              <Card.Header>
                <Nav variant="tabs" defaultActiveKey="lc">
                  <NavTab eventKey="lc" title="Labour Cost" />
                  <NavTab eventKey="lcf" title="Labour Cost Finishing" />
                </Nav>
              </Card.Header>

              {/* Tab Content */}
              <Card.Body>
                <Tab.Content>
                  <TabPane eventKey="lc" component={<TabLabourCost />} />
                  <TabPane
                    eventKey="lcf"
                    component={<TabLabourCostFinishing />}
                  />
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </Card>

          <Row className="d-flex justify-content-end align-items-center px-3 pt-4 pb-2 ">
            <ActionButton
              text="Simpan"
              disable={isSubmitting}
              loading={isSubmitting}
              onClick={() => setModalKonfirmasi(true)}
            />
          </Row>
        </Fragment>
      )}

      {modalForm.showRegistrasi && (
        <ModalFormRegistrasi generateMockNumber={generateMockNumber} />
      )}

      {modalForm.showKontrak && <ModalFormKontrak />}

      <ModalKonfirmasi handleSubmit={onSubmit} isSubmitting={isSubmitting} />
    </CRUDLayout>
  );
};

export default withDropdownTransferUpahContextProvider(
  withTransferUpahProduksiProvider(TambahTransferUpahProduksi)
);
