import Services from '../../../services';

class ItemBahanApi {
  get(data) {
    return Services.get('/vendor_item_bahan?id_vendor=' + data);
  }

  single(id) {
    return Services.get('/vendor_item_bahan/single?id_item_bahan_vendor=' + id);
  }

  getKelompok() {
    return Services.get('/vendor_item_bahan/kelompok_bahan');
  }

  getJenis(data) {
    return Services.get('/vendor_item_bahan/jenis_bahan?id_kelompok=' + data);
  }

  getItem(data) {
    return Services.get('/vendor_item_bahan/item_bahan?id_jenis=' + data);
  }

  create(value) {
    return Services.post('/vendor_item_bahan', value);
  }

  delete(value) {
    return Services.post('/vendor_item_bahan/delete', value);
  }
}

export default new ItemBahanApi();
