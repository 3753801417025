import Services from '../../services';

class HargaSatuanPrelimApi {
  page(params) {
    return Services.get(`/harga_satuan_prelim/page`, { params });
  }

  update(data) {
    return Services.put('/harga_satuan_prelim', data);
  }
}

export default new HargaSatuanPrelimApi();
