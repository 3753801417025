import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import Routes from './routes';
import { AuthContextProvider } from './utilities';
import './styles/css/App.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
      },
    },
  });

  return (
    <AuthContextProvider>
      <QueryClientProvider client={queryClient}>
        <Routes />
      </QueryClientProvider>
    </AuthContextProvider>
  );
};

export default App;
