import { Row, Col, Card } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { InfoItemVertical } from 'components';
import { DateConvert } from 'utilities';

export const InfoSection = () => {
  const { values } = useFormikContext();

  const STATUS_APPROVAL = {
    PEN: 'PENDING',
    APP: 'APPROVED',
    REJ: 'REJECTED',
    VER: 'VERIFIED',
    REV: 'REVISE',
  };

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col>
            <InfoItemVertical
              label="Tgl. Purchase Order"
              text={DateConvert(new Date(values.tgl_purchase_order)).detail}
            />
          </Col>

          <Col>
            <InfoItemVertical
              label="No. Purchase Order"
              text={values.no_purchase_order}
            />
          </Col>

          <Col>
            <InfoItemVertical
              label="Status Approval"
              text={STATUS_APPROVAL[values.status_approval]}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <InfoItemVertical
              label="Metode Pembayaran"
              text={values.pembayaran}
            />
          </Col>

          <Col>
            <InfoItemVertical
              label="Tgl. Jatuh Tempo"
              text={DateConvert(new Date(values.tgl_jatuh_tempo)).detail}
            />
          </Col>

          <Col>
            <InfoItemVertical
              label="Status Purchase Order"
              text={values.status_purchase_order}
            />
          </Col>
        </Row>

        <InfoItemVertical
          label="Alamat Tujuan Pengiriman"
          text={values.alamat_tujuan_pengiriman}
        />

        <InfoItemVertical
          label="Keterangan Purchase Order"
          text={
            <pre
              style={{
                fontFamily: 'Roboto',
                fontSize: 13,
                lineHeight: 1.7,
              }}
            >
              {values.keterangan}
            </pre>
          }
        />
      </Card.Body>
    </Card>
  );
};
