import React, { useState, useEffect } from 'react';

import Axios from 'axios';
import { TableNumber } from '../../../utilities';
import { Link, useHistory, useLocation } from 'react-router-dom';

import {
  CRUDLayout,
  InputSearch,
  CreateButton,
  UpdateButton,
  DeleteButton,
  DataStatus,
  Pagination,
  THead,
  TBody,
  ThFixed,
  Switch,
  TdFixed,
  Tr,
  Th,
  Td,
  Alert,
  DeleteModal,
  ActionButton,
  ReadButton,
} from '../../../components';
import { Row, Col, ButtonGroup } from 'react-bootstrap';

import { VendorApi } from '../../../api';

const Vendor = ({ setNavbarTitle }) => {
  let history = useHistory();
  const location = useLocation();

  // Title
  const title = 'Master Vendor';

  // indikator pemanggilan data sedang dimuat di server
  const [isLoading, setIsLoading] = useState(false);

  // indikator apakah sedang melakukan searching
  const [isSearching, setIsSearching] = useState(false);

  // menampung value dari search form
  const [searchKey, setSearchKey] = useState('');

  // Data View
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [dataCount, setDataCount] = useState(0);

  // Data Pengolahan
  const [deleteData, setDeleteData] = useState(false);
  const [isDeleteData, setIsDeleteData] = useState(false);

  // alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
  });
  let createAlert = location?.state?.variant;

  const getData = () => {
    setIsLoading(true);
    // setShowAlert(false)
    setIsSearching(false);

    Axios.all([VendorApi.getPage(page, dataLength, searchKey)])
      .then(
        Axios.spread((res) => {
          setData(res.data.data);
          setTotalPage(res.data.total_page);
          setDataCount(res.data.data_count);
        })
      )
      .catch((err) => alert(err))
      .finally(() => {
        if (searchKey != '') {
          setAlertConfig({
            variant: 'primary',
            text: `Hasil Pencarian : ${searchKey}`,
          });
          setShowAlert(true);
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    // set judul di Navbar
    setNavbarTitle('Master Vendor');

    if (createAlert !== undefined) {
      setAlertConfig({
        variant: location?.state?.variant,
        text: location?.state?.text,
      });
      setShowAlert(true);
    } else {
      return getData();
    }

    // jalankan function request data ke server
    getData();

    // menangani pembersihan saat fetching data
    return () => {
      history.replace((createAlert = undefined));
      setIsLoading(false);
    };
  }, [setNavbarTitle, page, dataLength, searchKey]);

  const changeDataStatus = (status, id) => {
    setIsLoading(true);
    setShowAlert(false);

    const value = {
      id_vendor: id,
    };

    const onLoadedSuccess = () => {
      setAlertConfig({
        variant: 'primary',
        text: 'Ubah Status Vendor Berhasil',
      });
      setShowAlert(true);
    };

    const onLoadedFailed = () => {
      setAlertConfig({
        variant: 'danger',
        text: 'Ubah Status Vendor Bagal',
      });
      setShowAlert(true);
    };

    status === true
      ? VendorApi.show(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData())
      : VendorApi.hide(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData());
  };

  // Tabel
  const Table = () => (
    <>
      <CRUDLayout.Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <Th>Kode Vendor</Th>
            <Th>Nama Vendor</Th>
            <Th>Jenis Vendor</Th>
            <Th>Contact Person</Th>
            <Th>Telepon</Th>
            <Th>Alamat</Th>
            <Th>Status Vendor</Th>
          </Tr>
        </THead>
        <TBody>
          {data !== undefined
            ? data.map((val, index) => (
                <Tr key={index}>
                  <TdFixed>{TableNumber(page, dataLength, index)}</TdFixed>
                  <TdFixed>
                    <div className="d-flex justify-content-center">
                      <ReadButton
                        onClick={() => {
                          history.push(
                            '/master/vendor/detail/' + val.id_vendor
                          );
                        }}
                      />

                      <UpdateButton
                        onClick={() => {
                          history.push(`/master/vendor/${val.id_vendor}`);
                        }}
                      />

                      <DeleteButton
                        onClick={() => {
                          setDeleteData(val);
                          setIsDeleteData(true);
                        }}
                      />

                      <Switch
                        id={toString(index + 1)}
                        checked={val.is_hidden === false ? true : false}
                        onChange={() =>
                          changeDataStatus(val.is_hidden, val.id_vendor)
                        }
                      />
                    </div>
                  </TdFixed>
                  <Td>
                    <div className="text-center">{val.kode_vendor}</div>
                  </Td>
                  <Td>{val.nama_vendor}</Td>
                  <Td>
                    {val.jenis_vendor.map((val) => val.jenis_vendor).join(', ')}
                  </Td>
                  <Td>{val.contact_person}</Td>
                  <Td>{val.telepon}</Td>
                  <Td>{val.alamat_vendor}</Td>
                  <Td>
                    {val.status_vendor == 0
                      ? 'Calon Vendor'
                      : val.status_vendor == 1
                      ? 'Vendor Resmi'
                      : ''}
                  </Td>
                </Tr>
              ))
            : ''}
        </TBody>
      </CRUDLayout.Table>
      {!isSearching && (
        <Pagination
          dataLength={dataLength}
          dataNumber={page * dataLength - dataLength + 1}
          dataPage={page * dataLength}
          dataCount={dataCount}
          onDataLengthChange={(e) => {
            setDataLength(e.target.value);
            setPage(1);
          }}
          currentPage={page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) => setPage(selected + 1)}
        />
      )}
    </>
  );

  // MODAL HAPUS COMPONENT
  const HapusModal = () => {
    // SET DATA ID YANG DIHAPUS
    const deleteValue = { id_vendor: deleteData.id_vendor };
    // MENANGANI DELETE BUTTON LOADING
    const [btnLoading, setBtnLoading] = useState(false);

    // DELETE DATA DARI SERVER
    const deleteDataHandler = () => {
      setBtnLoading(true);

      VendorApi.delete(deleteValue)
        .then(() => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: 'primary',
            text: 'Hapus data berhasil!',
          });
        })
        .catch((err) => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: 'danger',
            text: `Hapus data gagal! (${err.response.data.message})`,
          });
        })
        .finally(() => {
          // CLOSE MODAL
          setIsDeleteData(false);
          // TAMPIL ALERT
          setShowAlert(true);
          // FETCH DATA DARI SERVER
          getData();
        });
    };

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={title}
      >
        <div>Kode Vendor : {deleteData.kode_vendor}</div>
        <div>Nama Vendor : {deleteData.nama_vendor}</div>
      </DeleteModal>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row>
            <Col md="8">
              <InputSearch
                onChange={(e) => {
                  setTimeout(() => {
                    setSearchKey(e.target.value);
                    setPage(1);
                  }, 1000);
                }}
                onSubmit={(e) => e.preventDefault()}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          <Link to="/master/vendor/tambah">
            <CreateButton />
          </Link>
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      {/* Table */}
      {
        // cek apakah data sedang dimuat (loading)
        isLoading === true ? (
          <DataStatus loading={true} text="Memuat data..." />
        ) : // Cek apakah ada data
        data !== 0 ? (
          <Table />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      }
      <HapusModal />
    </CRUDLayout>
  );
};

export default Vendor;
