import { useState, useContext } from 'react';
import { DateConvert } from 'utilities';
import { PermintaanSubkonProduksiApi } from 'api';
import { TransferSubkonProduksiContext } from '../Context';

const formatMonthYear = (date) => {
  const fmtDate = DateConvert(new Date(date));

  return `${fmtDate.defaultMonth}/${fmtDate.defaultYear}`;
};

const getNomorTransferSubkon = async (date) => {
  const tanggal = DateConvert(date).default;

  return await PermintaanSubkonProduksiApi.getNomor({ tanggal })
    .then((res) => res.data.data)
    .catch(() => alert('Gagal memuat nomor Transfer Produksi Subkon'));
};

export const useMockNumber = () => {
  const [mockNumber, setMockNumber] = useState({});
  const { dataTransfer } = useContext(TransferSubkonProduksiContext);

  const allDataTransfer = Object.values(dataTransfer)
    .filter((i) => i?.length)
    .flat();

  const generateMockNumber = async (date) => {
    const selectedMonthYear = formatMonthYear(date);

    if (mockNumber.hasOwnProperty(selectedMonthYear)) {
      const count =
        allDataTransfer?.filter(
          (val) => formatMonthYear(val.tgl_kontrak) === selectedMonthYear
        ).length ?? 0;

      const splitNumber = mockNumber[selectedMonthYear].split('/');
      const lastIndex = splitNumber.length - 1;
      const incrementLastNumber = +splitNumber[lastIndex] + count;
      const formatIncrementLastNumber = ('000000' + incrementLastNumber).slice(
        -6
      );
      splitNumber.splice(lastIndex, 1, formatIncrementLastNumber);

      return splitNumber.join('/');
    } else {
      const serverNumber = await getNomorTransferSubkon(date);
      setMockNumber((prev) => ({ ...prev, [selectedMonthYear]: serverNumber }));
      return serverNumber;
    }
  };

  return [generateMockNumber];
};
