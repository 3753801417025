import { useContext } from 'react';
import { Formik } from 'formik';
import { DatePicker, FilterModal, SelectSearch } from 'components';
import { DateConvert } from 'utilities';
import { DropdownPurchaseOrderContext } from '../Contexts';

export const ModalFilterPreform = ({ show, setShow, filter, setFilter }) => {
  const defaultDropdown = { value: null, label: 'Semua' };

  const { dropdownItem, dropdownVendor } = useContext(
    DropdownPurchaseOrderContext
  );

  const optItems = [defaultDropdown].concat(dropdownItem.data ?? []);
  const optVendor = [defaultDropdown].concat(dropdownVendor.data ?? []);

  const initialValues = {
    id_vendor: filter?.id_vendor,
    tgl_seleksi_vendor_start: filter?.tgl_seleksi_vendor_start,
    tgl_seleksi_vendor_end: filter?.tgl_seleksi_vendor_end,
    list_item_po: filter?.list_item_po,
  };

  const onSubmit = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setFilter((prev) => ({
        ...prev,
        ...values,
        active: true,
        page: '1',
      }));

      setShow(false);
      return;
    }

    setFilter({ ...values, filter: false });
    setShow(false);
  };

  const onResetButtonClick = () => {
    setFilter((prev) => ({
      ...prev,
      page: '1',
      active: false,
      id_vendor: undefined,
      tgl_seleksi_vendor_start: undefined,
      tgl_seleksi_vendor_end: undefined,
      list_item_po: undefined,
    }));
    setShow(false);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, handleSubmit, setValues }) => (
        <FilterModal
          show={show}
          setShow={setShow}
          onResetButtonClick={() => onResetButtonClick(setValues)}
          onFilterButtonClick={handleSubmit}
        >
          <SelectSearch
            label="Vendor"
            placeholder="Pilih Nama Vendor"
            loading={dropdownVendor?.isLoading}
            option={optVendor}
            defaultValue={
              values.id_vendor
                ? optVendor.find((v) => values.id_vendor === v.value)
                : defaultDropdown
            }
            onChange={({ value }) => setFieldValue('id_vendor', value)}
          />

          <DatePicker
            selectsRange
            label="Tgl. Seleksi Vendor"
            placeholderText="Pilih Tanggal Seleksi Vendor"
            startDate={
              values.tgl_seleksi_vendor_start
                ? new Date(values.tgl_seleksi_vendor_start)
                : ''
            }
            endDate={
              values.tgl_seleksi_vendor_end
                ? new Date(values.tgl_seleksi_vendor_end)
                : ''
            }
            onChange={(dates) => {
              const [start, end] = dates;
              const startDate = start ? DateConvert(start).default : null;
              const endDate = end ? DateConvert(end).default : null;

              setFieldValue('tgl_seleksi_vendor_start', startDate);
              setFieldValue('tgl_seleksi_vendor_end', endDate);
            }}
            monthsShown={2}
          />

          <SelectSearch
            label="Item Purchase Order"
            placeholder="Pilih Item Purchase Order"
            loading={dropdownItem?.isLoading}
            option={optItems}
            defaultValue={
              values.list_item_po
                ? optItems.find((v) => values.list_item_po === v.value)
                : defaultDropdown
            }
            onChange={({ value }) => setFieldValue('list_item_po', value)}
          />
        </FilterModal>
      )}
    </Formik>
  );
};
