import Services from 'services';

class TransferProduksiSubkonApi {
  page(value) {
    return Services.get('/transfer_produksi_subkon/page', {
      params: { ...value },
    });
  }

  single(value) {
    return Services.get('/transfer_produksi_subkon/single', {
      params: { ...value },
    });
  }

  transfer(value) {
    return Services.get('/transfer_produksi_subkon/transfer', {
      params: { ...value },
    });
  }

  dropdown(value) {
    return Services.get('/transfer_produksi_subkon/dropdown', {
      params: { ...value },
    });
  }

  no_baru(value) {
    return Services.get('/transfer_produksi_subkon/no_baru', {
      params: { ...value },
    });
  }

  create(value) {
    return Services.post('/transfer_produksi_subkon', value);
  }

  delete(value) {
    return Services.post('/transfer_produksi_subkon/delete', value);
  }
}

export default new TransferProduksiSubkonApi();
