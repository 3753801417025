import Services from 'services';

class JenisKayuApi {
  getPage(params) {
    return Services.get('/jenis_kayu/page/', { params });
  }
  getSingle(params) {
    return Services.get('/jenis_kayu/single', { params });
  }
  create(data) {
    return Services.post('/jenis_kayu', data);
  }
  update(data) {
    return Services.put('/jenis_kayu', data);
  }
  delete(id_jenis_kayu) {
    return Services.post('/jenis_kayu/delete', id_jenis_kayu);
  }
}

export default new JenisKayuApi();
