import Services from '../../services';

class HargaPerkiraanSendiriApi {
  getPage(page, dataLength, key) {
    return Services.get(
      `/harga_perkiraan_sendiri/page?page=${page}&per_page=${dataLength}&q=${key}`
    );
  }

  page(params) {
    return Services.get(`/harga_perkiraan_sendiri/page?`, { params });
  }

  update(data) {
    return Services.post('/harga_perkiraan_sendiri', data);
  }
}

export default new HargaPerkiraanSendiriApi();
