import Services from 'services';

class PartKayuApi {
  getPage(params) {
    return Services.get('/part_kayu/page/', { params });
  }
  getSingle(params) {
    return Services.get('/part_kayu/single', { params });
  }
  create(data) {
    return Services.post('/part_kayu', data);
  }
  update(data) {
    return Services.put('/part_kayu', data);
  }
  delete(id_part_kayu) {
    return Services.post('/part_kayu/delete', id_part_kayu);
  }
}

export default new PartKayuApi();
