import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { InputCurrency, Table, Td, TdFixed, Th, ThFixed } from 'components';
import { useFormikContext } from 'formik';
import { RupiahConvert, DateConvert } from 'utilities';
import { calculateGrandTotal, itemNameWithDimension } from '../Utils';

export const TableListPurchaseOrder = ({ type = 'FORM' }) => {
  const isTypeForm = type === 'FORM';
  const [parentCB, setParentCB] = useState(false);
  const { values, setValues, setFieldValue } = useFormikContext();

  const handleCbParent = (e) => {
    setParentCB(e.target.checked);
    setValues((prev) => ({
      ...prev,
      detail: prev?.detail?.map((el) => ({ ...el, checked: e.target.checked })),
    }));
  };

  const handleCbItem = (e, index) => {
    const newItem = { ...values?.detail[index], checked: e.target.checked };

    setValues((prev) => ({
      ...prev,
      detail: prev?.detail?.toSpliced(index, 1, newItem),
    }));
  };

  const grandTotal = calculateGrandTotal(values?.detail);

  const diskon = Math.round(((values.diskon ?? 0 * 100) / 100) * grandTotal);

  const totalDikurangiDiskon = grandTotal - diskon;

  const ppn = Math.round(
    ((values.ppn ?? 0 * 100) / 100) * totalDikurangiDiskon
  );

  const totalDitambahPpn = totalDikurangiDiskon + ppn;

  const dp = Math.round(((values.dp ?? 0 * 100) / 100) * totalDitambahPpn);

  const sisa = totalDitambahPpn - dp;

  const maxPercentageAllowed = (values) => {
    const { formattedValue, floatValue } = values;
    if (floatValue == null) {
      return formattedValue === '';
    } else {
      return floatValue >= 0 && floatValue <= 100;
    }
  };

  return (
    <>
      <div className="mt-3" style={{ fontSize: '.9rem' }}>
        List Seleksi Vendor Yang Siap Dibuatkan Purchase Order
      </div>
      <Table>
        <thead>
          <tr>
            <ThFixed>No.</ThFixed>
            <ThFixed>Informasi Seleksi Vendor</ThFixed>
            <ThFixed>Informasi Purchase Request</ThFixed>
            <Th>Item Seleksi Vendor</Th>
            <Th width={100}>Qty</Th>
            <Th width={100}>Satuan</Th>
            <ThFixed>Harga Kesepakatan</ThFixed>
            <ThFixed>Total Harga</ThFixed>
            {isTypeForm && (
              <ThFixed>
                <Form>
                  <Form.Check
                    type="checkbox"
                    checked={parentCB ?? false}
                    onChange={handleCbParent}
                  />
                </Form>
              </ThFixed>
            )}
          </tr>
        </thead>
        <tbody>
          {values?.detail?.map((val, index) => (
            <tr key={index}>
              <Td className="text-center">{index + 1}</Td>

              <Td className="text-nowrap">
                <div>
                  {val.tgl_seleksi_vendor
                    ? DateConvert(new Date(val.tgl_seleksi_vendor)).defaultDMY
                    : '-'}
                </div>
                <div>{val.no_seleksi_vendor ?? '-'}</div>
              </Td>

              <Td className="text-nowrap">
                <div>
                  {val.tgl_purchase_request
                    ? DateConvert(new Date(val.tgl_purchase_request)).defaultDMY
                    : '-'}
                </div>
                <div>{val.no_purchase_request ?? '-'}</div>
              </Td>

              <Td> {itemNameWithDimension(val)}</Td>

              <Td textRight>{parseFloat(val.qty_order ?? 0)}</Td>

              <Td>{val.satuan_beli ?? val.nama_satuan}</Td>

              <Td textRight>
                {
                  RupiahConvert(String(Math.round(val.harga_kesepakatan ?? 0)))
                    .detail
                }
              </Td>

              <Td textRight>
                {
                  RupiahConvert(
                    String(
                      Math.round(
                        parseFloat(val.harga_kesepakatan ?? 0) *
                          parseFloat(val.qty_order ?? 0)
                      )
                    )
                  ).detail
                }
              </Td>

              {isTypeForm && (
                <Td className="text-center">
                  <Form>
                    <Form.Check
                      type="checkbox"
                      checked={val.checked ?? false}
                      onChange={(e) => handleCbItem(e, index)}
                    />
                  </Form>
                </Td>
              )}
            </tr>
          ))}
          <tr>
            <Td colSpan={7} textRight>
              Total
            </Td>

            <Td textRight>
              {RupiahConvert(String(calculateGrandTotal(values.detail))).detail}
            </Td>
            {isTypeForm && <Td />}
          </tr>

          <tr>
            <Td colSpan={7} textRight>
              <div className="d-flex justify-content-end align-items-center">
                {isTypeForm ? (
                  <>
                    <span className="mb-2">Diskon (%)</span>
                    <div style={{ width: 100 }}>
                      <InputCurrency
                        prefix=""
                        isAllowed={maxPercentageAllowed}
                        decimalScale={6}
                        className="ml-2 text-right"
                        value={values.diskon}
                        onChange={(value) => setFieldValue('diskon', value)}
                      />
                    </div>
                  </>
                ) : (
                  <span className="mb-2">
                    Diskon ({parseFloat(values.diskon)}%)
                  </span>
                )}
              </div>
            </Td>
            <Td textRight>{RupiahConvert(String(diskon)).detail}</Td>
            {isTypeForm && <Td />}
          </tr>

          <tr>
            <Td colSpan={7} textRight>
              Total Setelah Dikurangi Diskon
            </Td>
            <Td textRight>
              {RupiahConvert(String(totalDikurangiDiskon)).detail}
            </Td>
            {isTypeForm && <Td />}
          </tr>

          <tr>
            <Td colSpan={7} textRight>
              <div className="d-flex justify-content-end align-items-center">
                {isTypeForm ? (
                  <>
                    <span className="mb-2">PPN (%)</span>
                    <div style={{ width: 100 }}>
                      <InputCurrency
                        prefix=""
                        className="ml-2 text-right"
                        isAllowed={maxPercentageAllowed}
                        value={parseFloat(values.ppn)}
                        onChange={(value) => setFieldValue('ppn', value)}
                      />
                    </div>
                  </>
                ) : (
                  <span className="mb-2">PPN ({parseFloat(values.ppn)}%)</span>
                )}
              </div>
            </Td>
            <Td textRight>{RupiahConvert(String(ppn)).detail}</Td>
            {isTypeForm && <Td />}
          </tr>

          <tr>
            <Td colSpan={7} textRight>
              Total Setelah Ditambah PPN
            </Td>
            <Td textRight>{RupiahConvert(String(totalDitambahPpn)).detail}</Td>
            {isTypeForm && <Td />}
          </tr>

          <tr>
            <Td colSpan={7} textRight>
              <div className="d-flex justify-content-end align-items-center">
                {isTypeForm ? (
                  <>
                    <span className="mb-2">DP (%)</span>
                    <div style={{ width: 100 }}>
                      <InputCurrency
                        prefix=""
                        className="ml-2 text-right"
                        isAllowed={maxPercentageAllowed}
                        value={parseFloat(values.dp)}
                        onChange={(value) => setFieldValue('dp', value)}
                      />
                    </div>
                  </>
                ) : (
                  <span className="mb-2">DP ({parseFloat(values.dp)}%)</span>
                )}
              </div>
            </Td>
            <Td textRight>{RupiahConvert(String(dp)).detail}</Td>
            {isTypeForm && <Td />}
          </tr>

          <tr>
            <Td colSpan={7}>
              <div className="d-flex justify-content-end align-items-center">
                <span className="mb-2">Sisa</span>
              </div>
            </Td>
            <Td textRight>{RupiahConvert(String(sisa)).detail}</Td>
            {isTypeForm && <Td />}
          </tr>
        </tbody>
      </Table>
    </>
  );
};
