import { useContext } from 'react';
import { Formik } from 'formik';
import { DatePicker, FilterModal, SelectSearch } from 'components';
import { DateConvert } from 'utilities';
import { DropdownTransferSubkonContext } from '../Context';

export const ModalFilterTransferSubkonProduksi = ({
  show,
  setShow,
  filter,
  setFilter,
}) => {
  const defaultDropdown = { value: null, label: 'Semua' };

  const { dropdownVendor, dropdownItem } = useContext(
    DropdownTransferSubkonContext
  );

  const optItems = [defaultDropdown].concat(dropdownItem.data ?? []);
  const optVendor = [defaultDropdown].concat(dropdownVendor.data ?? []);

  const initialValues = {
    tgl_job_order_start: filter?.tgl_job_order_start,
    tgl_job_order_end: filter?.tgl_job_order_end,
    tgl_kontrak_subkon_start: filter?.tgl_kontrak_subkon_start,
    tgl_kontrak_subkon_end: filter?.tgl_kontrak_subkon_end,
    id_barang_jadi: filter?.id_barang_jadi,
    id_vendor: filter?.id_vendor,
  };

  const onSubmit = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setFilter((prev) => ({
        ...prev,
        ...values,
        active: true,
        page: '1',
      }));

      setShow(false);
      return;
    }

    setFilter({ ...values, filter: false });
    setShow(false);
  };

  const onResetButtonClick = () => {
    setFilter((prev) => ({
      ...prev,
      page: '1',
      active: false,
      tgl_job_order_start: undefined,
      tgl_job_order_end: undefined,
      tgl_kontrak_subkon_start: undefined,
      tgl_kontrak_subkon_end: undefined,
      id_barang_jadi: undefined,
      id_vendor: undefined,
    }));
    setShow(false);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, handleSubmit, setValues }) => (
        <FilterModal
          show={show}
          setShow={setShow}
          onResetButtonClick={() => onResetButtonClick(setValues)}
          onFilterButtonClick={handleSubmit}
        >
          <DatePicker
            selectsRange
            label="Tgl. Job Order"
            placeholderText="Pilih Tanggal Job Order"
            startDate={
              values.tgl_job_order_start
                ? new Date(values.tgl_job_order_start)
                : ''
            }
            endDate={
              values.tgl_job_order_end ? new Date(values.tgl_job_order_end) : ''
            }
            onChange={(dates) => {
              const [start, end] = dates;
              const startDate = start ? DateConvert(start).default : null;
              const endDate = end ? DateConvert(end).default : null;

              setFieldValue('tgl_job_order_start', startDate);
              setFieldValue('tgl_job_order_end', endDate);
            }}
            monthsShown={2}
          />

          <SelectSearch
            label="Item Job Order"
            placeholder="Pilih Item Job Order"
            loading={dropdownItem?.isLoading}
            option={optItems}
            defaultValue={
              values.id_barang_jadi
                ? optItems.find((v) => values.id_barang_jadi === v.value)
                : defaultDropdown
            }
            onChange={({ value }) => {
              setFieldValue('id_barang_jadi', value);
            }}
          />

          <DatePicker
            selectsRange
            label="Tgl. Transfer Subkon Produksi"
            placeholderText="Pilih Tanggal Transfer Subkon Produksi"
            startDate={
              values.tgl_kontrak_subkon_start
                ? new Date(values.tgl_kontrak_subkon_start)
                : ''
            }
            endDate={
              values.tgl_kontrak_subkon_end
                ? new Date(values.tgl_kontrak_subkon_end)
                : ''
            }
            onChange={(dates) => {
              const [start, end] = dates;
              const startDate = start ? DateConvert(start).default : null;
              const endDate = end ? DateConvert(end).default : null;

              setFieldValue('tgl_kontrak_subkon_start', startDate);
              setFieldValue('tgl_kontrak_subkon_end', endDate);
            }}
            monthsShown={2}
          />

          <SelectSearch
            label="Vendor"
            placeholder="Pilih Nama Vendor"
            loading={dropdownVendor?.isLoading}
            option={optVendor}
            defaultValue={
              values.id_vendor
                ? optVendor.find((v) => values.id_vendor === v.value)
                : defaultDropdown
            }
            onChange={({ value }) => setFieldValue('id_vendor', value)}
          />
        </FilterModal>
      )}
    </Formik>
  );
};
