import React, { PureComponent } from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  DataStatus,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  InfoItemHorizontal,
} from 'components';
import { DateConvert, RupiahConvert } from 'utilities';
import Logo from 'assets/image/LogoMGP.jpeg';
import Temaja from 'assets/image/AnakAgungNgurahTemajaPutra.png';
import Nukayasa from 'assets/image/IKetutNukayasa.png';
import Subawa from 'assets/image/IPutuSubawa.png';
import { calculateGrandTotal, itemNameWithDimension } from '../Utils';

const PrintContent = ({ dataPurchaseOrder, dataPODetail }) => {
  const grandTotal = calculateGrandTotal(dataPODetail);
  const diskon = Math.round(
    ((dataPurchaseOrder.diskon ?? 0 * 100) / 100) * grandTotal
  );
  const totalDikurangiDiskon = grandTotal - diskon;
  const ppn = Math.round(
    ((dataPurchaseOrder.ppn ?? 0 * 100) / 100) * totalDikurangiDiskon
  );
  const totalDitambahPpn = totalDikurangiDiskon + ppn;
  const dp = Math.round(
    ((dataPurchaseOrder.dp ?? 0 * 100) / 100) * totalDitambahPpn
  );
  const sisa = totalDitambahPpn - dp;

  const InfoItem = ({ label, text }) => (
    <div
      className="d-flex justify-content-center align-items-center my-1"
      style={{
        fontSize: 14,
      }}
    >
      <div
        className="text-wrap"
        style={{
          width: 200,
        }}
      >
        {label}
      </div>

      <div>{' : '} </div>

      <div
        style={{
          textAlign: 'right',
          minWidth: 130,
        }}
      >
        {text}
      </div>
    </div>
  );

  const KopSuratSection = () => (
    <div className="d-flex mx-2">
      <div>
        <img src={Logo} width={110} alt="MGP LOGO" />
      </div>
      <div style={{ marginLeft: 30 }}>
        <p>
          <b>PT. MARDIKA GRIYA PRASTA</b>
          <br />
          Jl. Sekarsari No.30, Kesambi, Denpasar, Bali, Indonesia <br />
          Phone +62(0)361 467-342 <br />
          Fax (0361) 46730 <br />
          Email sales@mgpwoodworks.com <br />
        </p>
      </div>
    </div>
  );

  const CatatanSection = () => (
    <div>
      <div className="mb-1">
        Pesanan ini selambat-lambatnya telah sampai pada :
      </div>

      <Row>
        <Col>
          <table>
            <tbody>
              <InfoItemHorizontal
                label="Tgl. Jatuh Tempo"
                text={
                  dataPurchaseOrder.tgl_pengiriman
                    ? DateConvert(new Date(dataPurchaseOrder.tgl_pengiriman))
                        .detail
                    : '-'
                }
              />
              <InfoItemHorizontal
                label="Pembayaran"
                text={dataPurchaseOrder.pembayaran}
              />
              <InfoItemHorizontal
                label="Dikirim ke"
                text={dataPurchaseOrder.alamat_tujuan_pengiriman}
              />
            </tbody>
          </table>
        </Col>

        <Col>
          <div className="d-flex justify-content-end">
            <table>
              <tbody>
                <InfoItem
                  label="Total"
                  text={RupiahConvert(parseInt(grandTotal).toString()).detail}
                />

                <InfoItem
                  label={`Diskon ${parseFloat(dataPurchaseOrder.diskon).toFixed(
                    2
                  )} %`}
                  text={RupiahConvert(parseInt(diskon).toString()).detail}
                />

                <InfoItem
                  label="Total Setelah Dikurangi Diskon"
                  text={
                    RupiahConvert(parseInt(totalDikurangiDiskon).toString())
                      .detail
                  }
                />
                <InfoItem
                  label={`PPN ${parseFloat(dataPurchaseOrder.ppn).toFixed(
                    2
                  )} %`}
                  text={RupiahConvert(parseInt(ppn).toString()).detail}
                />

                <InfoItem
                  label="Total Setelah Ditambah PPN"
                  text={RupiahConvert(parseInt(ppn).toString()).detail}
                />

                <InfoItem
                  label={`DP ${parseFloat(dataPurchaseOrder.dp).toFixed(2)} %`}
                  text={RupiahConvert(dp.toString()).detail}
                />

                <InfoItem
                  label={<b>Grand Total</b>}
                  text={RupiahConvert(String(parseInt(sisa))).detail}
                />
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    </div>
  );

  const TTDSection = () => {
    const TTDItems = ({ title, value, jabatan, src, showSignature = true }) => (
      <Col className="text-center">
        <div>{title}</div>
        <div>
          <img
            style={{ visibility: showSignature ? 'visible' : 'hidden' }}
            src={src}
            height="80"
            alt="TTDItems"
          />
        </div>
        <div>
          <u>{value}</u>
        </div>
        <div>{jabatan}</div>
      </Col>
    );

    return (
      <Row className="mt-5 pt-3">
        <TTDItems
          title="Disahkan,"
          value="A.A Ngurah Temaja Putra"
          src={Temaja}
          jabatan="Direktur"
        />

        <TTDItems
          title="Dibuat Oleh"
          value="Putu Subawa"
          src={Subawa}
          jabatan="Procurement"
        />
      </Row>
    );
  };

  const ListItemPurchaseOrderSection = () => {
    let total = 0;
    let nilaiDiskon = 0;
    let setelahDiskon = 0;
    let nilaiPPN = 0;
    let setelahPPN = 0;

    return (
      <>
        <div className="py-2 pl-1">
          <span>Kami tempatkan order pembelian dengan rincian sbb :</span>
        </div>
        {dataPODetail ? (
          dataPODetail.length > 0 ? (
            <>
              <Table>
                <THead>
                  <Tr>
                    <ThFixed>No.</ThFixed>
                    <ThFixed>Referensi PR</ThFixed>
                    <Th>Nama Barang/Jasa</Th>
                    <ThFixed>Qty</ThFixed>
                    <ThFixed>Satuan</ThFixed>
                    <ThFixed>Harga Satuan</ThFixed>
                    <ThFixed>Sub Total</ThFixed>
                  </Tr>
                </THead>
                <TBody>
                  {dataPODetail.map((val, index) => {
                    total = total + val.harga_kesepakatan * val.qty_order;
                    nilaiDiskon = dataPurchaseOrder.diskon
                      ? parseInt(dataPurchaseOrder.diskon) === 0
                        ? 0
                        : parseInt((dataPurchaseOrder.diskon / 100) * total)
                      : 0;
                    setelahDiskon = parseInt(total - nilaiDiskon);
                    nilaiPPN = dataPurchaseOrder.ppn
                      ? parseInt(dataPurchaseOrder.ppn) === 0
                        ? 0
                        : parseInt(
                            (dataPurchaseOrder.ppn / 100) * setelahDiskon
                          )
                      : 0;
                    setelahPPN = parseInt(setelahDiskon + nilaiPPN);

                    return (
                      <Tr key={index}>
                        <TdFixed>{index + 1}</TdFixed>
                        <TdFixed>{val.no_purchase_request}</TdFixed>
                        <Td>{itemNameWithDimension(val)}</Td>
                        <Td textRight>
                          {val.qty_order
                            ? parseFloat(val.qty_order).toPrecision()
                            : '-'}
                        </Td>
                        <Td>{val.nama_satuan ? val.nama_satuan : '-'}</Td>
                        <Td textRight>
                          {
                            RupiahConvert(val.harga_kesepakatan.toString())
                              .getWithComa
                          }
                        </Td>
                        <Td textRight>
                          {
                            RupiahConvert(
                              parseInt(
                                val.qty_order * val.harga_kesepakatan
                              ).toString()
                            ).getWithComa
                          }
                        </Td>
                      </Tr>
                    );
                  })}
                </TBody>
              </Table>
              <CatatanSection />

              <div>
                <span>Keterangan : </span>
                <pre
                  style={{
                    border: '1px solid transparent',
                    fontFamily: 'Roboto',
                    fontSize: 13,
                    lineHeight: 1.7,
                  }}
                >
                  {dataPurchaseOrder.keterangan}
                </pre>
              </div>

              {dataPurchaseOrder.stakeholder.length > 1 && <TTDSection />}
            </>
          ) : (
            <DataStatus text="Tidak ada data" />
          )
        ) : (
          <DataStatus text="Tidak ada data" />
        )}
      </>
    );
  };

  return (
    <div className="p-4">
      <KopSuratSection />

      <Row className="text-center d-flex justify-content-center align-items-center my-3">
        <h6 className="font-weight-bold">Purchase Order</h6>
      </Row>

      <Row className="d-flex justify-content-between align-items-end">
        <Col>
          <div
            className="font-weight-bold mb-1"
            style={{ width: 180, fontSize: 14 }}
          >
            Deskripsi
          </div>

          <InfoItemHorizontal
            label="Tgl. Purchase Order"
            text={
              dataPurchaseOrder.tgl_purchase_order
                ? DateConvert(new Date(dataPurchaseOrder.tgl_purchase_order))
                    .detail
                : '-'
            }
          />

          <InfoItemHorizontal
            label="No. Purchase Order"
            text={
              dataPurchaseOrder.no_purchase_order
                ? dataPurchaseOrder.no_purchase_order
                : '-'
            }
          />
        </Col>

        <Col className="d-flex justify-content-end align-items-end">
          <div className="pt-1">
            <div
              className="font-weight-bold mb-1 "
              style={{ width: 180, fontSize: 14 }}
            >
              Kepada:
            </div>

            <span style={{ fontSize: 14 }}>
              {dataPurchaseOrder.nama_vendor
                ? dataPurchaseOrder.nama_vendor
                : '-'}
            </span>
            <br />
            <span style={{ fontSize: 14 }}>
              {dataPurchaseOrder.alamat_vendor
                ? dataPurchaseOrder.alamat_vendor
                : '-'}
            </span>
          </div>
        </Col>
      </Row>
      <ListItemPurchaseOrderSection />
    </div>
  );
};

export class PrintPurchaseOrder extends PureComponent {
  render() {
    return (
      <PrintContent
        dataPurchaseOrder={this.props.dataPurchaseOrder}
        dataPODetail={this.props.dataPODetail}
      />
    );
  }
}
