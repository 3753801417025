import { useEffect, useContext } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ActionButton, DatePicker, Input, SelectSearch } from 'components';
import {
  DropdownTransferUpahContext,
  TransferUpahProduksiContext,
} from '../Context';

export const ModalFormRegistrasi = ({ generateMockNumber }) => {
  const { modalForm, setModalForm } = useContext(TransferUpahProduksiContext);
  const { dropdownVendor } = useContext(DropdownTransferUpahContext);

  const formInitialData = {
    tgl_kontrak: modalForm?.data?.tgl_kontrak ?? '',
    no_kontrak: modalForm?.data?.no_kontrak ?? '',
    id_vendor: modalForm?.data?.id_vendor ?? '',
    nama_vendor: modalForm?.data?.nama_vendor ?? '',
    jabatan_vendor: modalForm?.data?.jabatan_vendor ?? '',
    alamat_vendor: modalForm?.data?.alamat_vendor ?? '',
  };

  const formValidationSchema = Yup.object().shape({
    tgl_kontrak: Yup.string().required('Pilih tanggal kontrak'),
    no_kontrak: Yup.string().required('Pilih nomor kontrak'),
    id_vendor: Yup.string().required('Pilih vendor'),
  });

  const formSubmitHandler = (values) => {
    setModalForm((prev) => ({
      ...prev,
      showRegistrasi: false,
      showKontrak: true,
      data: {
        ...prev.data,
        ...values,
      },
    }));
  };

  const { values, touched, errors, setValues, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: formInitialData,
    validationSchema: formValidationSchema,
    onSubmit: formSubmitHandler,
  });

  const getNomorHandler = async (date) =>
    await generateMockNumber(date).then((nomor) => {
      setValues({
        ...values,
        tgl_kontrak: date,
        no_kontrak: nomor,
      });
    });

  useEffect(() => {
    getNomorHandler(new Date());
  }, []);

  return (
    <Modal
      show={modalForm.showRegistrasi}
      onHide={() =>
        setModalForm((prev) => ({ ...prev, showRegistrasi: false }))
      }
    >
      <form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <b>Form Registrasi Kontrak</b>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <DatePicker
                label="Tgl. Kontrak"
                placeholderText="Pilih tanggal"
                format="dd/MM/yyyy"
                selected={
                  values.tgl_kontrak ? new Date(values.tgl_kontrak) : ''
                }
                onChange={(date) => getNomorHandler(date)}
                error={!!errors.tgl_kontrak && !!touched.tgl_kontrak}
                errorText={
                  !!errors.tgl_kontrak &&
                  !!touched.tgl_kontrak &&
                  errors.tgl_kontrak
                }
              />
            </Col>
            <Col>
              <Input
                label="No. Kontrak"
                placeholder="Pilih tgl untuk menentukan nomor"
                value={values.no_kontrak}
                error={!!errors.no_kontrak && !!touched.no_kontrak}
                errorText={
                  !!errors.no_kontrak &&
                  !!touched.no_kontrak &&
                  errors.no_kontrak
                }
                readOnly
              />
            </Col>
          </Row>
          <SelectSearch
            label="Vendor"
            placeholder="Pilih vendor"
            loading={dropdownVendor.isLoading}
            option={dropdownVendor?.data ?? []}
            onChange={(val) =>
              setValues({
                ...values,
                id_vendor: val?.value,
                nama_vendor: val?.label,
                alamat_vendor: val?.alamat_vendor,
                jabatan_vendor: val?.jabatan_vendor,
              })
            }
            error={!!errors.id_vendor && !!touched.id_vendor}
            errorText={
              !!errors.id_vendor && !!touched.id_vendor && errors.id_vendor
            }
          />
        </Modal.Body>
        <Modal.Footer>
          <ActionButton type="submit" text="Format Kontrak" />
        </Modal.Footer>
      </form>
    </Modal>
  );
};
