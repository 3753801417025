// React
import React, { Component } from 'react';

// Component
import { THead, TBody, Th, Table, Tr, Td, TdFixed } from '../../../components';
import { Row, Col } from 'react-bootstrap';

// API
import {
  PrakualifikasiVendorApi,
  VendorApi,
  ItemBahanApi,
  ItemUpahApi,
  ItemSubconApi,
} from '../../../api';
import Axios from 'axios';

// Icon
import { IoCheckmarkSharp } from 'react-icons/io5';

export class ShowData extends Component {
  render() {
    return (
      <div className="d-flex flex-row">
        <div className="text-secondary mb-2 mr-3" style={{ width: 170 }}>
          {this.props.title}
        </div>
        <span>:</span>
        <div
          className="text-dark"
          style={{
            fontWeight: 200,
            paddingLeft: 40,
          }}
        >
          {this.props.text ? this.props.text : '-'}
        </div>
      </div>
    );
  }
}

export class CetakPrakualifikasiVendor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        dokumen_utama: [],
        jumlah_item: [],
        dokumen_lainnya: [],
      },
      vendor: {
        jenis_vendor: [],
      },
      total: 0,
    };
  }

  componentDidMount() {
    Axios.all([
      PrakualifikasiVendorApi.single(this.props.idRef),
      ItemBahanApi.get(this.props.idVendor),
      ItemUpahApi.get(this.props.idVendor),
      VendorApi.single(this.props.idVendor),
    ]).then(
      Axios.spread((res, bahan, upah, vendor) => {
        this.setState({
          data: res.data.data,
          bahan: bahan.data.data,
          upah: upah.data.data,
          vendor: vendor.data.data,
        });
      })
    );
  }

  componentWillUnmount() {
    this.setState({
      data: {},
      vendor: {},
      total: 0,
    });
  }

  render() {
    const data = this.state.data;
    const vendor = this.state.vendor;
    const bahan = this.state.bahan;
    const upah = this.state.upah;
    const subcon = this.state.subcon;

    return (
      <div style={{ marginTop: 100 }} className="mx-2">
        <div className="row">
          <div className="col-6">
            <div className="mb-2">
              <ShowData
                title="Tanggal Prakualifikasi"
                text={data.tgl_vendor_prakualifikasi}
              />

              <ShowData
                title="No Prakualifikasi"
                text={data.no_vendor_prakualifikasi}
              />

              <ShowData
                title="Status Hukum Vendor"
                text={
                  data.status_hukum ? 'Berbadan Hukum' : 'Tidak Berbadan Hukum'
                }
              />
            </div>

            <div className="mb-2">
              <ShowData title="Kode Vendor" text={vendor.kode_vendor} />

              <ShowData title="Nama Vendor" text={vendor.nama_vendor} />

              <ShowData
                title="Jenis Vendor"
                text={vendor.jenis_vendor
                  .map((val) => val.jenis_vendor)
                  .join(', ')}
              />

              <ShowData
                title="Pimpinan Perusahaan"
                text={vendor.nama_pimpinan_perusahaan}
              />

              <ShowData title="Jumlah Karyawan" text={data.jumlah_karyawan} />
            </div>
          </div>

          <div className="col-6">
            <div className="mb-2">
              <ShowData title="Contact Person" text={vendor.contact_person} />

              <ShowData title="Telepon" text={vendor.telepon} />

              <ShowData title="Email" text={vendor.email} />

              <ShowData title="Website" text={vendor.website} />

              <ShowData title="Media Sosial" text={vendor.media_sosial} />
            </div>

            <div className="mb-2">
              <ShowData title="Alamat Vendor" text={vendor.alamat_vendor} />

              <ShowData title="Desa" text={vendor.nama_desa} />

              <ShowData title="Kecamatan" text={vendor.nama_kecamatan} />

              <ShowData title="Kabupaten" text={vendor.nama_kabupaten} />

              <ShowData title="Provinsi" text={vendor.nama_provinsi} />

              <ShowData title="Kode Pos" text={vendor.kode_pos} />
            </div>
          </div>
        </div>
        {vendor.jenis_vendor.map((value) => {
          if (value.jenis_vendor == 'Supplier') {
            return (
              <>
                <small>
                  List Item Bahan Yang Disediakan Vendor Ini Sebagai Supplier
                </small>
                <Table>
                  <thead>
                    <tr>
                      <th>Kode Item Bahan</th>
                      <th>Nama Item Bahan</th>
                      <th>Kelompok Bahan</th>
                      <th>Jenis Bahan</th>
                      <th>Tipe Bahan</th>
                      <th>Satuan Pakai</th>
                    </tr>
                  </thead>

                  <tbody>
                    {bahan.length > 0 ? (
                      bahan.map((val, index) => (
                        <tr key={index}>
                          <td>{val.kode_item}</td>
                          <td>{val.nama_item}</td>
                          <td>{val.nama_kelompok}</td>
                          <td>{val.nama_jenis}</td>
                          <td>{val.tipe}</td>
                          <td>{val.satuan_pakai}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="6"
                          className="my-2 font-weight-bold text-center"
                        >
                          Tidak Ada Data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </>
            );
          } else if (value.jenis_vendor == 'Mandor') {
            return (
              <>
                <small>
                  List Item Upah Yang Disediakan Vendor Ini Sebagai Mandor
                </small>
                <Table>
                  <thead>
                    <tr>
                      <th>Kode Item Bahan</th>
                      <th>Nama Item Bahan</th>
                      <th>Satuan Pakai</th>
                    </tr>
                  </thead>

                  <tbody>
                    {upah.length > 0 ? (
                      upah.map((val, index) => (
                        <tr key={index}>
                          <td>{val.kode_item}</td>
                          <td>{val.nama_item}</td>
                          <td>{val.nama_satuan}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="3"
                          className="my-2 font-weight-bold text-center"
                        >
                          Tidak Ada Data
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </>
            );
          }
        })}

        <small>Jumlah Item Bahan Yang Akan Disediakan</small>
        <Table>
          <thead>
            <tr>
              <th>Kode Item Bahan</th>
              <th>Nama Item Bahan</th>
              <th>Satuan Pakai</th>
              <th>Jumlah</th>
            </tr>
          </thead>

          <tbody>
            {data.jumlah_item.length > 0 ? (
              data.jumlah_item.map((val, index) => (
                <tr key={index}>
                  <td>{val.kode_item}</td>
                  <td>{val.nama_item}</td>
                  <td>{val.nama_satuan}</td>
                  <td className="text-right">{val.jumlah_item}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="my-2 font-weight-bold text-center">
                  Tidak Ada Data
                </td>
              </tr>
            )}
          </tbody>
        </Table>

        <div className="mb-2">
          <strong>Lama Vendor Menyediakan Item : </strong>
          {data.lama_menyediakan_item == '1'
            ? 'Kurang Dari 1 (Satu) Tahun'
            : data.lama_menyediakan_item == '2'
            ? 'Antara 1 (Satu) Sampai 3 (Tiga) Tahun'
            : 'Diatas 3 (Tiga) Tahun'}
        </div>

        <small>Kelengkapan Dokumen</small>
        <Table>
          <THead>
            <Tr>
              <Th>No</Th>
              <Th>Uraian</Th>
              <Th>Ada</Th>
              <Th>Tidak Ada</Th>
            </Tr>
          </THead>

          <TBody>
            {data.dokumen_utama.map((val, index) => {
              return (
                <Tr key={index}>
                  <TdFixed>{index + 1}</TdFixed>
                  <Td>{val.nama_dokumen}</Td>
                  <TdFixed>
                    {val.ada_atau_tidak ? <IoCheckmarkSharp /> : <div />}
                  </TdFixed>
                  <TdFixed>
                    {!val.ada_atau_tidak ? <IoCheckmarkSharp /> : <div />}
                  </TdFixed>
                </Tr>
              );
            })}
          </TBody>
        </Table>

        {data.dokumen_lainnya.length > 0 ? (
          <>
            <small>Dokumen Lainnya</small>
            <Table>
              <THead>
                <Tr>
                  <Th>No</Th>
                  <Th>Uraian</Th>
                </Tr>
              </THead>

              <TBody>
                {data.dokumen_lainnya.map((val, index) => {
                  return (
                    <Tr key={index}>
                      <TdFixed>{index + 1}</TdFixed>
                      <Td>{val.nama_dokumen}</Td>
                    </Tr>
                  );
                })}
              </TBody>
            </Table>
          </>
        ) : (
          ''
        )}

        <div className="row" style={{ marginTop: 30 }}>
          <div className="col-6 d-flex justify-content-center">
            <label>Disimpulkan Oleh</label>
          </div>
          <div className="col-6 d-flex justify-content-center">
            <label>Diajukan Oleh</label>
          </div>
        </div>

        <div className="row" style={{ marginTop: 100 }}>
          <div className="col-2 d-flex justify-content-end">(</div>
          <div className="col-2 d-flex justify-content-center"></div>
          <div className="col-2">)</div>
          <div className="col-2 d-flex justify-content-end">(</div>
          <div className="col-2 d-flex justify-content-center"></div>
          <div className="col-2">)</div>
        </div>

        <div className="row" style={{ marginTop: 10 }}>
          <div className="col-6 d-flex justify-content-center">
            <label>Manager Keuangan</label>
          </div>
          <div className="col-6 d-flex justify-content-center">
            <label>Manager Procurement</label>
          </div>
        </div>
      </div>
    );
  }
}

export default CetakPrakualifikasiVendor;
