// React
import React, { useState, useEffect } from 'react';

// Router
import { useHistory, useLocation } from 'react-router-dom';

// Component
import {
  CRUDLayout,
  InputSearch,
  DataStatus,
  Pagination,
  THead,
  TBody,
  ThFixed,
  Alert,
  TdFixed,
  Tr,
  Th,
  Td,
  ActionButton,
} from '../../../components';
import { Button, Modal, Row, Col } from 'react-bootstrap';
import { TableNumber } from '../../../utilities';

// API
import { KualifikasiVendorApi } from '../../../api';

const KualifikasiVendor = ({ setNavbarTitle }) => {
  let history = useHistory();
  const location = useLocation();

  // indikator pemanggilan data sedang dimuat di server
  const [isLoading, setIsLoading] = useState(false);

  // indikator apakah sedang melakukan searching
  const [isSearching, setIsSearching] = useState(false);

  // menampung value dari search form
  const [searchKey, setSearchKey] = useState('');

  // Data View
  const [data, setData] = useState([
    {
      jenis: 'Supplier, Mandor',
    },
  ]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [dataCount, setDataCount] = useState(0);

  // alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
  });
  let createAlert = location?.state?.variant;

  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);
    setShowAlert(false);

    KualifikasiVendorApi.getPage(page, dataLength, searchKey)
      .then((res) => {
        setData(res.data.data);
        setTotalPage(res.data.total_page);
        setDataCount(res.data.data_count);
      })
      .catch((err) => alert(err))
      .finally(() => {
        if (searchKey != '') {
          setAlertConfig({
            variant: 'primary',
            text: `Hasil Pencarian : ${searchKey}`,
          });
          setShowAlert(true);
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    // set judul di Navbar
    setNavbarTitle('Kualifikasi Vendor');

    if (createAlert !== undefined) {
      setAlertConfig({
        variant: location?.state?.variant,
        text: location?.state?.text,
      });
      setShowAlert(true);
    } else {
      return getData();
    }

    // jalankan function request data ke server
    getData();

    // menangani pembersihan saat fetching data
    return () => {
      history.replace((createAlert = undefined));
      setIsLoading(false);
    };
  }, [setNavbarTitle, page, dataLength, searchKey]);

  // Tabel
  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <Th>Tanggal Prakualifikasi</Th>
              <Th>No Prakualifikasi</Th>
              <Th>Kode Calon Vendor</Th>
              <Th>Nama Vendor</Th>
              <Th>Jenis Vendor</Th>
              <Th>Status Kualifikasi</Th>
              <ThFixed>Aksi</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={index}>
                <TdFixed>{TableNumber(page, dataLength, index)}</TdFixed>
                <Td>
                  <div className="text-center">
                    {val.tgl_vendor_prakualifikasi}
                  </div>
                </Td>
                <Td>
                  <div className="text-center">
                    {val.no_vendor_prakualifikasi}
                  </div>
                </Td>
                <Td>
                  <div className="text-center">{val.kode_vendor}</div>
                </Td>
                <Td>{val.nama_vendor}</Td>
                <Td>{val.jenis_vendor}</Td>
                <Td>
                  <div className="text-center">
                    {val.status_kualifikasi == '0' ? (
                      'Pending'
                    ) : (
                      <>
                        {val.status_kualifikasi == '1' ? (
                          <div>Lolos</div>
                        ) : (
                          <div>Tidak Lolos</div>
                        )}
                        <div>
                          {val.tgl_kualifikasi != null &&
                            val.tgl_kualifikasi.split(' ')[0]}
                        </div>
                      </>
                    )}
                  </div>
                </Td>
                <TdFixed>
                  <div className="text-center">
                    {val.status_kualifikasi == '0' ? (
                      <Button
                        color="primary"
                        onClick={() =>
                          history.push(
                            '/master/kualifikasi-vendor/kualifikasi/' +
                              val.id_vendor_prakualifikasi
                          )
                        }
                      >
                        Kualifikasi
                      </Button>
                    ) : (
                      <Button
                        color="primary"
                        onClick={() =>
                          history.push(
                            '/master/kualifikasi-vendor/detail/' +
                              val.id_vendor_prakualifikasi
                          )
                        }
                      >
                        Detail
                      </Button>
                    )}
                  </div>
                </TdFixed>
              </Tr>
            ))}
          </TBody>
        </CRUDLayout.Table>
        {!isSearching && (
          <Pagination
            dataLength={dataLength}
            dataNumber={page * dataLength - dataLength + 1}
            dataPage={page * dataLength}
            dataCount={dataCount}
            onDataLengthChange={(e) => {
              setDataLength(e.target.value);
              setPage(1);
            }}
            currentPage={page}
            totalPage={totalPage}
            onPaginationChange={({ selected }) => setPage(selected + 1)}
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row>
            <Col md="8">
              <InputSearch
                onChange={(e) => {
                  setTimeout(() => {
                    setSearchKey(e.target.value);
                    setPage(1);
                  }, 1000);
                }}
                onSubmit={(e) => e.preventDefault()}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
      </CRUDLayout.Head>

      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      {/* Table */}
      {
        // cek apakah data sedang dimuat (loading)
        isLoading === true ? (
          <DataStatus loading={true} text="Memuat data..." />
        ) : // Cek apakah ada data
        data.length > 0 ? (
          <Table />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      }
    </CRUDLayout>
  );
};

export default KualifikasiVendor;
