import React from 'react';

const Th = ({ children, ...props }) => {
  return (
    <th
      {...props}
      className={`${props.className ?? ''} text-center align-middle`}
      style={{
        ...props.style,
        fontSize: '14px',
      }}
    >
      {children}
    </th>
  );
};

export default Th;
