import { useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import {
  ActionButton,
  Alert,
  BackButton,
  DataStatus,
  FilterButton,
  InputSearch,
  Pagination,
  Td,
  TdFixed,
  Th,
  ThFixed,
} from 'components';
import { useSessionStorage } from 'hooks';
import { debounce } from 'lodash';
import { DateConvert, RupiahConvert, TableNumber } from 'utilities';
import { PurchaseOrderApi } from 'api';
import { ModalFilterPreform, ModalKonfirmasiUbahStatusPO } from './Components';
import { withDropdownPurchaseOrderContextProvider } from './Contexts';
import { tglInputFormat } from './Utils';
import { IoAddOutline } from 'react-icons/io5';

const PreformPurchaseOrderList = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const paginationKey = `${location.pathname}_pagination`;

  const [isPageLoading, setIsPageLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [data, setData] = useState([]);
  const [modalStatusPO, setModalStatusPO] = useState({
    show: false,
    data: null,
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [pagination, setPagination] = useSessionStorage(paginationKey, {
    q: '',
    page: '1',
    per_page: '10',
    total_page: '1',
    data_count: '0',

    active: false,
    tgl_seleksi_vendor_start: undefined,
    tgl_seleksi_vendor_end: undefined,
    id_vendor: undefined,
    list_item_po: undefined,
  });

  const { total_page, data_count, active, ...query } = pagination;

  const getInitialData = () => {
    setIsPageLoading(true);

    PurchaseOrderApi.getVendor(query)
      .then(({ data }) => {
        setData(data.data);
        setPagination((prev) => ({
          ...prev,
          data_count: data.data_count,
          total_page: data.total_page,
        }));
      })
      .catch((err) =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: `Data gagal dimuat ${err.response.data.message}`,
        })
      )
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState?.alert) {
      setAlertConfig(locationState.alert);
    }
  };

  const dismissAlert = () => {
    history.replace(location?.pathname, { alert: undefined });
    setAlertConfig({ ...alertConfig, show: false });
  };

  useEffect(() => {
    setNavbarTitle('Purchase Order');
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    pagination.page,
    pagination.per_page,
    pagination.tgl_seleksi_vendor_start,
    pagination.tgl_seleksi_vendor_end,
    pagination.id_vendor,
    pagination.list_item_po,
  ]);

  const TableContent = () => {
    const style = { fontSize: 14 };

    const generateNamaItem = (item) => {
      const { nama_item, t, w, l } = item;
      if (t && w && l) {
        return `${nama_item} (${parseFloat(t)} X ${parseFloat(
          w
        )} X ${parseFloat(l)})`;
      }

      return nama_item ?? '-';
    };

    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <div className="mt-2" style={{ fontSize: '.9rem' }}>
          List Seleksi Vendor Yang Siap Dibuatkan Purchase Order
        </div>

        <Table
          bordered
          responsive
          className="bg-white overflow-hidden border-1 shadow-sm"
        >
          <thead>
            <tr>
              <ThFixed>No</ThFixed>
              <Th>Vendor</Th>
              <ThFixed>Informasi Seleksi Vendor</ThFixed>
              <Th>Item Seleksi Vendor</Th>
              <ThFixed>Qty.</ThFixed>
              <ThFixed>Satuan</ThFixed>
              <ThFixed>Harga Kesepakatan</ThFixed>
              <ThFixed>Total Harga</ThFixed>
              <ThFixed>Aksi</ThFixed>
            </tr>
          </thead>

          <tbody>
            {data?.map((val, index) =>
              val?.seleksi_vendor?.map((item, itemIdx) => (
                <tr key={index}>
                  {itemIdx === 0 && (
                    <td
                      rowSpan={val.seleksi_vendor.length}
                      className="text-center"
                      style={style}
                    >
                      {TableNumber(pagination.page, pagination.per_page, index)}
                    </td>
                  )}

                  {itemIdx === 0 && (
                    <td rowSpan={val.seleksi_vendor.length} style={style}>
                      {val.nama_vendor}
                    </td>
                  )}

                  <Td>
                    <div>
                      {item.tgl_seleksi_vendor
                        ? DateConvert(new Date(item.tgl_seleksi_vendor))
                            .defaultDMY
                        : '-'}
                    </div>
                    <div>{item.no_seleksi_vendor ?? '-'}</div>
                  </Td>

                  <Td className="text-nowrap">{generateNamaItem(item)}</Td>

                  <Td textRight className="text-nowrap">
                    {parseFloat(item.qty_order ?? 0)}
                  </Td>

                  <Td textRight className="text-nowrap">
                    {item.nama_satuan ?? item.satuan_beli ?? ''}
                  </Td>

                  <Td textRight>
                    {
                      RupiahConvert(String(Math.round(item.harga_kesepakatan)))
                        .detail
                    }
                  </Td>

                  <Td textRight>
                    {
                      RupiahConvert(
                        String(
                          Math.round(
                            parseFloat(item.harga_kesepakatan ?? 0) *
                              parseFloat(item.qty_order ?? 0)
                          )
                        )
                      ).detail
                    }
                  </Td>

                  {itemIdx === 0 && (
                    <td rowSpan={val.seleksi_vendor.length} style={style}>
                      <ActionButton
                        size="sm"
                        className="m-0"
                        j
                        onClick={() =>
                          history.push(
                            `/transaksi/purchase-order/tambah/${val.id_vendor}`
                          )
                        }
                      >
                        <IoAddOutline />
                      </ActionButton>
                    </td>
                  )}
                </tr>
              ))
            )}
          </tbody>
        </Table>

        <Pagination
          dataLength={pagination.per_page}
          dataNumber={
            pagination.page * pagination.per_page - pagination.per_page + 1
          }
          dataPage={
            pagination.data_count < pagination.per_page
              ? pagination.data_count
              : pagination.page * pagination.per_page
          }
          dataCount={pagination.data_count}
          currentPage={pagination.page}
          totalPage={pagination.total_page}
          onPaginationChange={({ selected }) =>
            setPagination({
              ...pagination,
              page: selected + 1,
            })
          }
          onDataLengthChange={(e) =>
            setPagination({
              ...pagination,
              page: 1,
              per_page: e.target.value,
            })
          }
        />
      </>
    );
  };

  return (
    <>
      <Row className="mb-1">
        <Col className="d-flex flex-row justify-space-between align-items-center">
          <InputSearch
            defaultValue={pagination.q}
            onChange={debounce(
              (e) =>
                setPagination((prv) => ({
                  ...prv,
                  q: e.target.value,
                  page: 1,
                })),
              1500
            )}
          />
          <FilterButton
            active={pagination.active}
            onClick={() => setShowFilter((prev) => !prev)}
          />
        </Col>
        <Col className="d-flex justify-content-end">
          <BackButton onClick={() => history.goBack()} />
        </Col>
      </Row>

      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => dismissAlert()}
      />

      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <TableContent />
      )}

      <ModalKonfirmasiUbahStatusPO
        show={modalStatusPO.show}
        data={modalStatusPO.data}
        onClose={() => setModalStatusPO({ show: false, data: null })}
      />

      <ModalFilterPreform
        show={showFilter}
        setShow={setShowFilter}
        filter={pagination}
        setFilter={setPagination}
      />
    </>
  );
};

export default withDropdownPurchaseOrderContextProvider(
  PreformPurchaseOrderList
);
