import React, { useState, useEffect } from 'react';
import { Button, Card, Spinner } from 'react-bootstrap';
import { IoDocumentTextOutline } from 'react-icons/io5';
import axios from 'axios';
import FileSaver from 'file-saver';
import {
  CRUDLayout,
  Tr,
  Th,
  Td,
  Alert,
  DataStatus,
  TdFixed,
  ThFixed,
  THead,
  TBody,
  DatePicker,
  ActionButton,
  SelectSearch,
} from 'components';
import { DateConvert, RupiahConvert } from 'utilities';
import { LaporanPurchaseOrderApi } from 'api';

const LaporanPurchaseOrder = ({ setNavbarTitle }) => {
  const TODAY = new Date().toISOString().slice(0, 10);
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isExporting, setIsExporting] = useState({ status: false, tipe: '' });
  const [pageLoading, setPageLoading] = useState(true);
  const [form, setForm] = useState({
    tanggal_start: TODAY,
    tanggal_end: TODAY,
    nama_item: null,
    nama_vendor: null,
    tgl_jatuh_tempo: null,
  });
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
    show: false,
  });
  const [atribut, setAtribut] = useState({
    vendor: [],
    atribut: [],
  });

  const mapDataFromServer = (data) => {
    const uniqNumber = [];
    const mappedData = data.map((item) => {
      if (!uniqNumber.includes(item.no_purchase_request)) {
        uniqNumber.push(item.no_purchase_request);
        return item;
      }
      return Object({
        nama_item: item.nama_item,
        nama_satuan: item.nama_satuan,
        qty_order: item.qty_order,
        qty_diterima: item.qty_diterima,
        qty_sisa: item.qty_sisa,
        harga_kesepakatan: item.harga_kesepakatan,
        ppn: item.ppn,
        diskon: item.diskon,
      });
    });
    return mappedData;
  };

  const handleFetchReport = () => {
    setLoading(true);

    LaporanPurchaseOrderApi.getPage(form)
      .then(({ data }) => setData(mapDataFromServer(data.data ?? [])))
      .catch(() =>
        setAlertConfig({
          variant: 'danger',
          text: 'Tidak dapat memuat data!',
          show: true,
        })
      )
      .finally(() => setLoading(false));
  };

  const handleButtonExport = (tipe) => {
    setIsExporting({
      status: true,
      tipe,
    });

    const query = { ...form, tipe };

    LaporanPurchaseOrderApi.getExport(query)
      .then(({ data }) => {
        const ext = tipe === 'excel' ? 'xlsx' : 'pdf';
        const rawPath = data.data.split(':')[1];
        const generatedPath =
          window.location.protocol === 'http:'
            ? `http:${rawPath}`
            : `https:${rawPath}`;

        FileSaver.saveAs(generatedPath, `Laporan_PO.${ext}`);
      })
      .finally(() => setIsExporting({ status: false, tipe: '' }));
  };

  useEffect(() => {
    setNavbarTitle('Laporan Monitoring Purchasing Order (PO)');

    const semua = { value: null, label: 'Semua Data' };
    setPageLoading(true);
    axios
      .all([
        LaporanPurchaseOrderApi.getDropdown({ tipe: 'vendor' }),
        LaporanPurchaseOrderApi.getDropdown({ tipe: 'material' }),
      ])
      .then(
        axios.spread((vendor, material) =>
          setAtribut({
            vendor: [
              {
                id_vendor: semua.value,
                nama_vendor: semua.label,
              },
              ...vendor.data.data,
            ],
            material: [
              {
                id_item_buaso: semua.value,
                nama_item: semua.label,
              },
              ...material.data.data,
            ],
          })
        )
      )
      .finally(() => {
        setPageLoading(false);
      });
  }, [setNavbarTitle]);

  const ExportButton = ({
    disabled = false,
    loading = false,
    variant = 'outline-success',
    text = 'Export EXCEL',
    onClick,
  }) => {
    return (
      <Button
        variant={variant}
        onClick={onClick}
        disabled={disabled}
        className="mx-1 mb-1"
      >
        {loading ? (
          <Spinner animation="border" size="sm" />
        ) : (
          <>
            <IoDocumentTextOutline size={18} />
            <span className="ml-2 text-uppercase">{text}</span>
          </>
        )}
      </Button>
    );
  };

  const TableSection = () => {
    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Tgl. PR</ThFixed>
              <ThFixed>No. PR</ThFixed>
              <ThFixed>Tgl. PO</ThFixed>
              <ThFixed>No. PO</ThFixed>
              <Th>Vendor</Th>
              <Th style={{ minWidth: '250px' }}>Item Bahan</Th>
              <Th>Qty. PO</Th>
              <Th>Harga Satuan</Th>
              <Th>Diskon</Th>
              <Th>Pajak</Th>
              <Th>Sub Total Harga</Th>
              <ThFixed>Penerimaan Barang</ThFixed>
              <ThFixed>Sisa Qty. PO</ThFixed>
              <ThFixed>Tgl. Jatuh Tempo</ThFixed>
              <Th>Alamat Tujuan Pengiriman</Th>
              <Th>Pembayaran</Th>
              <Th>Keterangan</Th>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => {
              const qty_order = val.qty_order ? parseFloat(val.qty_order) : 0;
              const harga_kesepakatan = val.harga_kesepakatan
                ? parseInt(val.harga_kesepakatan)
                : 0;
              const ppn = val.ppn ? parseInt(val.ppn) : 0;
              const diskon = val.diskon ? parseInt(val.diskon) : 0;
              const total = harga_kesepakatan * qty_order;
              const hasil_diskon = total - total * (diskon / 100);
              const hasil_ppn = Math.round(
                hasil_diskon + hasil_diskon * (ppn / 100)
              );

              return (
                <Tr key={index}>
                  <TdFixed>{index + 1}</TdFixed>
                  <TdFixed>
                    {val.tgl_purchase_request
                      ? DateConvert(new Date(val.tgl_purchase_request))
                          .defaultDMY
                      : ''}
                  </TdFixed>
                  <TdFixed>{val.no_purchase_request}</TdFixed>
                  <TdFixed>
                    {val.tgl_purchase_order
                      ? DateConvert(new Date(val.tgl_purchase_order)).defaultDMY
                      : ''}
                  </TdFixed>
                  <TdFixed>{val.no_purchase_order}</TdFixed>
                  <Td>{val.nama_vendor}</Td>
                  <Td>{val.nama_item}</Td>
                  <Td>{`${qty_order} ${val.nama_satuan ?? ''}`}</Td>
                  <Td>{RupiahConvert(String(harga_kesepakatan)).detail}</Td>
                  <TdFixed>{diskon}%</TdFixed>
                  <TdFixed>{ppn}%</TdFixed>
                  <Td>{RupiahConvert(String(hasil_ppn)).detail}</Td>
                  <TdFixed>{val.qty_diterima}</TdFixed>
                  <TdFixed>{val.qty_sisa}</TdFixed>
                  <TdFixed>
                    {val.tgl_jatuh_tempo
                      ? DateConvert(new Date(val.tgl_jatuh_tempo)).defaultDMY
                      : ''}
                  </TdFixed>
                  <Td>{val.alamat_tujuan_pengiriman}</Td>
                  <Td>{val.pembayaran}</Td>
                  <Td>{val.keterangan}</Td>
                </Tr>
              );
            })}
          </TBody>
        </CRUDLayout.Table>
      </>
    );
  };

  if (pageLoading) {
    return <DataStatus loading={true} text="Memuat data ..." />;
  } else {
    return (
      <CRUDLayout>
        <Card className="px-3">
          {/* Periode */}
          <div className="d-flex flex-wrap justify-content-start align-items-center">
            <span className="mr-2 mt-1 text-">Periode:</span>
            <div className="p-2 pl-0">
              <DatePicker
                dateFormat="dd/MM/yyyy"
                label="Tgl. Mulai"
                name="tanggal_start"
                selected={
                  form.tanggal_start ? new Date(form.tanggal_start) : ''
                }
                onChange={(val) => {
                  const value = val.toISOString().slice(0, 10);
                  setForm((prev) => ({ ...prev, tanggal_start: value }));
                }}
              />
            </div>
            <span className="mx-2 mt-1">s.d.</span>
            <div className="p-2 pl-0">
              <DatePicker
                dateFormat="dd/MM/yyyy"
                label="Tgl. Selesai"
                name="tanggal_end"
                selected={form.tanggal_end ? new Date(form.tanggal_end) : ''}
                onChange={(val) => {
                  const value = val.toISOString().slice(0, 10);
                  setForm((prev) => ({ ...prev, tanggal_end: value }));
                }}
              />
            </div>
          </div>

          {/* Filter */}
          <div className="d-flex flex-wrap justify-content-start align-items-center">
            <span className="mr-2 mt-1 text-">Filter By:</span>
            <div className="p-2 pl-0" style={{ width: '200px' }}>
              <SelectSearch
                label="Vendor"
                placeholder="Pilih salah satu..."
                option={atribut.vendor.map((val) => {
                  return {
                    value: val.id_vendor,
                    label: val.nama_vendor,
                  };
                })}
                onChange={(val) =>
                  setForm((prev) => ({
                    ...prev,
                    nama_vendor: val.value !== null ? val.label : null,
                  }))
                }
              />
            </div>
            <div className="p-2" style={{ width: '200px' }}>
              <SelectSearch
                label="Item Bahan"
                placeholder="Pilih salah satu..."
                option={atribut.material.map((val) => {
                  return {
                    value: val.id_item_buaso,
                    label: val.nama_item,
                  };
                })}
                onChange={(val) =>
                  setForm((prev) => ({
                    ...prev,
                    nama_item: val.value !== null ? val.label : null,
                  }))
                }
              />
            </div>
            <div className="p-2">
              <DatePicker
                dateFormat="dd/MM/yyyy"
                label="Tgl. Jatuh tempo"
                name="tgl_jatuh_tempo"
                selected={
                  form.tgl_jatuh_tempo ? new Date(form.tgl_jatuh_tempo) : ''
                }
                onChange={(val) => {
                  const value = val.toISOString().slice(0, 10);
                  setForm((prev) => ({ ...prev, tgl_jatuh_tempo: value }));
                }}
              />
            </div>
            <ActionButton
              className="mt-1 p-50"
              size="sm"
              text="LIHAT DATA"
              onClick={handleFetchReport}
            />
          </div>
        </Card>

        <Alert
          showCloseButton={true}
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() => setAlertConfig((prev) => ({ ...prev, show: false }))}
        />

        {!isLoading && data.length > 0 && (
          <div className="d-flex justify-content-end align-items-center mx-1 mt-4 mb-1">
            <ExportButton
              loading={isExporting.status && isExporting.tipe === 'pdf'}
              disabled={isExporting.status}
              variant="outline-danger"
              text="Export PDF"
              onClick={() => handleButtonExport('pdf')}
            />
            <ExportButton
              loading={isExporting.status && isExporting.tipe === 'excel'}
              disabled={isExporting.status}
              onClick={() => handleButtonExport('excel')}
            />
          </div>
        )}

        {isLoading ? (
          <DataStatus loading={true} text="Memuat data ..." />
        ) : (
          <TableSection />
        )}
      </CRUDLayout>
    );
  }
};

export default LaporanPurchaseOrder;
