// React
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Component
import { Row, Col, Card } from 'react-bootstrap';
import {
  BackButton,
  ActionButton,
  DataStatus,
  Input,
  Th,
  Td,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  DatePicker,
  TextArea,
  SelectSearch,
} from 'components';
import { DateConvert, RupiahConvert } from 'utilities';

// Form
import { Formik } from 'formik';
import * as Yup from 'yup';

// API
import Axios from 'axios';
import { KontrakPrelimApi } from 'api';

const TambahPurchaseOrder = ({ setNavbarTitle }) => {
  const title = 'Kontrak Prelim';
  const history = useHistory();
  const { id_permintaan_prelim, id_item_prelim } = useParams();

  // STATES
  // Data
  const [page, setPage] = useState({
    loading: true,
    error: true,
  });
  const [data, setData] = useState({});
  // History
  const [dataHistory, setDataHistory] = useState([]);
  // Atribut
  const [loadingAtribut, setLoadingAtribut] = useState({
    no_baru: false,
  });
  const [dataAtribut, setDataAtribut] = useState({
    no_baru: '',
    jumlah: 'Rp. 0',
    vendor: [],
  });

  const [loadingJumlah, setLoadingJumlah] = useState(false);

  useEffect(() => {
    setNavbarTitle(`Tambah Data ${title}`);

    setPage({
      loading: true,
      error: false,
    });

    Axios.all([
      KontrakPrelimApi.permintaan_single({
        id_permintaan_prelim,
        id_item_prelim,
      }),
      KontrakPrelimApi.no_baru({ tanggal: DateConvert(new Date()).default }),
      KontrakPrelimApi.dropdown({ tipe: 'vendor' }),
    ])
      .then(
        Axios.spread((data, no_baru, vendor) => {
          const newData = data.data.data ?? {};

          setData(newData);
          setDataAtribut({
            no_baru: no_baru.data.data,
            vendor: vendor.data.data,
          });

          KontrakPrelimApi.page({
            id_permintaan_prelim: newData.id_permintaan_prelim,
            id_item_prelim: newData.id_item_prelim,
          }).then((res) => setDataHistory(res.data.data));

          setPage({
            loading: false,
            error: false,
          });
        })
      )
      .catch(() =>
        setPage({
          loading: false,
          error: true,
        })
      );

    // eslint-disable-next-line
  }, []);

  const getNoBaru = (value) => {
    setLoadingAtribut({ no_baru: true });

    KontrakPrelimApi.no_baru({ tanggal: value })
      .then((res) =>
        setDataAtribut({
          ...dataAtribut,
          no_baru: res.data.data,
        })
      )
      .catch(() =>
        setDataAtribut({
          ...dataAtribut,
          no_baru: 'Data Gagal',
        })
      )
      .finally(() => setLoadingAtribut({ no_baru: false }));
  };

  const getJumlah = (qty_kontrak, harga_satuan) => {
    setLoadingJumlah(true);
    const harga = harga_satuan ? RupiahConvert(harga_satuan).default : 0;

    setDataAtribut({
      ...dataAtribut,
      jumlah: RupiahConvert(
        String(parseInt(parseFloat(qty_kontrak) * parseInt(harga)))
      ).detail,
    });

    setTimeout(() => {
      setLoadingJumlah(false);
    }, 500);
  };

  const formInitialValues = {
    id_permintaan_prelim: data?.id_permintaan_prelim,
    tgl_kontrak_prelim: DateConvert(new Date()).default,
    id_item_prelim: data?.id_item_prelim,
    id_vendor: '',
    qty_kontrak: null,
    harga_satuan: '',
    catatan: '',
  };

  const formValidationSchema = Yup.object().shape({
    tgl_kontrak_prelim: Yup.date()
      .typeError('Format tanggal salah')
      .required('Tanggal Kontrak Prelim wajib diisi'),
    id_vendor: Yup.string().required('Pilih Vendor'),
    qty_kontrak: Yup.string()
      .test('checkQty', 'Qty. Kontrak Melebihi Qty. Permintaan', (value) =>
        parseFloat(value) > parseFloat(data.qty_permintaan) ? false : true
      )
      .required('Masukkan Qty'),
    harga_satuan: Yup.string().required('Harga Satuan wajib diisi'),
  });

  const formSubmitHandler = (values, { setSubmitting }) => {
    values.harga_satuan = RupiahConvert(String(values.harga_satuan)).default;

    KontrakPrelimApi.create(values)
      .then(() =>
        history.push('/transaksi/kontrak-prelim', {
          alert: {
            show: true,
            variant: 'primary',
            text: 'Data berhasil ditambah!',
          },
        })
      )
      .finally(() => setSubmitting(false));
  };

  // COMPONENTS
  const InfoSection = () => {
    const InfoItem = ({ title, value, textStyle, link }) => (
      <tr>
        <td>
          <small>{title}</small>
        </td>
        <td>
          <small className="pl-4 pr-2">:</small>
        </td>
        <td>
          <small
            onClick={() =>
              link
                ? history.push(
                    `/transaksi/kontrak-prelim/tambah/detail-spk/${data.id_spk}`
                  )
                : ''
            }
            style={textStyle}
          >
            {value}
          </small>
        </td>
      </tr>
    );

    return (
      <Row>
        <Col md>
          <table>
            <tbody>
              <InfoItem
                title="Tgl. Permintaan Prelim"
                value={
                  data?.tgl_permintaan_prelim
                    ? DateConvert(new Date(data.tgl_permintaan_prelim)).detail
                    : '-'
                }
              />
              <InfoItem
                title="No. Permintaan Prelim"
                value={data?.no_permintaan_prelim ?? '-'}
              />
              <InfoItem
                title="No. SPK"
                textStyle={{
                  color: 'rgb(119,168,248)',
                  cursor: 'pointer',
                  fontSize: 14,
                }}
                value={data?.no_spk ?? '-'}
                link
              />
            </tbody>
          </table>
        </Col>

        <Col md>
          <table>
            <tbody>
              <InfoItem
                title="Item Permintaan"
                value={
                  data?.nama_item_prelim
                    ? `${
                        data?.kode_item_prelim && `(${data.kode_item_prelim}) `
                      }${data.nama_item_prelim}`
                    : '-'
                }
              />
              <InfoItem
                title="Qty. Permintaan"
                value={
                  data?.qty_permintaan
                    ? `${parseFloat(data?.qty_permintaan)} ${
                        data?.nama_satuan_permintaan ?? ''
                      }`
                    : 0
                }
              />
              <InfoItem
                title="Qty. Telah Ditransfer"
                value={
                  data?.qty_kontrak
                    ? `${parseInt(data?.qty_kontrak)} ${
                        data?.nama_satuan_kontrak ?? ''
                      }`
                    : 0
                }
              />
              <InfoItem
                title="Durasi Prelim"
                value={
                  data?.qty_durasi ? `${parseInt(data?.qty_durasi)} Hari` : 0
                }
              />
            </tbody>
          </table>
        </Col>
      </Row>
    );
  };

  return (
    <Card>
      <Card.Header className="d-flex justify-content-between align-items-center">
        <b>Detail {title}</b>
        <BackButton onClick={() => history.goBack()} />
      </Card.Header>
      <Card.Body>
        {page.loading ? (
          <DataStatus loading={true} text="Memuat data . . ." />
        ) : page.error ? (
          <DataStatus text="Data Tidak Dapat Dimuat" />
        ) : (
          <>
            <InfoSection />
            <hr />
            <Formik
              initialValues={formInitialValues}
              validationSchema={formValidationSchema}
              onSubmit={formSubmitHandler}
            >
              {({
                values,
                errors,
                touched,
                isSubmitting,
                setFieldValue,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col md>
                      <Row>
                        <Col md>
                          <DatePicker
                            label="Tgl. Kontrak Prelim"
                            className="mt-1"
                            selected={
                              values.tgl_kontrak_prelim
                                ? new Date(values.tgl_kontrak_prelim)
                                : ''
                            }
                            onChange={(e) => {
                              const value = e.toISOString().slice(0, 10);
                              setFieldValue('tgl_kontrak_prelim', value);
                              getNoBaru(value);
                            }}
                            error={
                              errors.tgl_kontrak_prelim &&
                              touched.tgl_kontrak_prelim &&
                              true
                            }
                            errorText={errors.tgl_kontrak_prelim}
                          />
                        </Col>

                        <Col md>
                          <Input
                            label="No. Purchase Order"
                            value={
                              loadingAtribut.no_baru
                                ? 'Memuat...'
                                : dataAtribut.no_baru
                            }
                            readOnly
                          />
                        </Col>
                      </Row>

                      <SelectSearch
                        label="Vendor"
                        placeholder="Pilih Vendor"
                        onChange={(val) => {
                          setFieldValue('id_vendor', val.value);
                        }}
                        option={dataAtribut.vendor.map((val) => {
                          return {
                            value: val.id_vendor,
                            label: val.nama_vendor,
                          };
                        })}
                        defaultValue={{
                          value: values.id_vendor ?? '',
                          label:
                            values.id_vendor === ''
                              ? 'Pilih vendor'
                              : dataAtribut.vendor.find(
                                  (val) => val.id_vendor === values.id_vendor
                                )?.nama_vendor ?? 'Pilih vendor',
                        }}
                        error={errors.id_vendor && touched.id_vendor}
                        errorText={
                          errors.id_vendor &&
                          touched.id_vendor &&
                          errors.id_vendor
                        }
                      />

                      <TextArea
                        label="Catatan"
                        placeholder="Catatan"
                        name="catatan"
                        value={values.catatan}
                        onChange={handleChange}
                        error={errors.catatan && touched.catatan && true}
                        errorText={errors.catatan}
                      />
                    </Col>
                    <Col>
                      <Input
                        label="Item Prelim"
                        value={
                          data?.nama_item_prelim
                            ? `${
                                data?.kode_item_prelim &&
                                `(${data.kode_item_prelim}) `
                              }${data.nama_item_prelim}`
                            : '-'
                        }
                        readOnly
                      />

                      <Row>
                        <Col md="6">
                          <Input
                            label="Qty. Kontrak"
                            name="qty_kontrak"
                            autoComplete="off"
                            placeholder="Masukan qty"
                            value={values.qty_kontrak}
                            onChange={(e) => {
                              setFieldValue('qty_kontrak', e.target.value);

                              getJumlah(e.target.value, values.harga_satuan);
                            }}
                            error={
                              errors.qty_kontrak && touched.qty_kontrak && true
                            }
                            errorText={errors.qty_kontrak}
                          />
                        </Col>

                        <Col md="6">
                          <Input
                            label="Satuan Kontrak"
                            value={data?.nama_satuan_permintaan ?? '-'}
                            readOnly
                          />
                        </Col>

                        <Col md="6">
                          <Input
                            label="Harga Satuan Kontrak"
                            name="harga_satuan"
                            autoComplete="off"
                            value={values.harga_satuan}
                            placeholder="Masukan harga"
                            onChange={(e) => {
                              const val = e.target.value;
                              const convert = RupiahConvert(
                                val.toString()
                              ).default;
                              const value = Number.isInteger(convert)
                                ? RupiahConvert(String(convert)).detail
                                : 'Rp. 0';
                              setFieldValue('harga_satuan', value);

                              getJumlah(values.qty_kontrak, value);
                            }}
                            error={
                              errors.harga_satuan &&
                              touched.harga_satuan &&
                              true
                            }
                            errorText={errors.harga_satuan}
                          />
                        </Col>

                        <Col md="6">
                          <Input
                            label="Durasi Prelim"
                            value={
                              data?.qty_durasi
                                ? `${parseInt(data?.qty_durasi)} Hari`
                                : 0
                            }
                            readOnly
                          />
                        </Col>
                      </Row>

                      <Input
                        label="Jumlah"
                        value={
                          loadingJumlah ? 'Memuat ....' : dataAtribut.jumlah
                        }
                        readOnly
                      />
                    </Col>
                  </Row>
                  <hr />
                  <div className="py-2 pl-1">
                    <b>History Kontrak Prelim</b>
                  </div>
                  <Table>
                    <THead>
                      <Tr className="text-center">
                        <ThFixed>No</ThFixed>
                        <ThFixed>Tgl. Kontrak Prelim</ThFixed>
                        <ThFixed>No. Kontrak Prelim</ThFixed>
                        <Th>Item Prelim</Th>
                        <Th>Qty. Kontrak Prelim</Th>
                        <Th>Vendor</Th>
                        <ThFixed>Harga Satuan Kontrak</ThFixed>
                        <ThFixed>Jumlah</ThFixed>
                      </Tr>
                    </THead>
                    <TBody>
                      {dataHistory.length > 0 ? (
                        dataHistory.map((val, index) => (
                          <Tr key={index}>
                            <TdFixed>{index + 1}</TdFixed>
                            <TdFixed>
                              {val.tgl_kontrak_prelim
                                ? DateConvert(new Date(val.tgl_kontrak_prelim))
                                    .defaultDMY
                                : '-'}
                            </TdFixed>
                            <TdFixed>{val.no_kontrak_prelim ?? '-'}</TdFixed>
                            <Td>
                              {val.nama_item_prelim
                                ? `${
                                    val.kode_item_prelim &&
                                    `(${val.kode_item_prelim}) `
                                  }${val.nama_item_prelim}`
                                : '-'}
                            </Td>
                            <Td>
                              {val.qty_kontrak
                                ? `${parseInt(val.qty_kontrak)} ${
                                    val.nama_satuan_permintaan ?? ''
                                  }`
                                : 0}
                            </Td>
                            <Td>{val.nama_vendor ?? '-'}</Td>
                            <Td>
                              {val.harga_satuan
                                ? RupiahConvert(
                                    String(parseInt(val.harga_satuan))
                                  ).detail
                                : 'Rp. 0'}
                            </Td>
                            <Td>
                              {Boolean(val.harga_satuan && val.qty_kontrak)
                                ? RupiahConvert(
                                    String(
                                      parseInt(val.harga_satuan) *
                                        parseInt(val.qty_kontrak)
                                    )
                                  ).detail
                                : 'Rp. 0'}
                            </Td>
                          </Tr>
                        ))
                      ) : (
                        <Tr>
                          <TdFixed colSpan={8}>
                            <DataStatus text="Tidak ada data" />
                          </TdFixed>
                        </Tr>
                      )}
                    </TBody>
                  </Table>

                  <div className="text-right">
                    <ActionButton
                      type="submit"
                      text="Simpan"
                      loading={isSubmitting}
                    />
                  </div>
                </form>
              )}
            </Formik>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default TambahPurchaseOrder;
