import React, { PureComponent } from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  DataStatus,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  TextArea,
} from 'components';
import { DateConvert, RupiahConvert } from 'utilities';
import Logo from '../../../assets/image/LogoMGP.jpeg';
import TTDBTS from '../../../assets/image/ttdBTS.png';
import TTD2 from '../../../assets/image/TTD2.png';
import TTD3 from '../../../assets/image/TTD3.png';
import { IoCheckmarkSharp, IoArrowBackOutline } from 'react-icons/io5';

const PrintContent = ({
  dataKualifikasiVendor,
  dataKualifikasiVendorDetail,
}) => {
  console.log('print', dataKualifikasiVendor);
  const ShowData = ({ title, text }) => (
    <div className="d-flex flex-row">
      <div className="text-secondary mb-2 mr-3" style={{ width: 170 }}>
        {title}
      </div>
      <span>:</span>
      <div
        className="text-dark"
        style={{
          paddingLeft: 5,
        }}
      >
        {text ? text : '-'}
      </div>
    </div>
  );

  const InfoItems = ({ title, value }) => (
    <tr>
      <td>{title}</td>
      <td className="pl-4 pr-2">:</td>
      <td>{value}</td>
    </tr>
  );

  let total = 0;
  let nilaiDiskon = 0;
  let setelahDiskon = 0;
  let nilaiPPN = 0;
  let setelahPPN = 0;

  dataKualifikasiVendorDetail.map((val, index) => {
    total = total + val.harga_kesepakatan * val.qty_order;
    nilaiDiskon = dataKualifikasiVendor.diskon
      ? parseInt(dataKualifikasiVendor.diskon) === 0
        ? 0
        : parseInt((dataKualifikasiVendor.diskon / 100) * total)
      : 0;
    setelahDiskon = parseInt(total - nilaiDiskon);
    nilaiPPN = dataKualifikasiVendor.ppn
      ? parseInt(dataKualifikasiVendor.ppn) === 0
        ? 0
        : parseInt((dataKualifikasiVendor.ppn / 100) * setelahDiskon)
      : 0;
    setelahPPN = parseInt(setelahDiskon + nilaiPPN);
  });

  console.log(dataKualifikasiVendor);

  const CatatanSection = () => (
    <div>
      <div>Pesanan ini selambat-lambatnya telah sampai pada :</div>
      <Row>
        <Col>
          <table>
            <tbody>
              <InfoItems
                title="Tanggal"
                value={
                  dataKualifikasiVendor.tgl_pengiriman
                    ? DateConvert(
                        new Date(dataKualifikasiVendor.tgl_pengiriman)
                      ).detail
                    : '-'
                }
              />
              <InfoItems
                title="Pambayaran"
                value={dataKualifikasiVendor.pembayaran}
              />
              <InfoItems
                title="Dikirim ke"
                value={dataKualifikasiVendor.alamat_tujuan_pengiriman}
              />
            </tbody>
          </table>
        </Col>
        <Col>
          <div className="d-flex justify-content-end">
            <table>
              <tbody>
                <InfoItems
                  title="Sub Total"
                  value={RupiahConvert(total.toString()).detail}
                />
                <InfoItems
                  title={`Diskon ${dataKualifikasiVendor.diskon} %`}
                  value={RupiahConvert(nilaiDiskon.toString()).detail}
                />
                <InfoItems
                  title="Pengenaan Pajak"
                  value={RupiahConvert(setelahDiskon.toString()).detail}
                />
                <InfoItems
                  title={`PPN ${dataKualifikasiVendor.ppn} %`}
                  value={RupiahConvert(nilaiPPN.toString()).detail}
                />
                <InfoItems
                  title={<b>Total</b>}
                  value={RupiahConvert(setelahPPN.toString()).detail}
                />
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    </div>
  );

  const KopSuratSection = () => (
    <div className="d-flex mx-2">
      <div>
        <img src={Logo} width={110} />
      </div>
      <div style={{ marginLeft: 30 }}>
        <p>
          <b>PT. MARDIKA GRIYA PRASTA</b>
          <br />
          Jl. Sekarsari No.30, Kesambi, Denpasar, Bali, Indonesia <br />
          Phone +62(0)361 467-342 <br />
          Fax (0361) 46730 <br />
          Email sales@mgpwoodworks.com <br />
        </p>
      </div>
    </div>
  );

  const InfoSection = () => {
    return (
      <>
        <KopSuratSection />
        <Row>
          <Col>
            <div className="mb-2">
              <ShowData
                title="Tanggal Prakualifikasi"
                text={dataKualifikasiVendor?.tgl_vendor_prakualifikasi}
              />

              <ShowData
                title="No Prakualifikasi"
                text={dataKualifikasiVendor?.no_vendor_prakualifikasi}
              />

              <ShowData
                title="Status Hukum Vendor"
                text={
                  dataKualifikasiVendor?.status_hukum
                    ? 'Berbadan Hukum'
                    : 'Tidak Berbadan Hukum'
                }
              />
            </div>

            <div className="mb-2">
              <ShowData
                title="Kode Vendor"
                text={dataKualifikasiVendor?.kode_vendor}
              />

              <ShowData
                title="Nama Vendor"
                text={dataKualifikasiVendor?.nama_vendor}
              />

              <ShowData
                title="Jenis Vendor"
                text={
                  dataKualifikasiVendor?.jenis_vendor
                    ? dataKualifikasiVendor?.jenis_vendor
                        .map((val) => val.jenis_vendor)
                        .join(', ')
                    : ''
                }
              />

              <ShowData
                title="Pimpinan Perusahaan"
                text={dataKualifikasiVendor?.nama_pimpinan_perusahaan}
              />

              <ShowData
                title="Jumlah Karyawan"
                text={dataKualifikasiVendor?.jumlah_karyawan}
              />
            </div>
          </Col>

          <Col>
            <div className="mb-2">
              <ShowData
                title="Contact Person"
                text={dataKualifikasiVendor?.contact_person}
              />

              <ShowData title="Telepon" text={dataKualifikasiVendor?.telepon} />

              <ShowData
                title="Email"
                text={dataKualifikasiVendor?.kontak?.email}
              />

              <ShowData
                title="Website"
                text={dataKualifikasiVendor?.kontak?.website}
              />

              <ShowData
                title="Media Sosial"
                text={dataKualifikasiVendor?.kontak?.media_sosial}
              />
            </div>

            <div className="mb-2">
              <ShowData
                title="Alamat Vendor"
                text={dataKualifikasiVendor?.alamat?.alamat_vendor}
              />

              <ShowData
                title="Desa"
                text={dataKualifikasiVendor?.alamat?.nama_desa}
              />

              <ShowData
                title="Kecamatan"
                text={dataKualifikasiVendor?.alamat?.nama_kecamatan}
              />

              <ShowData
                title="Kabupaten"
                text={dataKualifikasiVendor?.alamat?.nama_kabupaten}
              />

              <ShowData
                title="Provinsi"
                text={dataKualifikasiVendor?.alamat?.provinsi}
              />

              <ShowData
                title="Kode Pos"
                text={dataKualifikasiVendor?.alamat?.kode_pos}
              />
            </div>
          </Col>
        </Row>
      </>
    );
  };

  const TTDSection = () => {
    const TTDItems = ({ title, value, jabatan, src }) => (
      <Col className="text-center">
        <div>{title}</div>
        {/* <div style={{ height: 80 }}></div> */}
        <div>
          <img src={src} height="80" />
        </div>
        <div>
          <u>{value}</u>
        </div>
        <div>{jabatan}</div>
      </Col>
    );

    return (
      <Row className="mt-5 pt-3">
        <TTDItems
          title="Disahkan,"
          value="A.A Ngurah Temaja Putra"
          src={TTDBTS}
          jabatan="Direktur"
        />
        <TTDItems
          title="Disetujui,"
          value="I Ketut Nukayasa"
          src={TTD2}
          jabatan="Manager Adm & Keu"
        />
        <TTDItems
          title="Dibuat Oleh"
          value="Putu Subawa"
          src={TTD3}
          jabatan="Procurement"
        />
      </Row>
    );
  };

  const ListItemPurchaseOrderSection = () => {
    let total = 0;
    let nilaiDiskon = 0;
    let setelahDiskon = 0;
    let nilaiPPN = 0;
    let setelahPPN = 0;

    const TableFooterItems = ({ text, input, value }) => (
      <Tr>
        <Td colSpan={6} textRight>
          <b>{text}</b>
        </Td>
        <Td textRight colSpan={2}>
          <div className="d-flex align-items-center justify-content-between text-nowrap">
            {input ? (
              <div className="d-flex justify-content-center align-items-center">
                <div style={{ width: '70px' }}>{input}</div>
                <div className="pb-1 ml-2">
                  <b>%</b>
                </div>
              </div>
            ) : (
              <div></div>
            )}
            <div className="text-right">
              <b>{value}</b>
            </div>
          </div>
        </Td>
      </Tr>
    );

    return (
      <>
        {dataKualifikasiVendor.jenis_vendor
          ? dataKualifikasiVendor.jenis_vendor.map((value) => {
              if (value.jenis_vendor == 'Mandor') {
                return (
                  <>
                    <small>
                      List Item Upah Yang Disediakan Vendor Ini Sebagai Mandor
                    </small>
                    <Table>
                      <THead>
                        <Tr>
                          <Th>No</Th>
                          <Th>Kode Item Bahan</Th>
                          <Th>Nama Item Bahan</Th>
                          <Th>Satuan Pakai</Th>
                        </Tr>
                      </THead>

                      <TBody>
                        {dataKualifikasiVendor.vendor_item_upah.length > 0 ? (
                          dataKualifikasiVendor.vendor_item_upah.map(
                            (val, index) => (
                              <Tr key={index}>
                                <Td>
                                  <div className="text-center">{index + 1}</div>
                                </Td>
                                <Td>
                                  <div className="text-center">
                                    {val.kode_item}
                                  </div>
                                </Td>
                                <Td>{val.nama_item}</Td>
                                <Td>{val.nama_satuan}</Td>
                              </Tr>
                            )
                          )
                        ) : (
                          <Tr>
                            <td
                              colSpan="3"
                              className="my-2 font-weight-bold text-center"
                            >
                              Tidak Ada Data
                            </td>
                          </Tr>
                        )}
                      </TBody>
                    </Table>
                  </>
                );
              } else if (value.jenis_vendor == 'Subcon') {
                return (
                  <>
                    <small>
                      List Item Subcon Yang Disediakan Vendor Ini Sebagai Subcon
                    </small>
                    <Table>
                      <THead>
                        <Tr>
                          <Th>No</Th>
                          <Th>Kode Item Subcon</Th>
                          <Th>Nama Item Subcon</Th>
                          <Th>Kelompok Subcon</Th>
                          <Th>Satuan Pakai</Th>
                        </Tr>
                      </THead>

                      <TBody>
                        {dataKualifikasiVendor.vendor_item_subcon.length > 0 ? (
                          dataKualifikasiVendor.vendor_item_subcon.map(
                            (val, index) => (
                              <Tr key={index}>
                                <Td>
                                  <div className="text-center">{index + 1}</div>
                                </Td>
                                <Td>
                                  <div className="text-center">
                                    {val.kode_item}
                                  </div>
                                </Td>
                                <Td>{val.nama_item}</Td>
                                <Td>{val.nama_kelompok}</Td>
                                <Td>{val.nama_satuan}</Td>
                              </Tr>
                            )
                          )
                        ) : (
                          <Tr>
                            <td
                              colSpan="5"
                              className="my-2 font-weight-bold text-center"
                            >
                              Tidak Ada Data
                            </td>
                          </Tr>
                        )}
                      </TBody>
                    </Table>
                  </>
                );
              } else if (value.jenis_vendor == 'Supplier') {
                return (
                  <>
                    <small>
                      List Item Bahan Yang Disediakan Vendor Ini Sebagai
                      Supplier
                    </small>
                    <Table>
                      <THead>
                        <Tr>
                          <Th>No</Th>
                          <Th>Kode Item Bahan</Th>
                          <Th>Nama Item Bahan</Th>
                          <Th>Satuan Pakai</Th>
                          <Th>Jumlah</Th>
                        </Tr>
                      </THead>

                      <TBody>
                        {dataKualifikasiVendor.jumlah_item.length > 0 ? (
                          dataKualifikasiVendor.jumlah_item.map(
                            (val, index) => (
                              <Tr key={index}>
                                <Td>
                                  <div className="text-center">{index + 1}</div>
                                </Td>
                                <Td>
                                  <div className="text-center">
                                    {val.kode_item}
                                  </div>
                                </Td>
                                <Td>{val.nama_item}</Td>
                                <Td>{val.nama_satuan}</Td>
                                <Td>{val.jumlah_item}</Td>
                              </Tr>
                            )
                          )
                        ) : (
                          <Tr>
                            <td
                              colSpan="6"
                              className="my-2 font-weight-bold text-center"
                            >
                              Tidak Ada Data
                            </td>
                          </Tr>
                        )}
                      </TBody>
                    </Table>
                  </>
                );
              }
            })
          : ''}

        <div className="mb-2">
          <strong>Lama Vendor Menyediakan Item : </strong>
          {dataKualifikasiVendor.lama_menyediakan_item == '1'
            ? 'Kurang Dari 1 (Satu) Tahun'
            : dataKualifikasiVendor.lama_menyediakan_item == '2'
            ? 'Antara 1 (Satu) Sampai 3 (Tiga) Tahun'
            : 'Diatas 3 (Tiga) Tahun'}
        </div>

        <small>Kelengkapan Dokumen</small>
        <Table>
          <THead>
            <Tr>
              <Th>No</Th>
              <Th>Uraian</Th>
              <Th>Ada</Th>
              <Th>Tidak Ada</Th>
            </Tr>
          </THead>

          <TBody>
            {dataKualifikasiVendor.dokumen_utama
              ? dataKualifikasiVendor.dokumen_utama.map((val, index) => {
                  return (
                    <Tr key={index}>
                      <TdFixed>{index + 1}</TdFixed>
                      <Td>{val.nama_dokumen}</Td>
                      <TdFixed>
                        {val.ada_atau_tidak ? <IoCheckmarkSharp /> : <div />}
                      </TdFixed>
                      <TdFixed>
                        {!val.ada_atau_tidak ? <IoCheckmarkSharp /> : <div />}
                      </TdFixed>
                    </Tr>
                  );
                })
              : ''}
          </TBody>
        </Table>

        <small>Dokumen Lainnya</small>
        <Table>
          <THead>
            <Tr>
              <Th>No</Th>
              <Th>Uraian</Th>
            </Tr>
          </THead>

          <TBody>
            {dataKualifikasiVendor.dokumen_lainnya ? (
              dataKualifikasiVendor.dokumen_lainnya.map((val, index) => {
                return (
                  <Tr key={index}>
                    <TdFixed>{index + 1}</TdFixed>
                    <Td>{val.nama_dokumen}</Td>
                  </Tr>
                );
              })
            ) : (
              <td colSpan="2" className="my-2 font-weight-bold text-center">
                Tidak Ada Data
              </td>
            )}
          </TBody>
        </Table>

        <small>Kesimpulan Kualifikasi</small>
        <div className="d-flex">
          <div className="d-inline mr-2">Status Kualifikasi : </div>
          <div className="d-inline font-weight-bold">
            {dataKualifikasiVendor.status_kualifikasi == '1'
              ? 'Lolos'
              : dataKualifikasiVendor.status_kualifikasi == '2'
              ? 'Tidak Lolos'
              : 'Error'}
          </div>
        </div>

        <TextArea
          label="Catatan"
          type="text"
          rows={4}
          disabled={true}
          value={dataKualifikasiVendor.catatan_kualifikasi}
        />
      </>
    );
  };

  return (
    <div className="p-4">
      <InfoSection />
      <ListItemPurchaseOrderSection />
    </div>
  );
};

export default class PrintPurchaseOrder extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <PrintContent
        dataKualifikasiVendor={this.props.dataKualifikasiVendor}
        dataKualifikasiVendor={this.props.dataKualifikasiVendor}
        dataKualifikasiVendorDetail={this.props.dataKualifikasiVendorDetail}
      />
    );
  }
}
