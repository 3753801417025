import { createContext } from 'react';
import { useQuery } from 'react-query';
import { PermintaanSubkonProduksiApi } from 'api';
import { useQueryDropdown } from 'config/useQueryDropdown';

export const DropdownTransferSubkonContext = createContext();

export const DropdownTransferSubkonContextProvider = ({ children }) => {
  const dropdownMenuKey = 'transfer_upah_produksi';

  const dropdownStatusPermintaan = [
    { value: 'waiting', label: 'Waiting' },
    { value: 'on_progress', label: 'On Progress' },
    { value: 'done', label: 'Done' },
  ];

  const dropdownVendor = useQuery(
    ['dropdown', 'vendor', dropdownMenuKey],
    () =>
      PermintaanSubkonProduksiApi.getDropdownKontrakSubkon({
        tipe: 'vendor',
      }).then((res) =>
        res.data.data.map((k) => ({
          ...k,
          value: k.id_vendor,
          label: k.nama_vendor,
        }))
      ),
    useQueryDropdown
  );

  const dropdownSatuan = useQuery(
    ['dropdown', 'satuan', dropdownMenuKey],
    () =>
      PermintaanSubkonProduksiApi.getDropdownKontrakSubkon({
        tipe: 'satuan',
      }).then((res) =>
        res.data.data.map((k) => ({
          value: k.id_satuan,
          label: k.nama_satuan,
        }))
      ),
    useQueryDropdown
  );

  const dropdownCustomer = useQuery(
    ['dropdown', 'customer', dropdownMenuKey],
    () =>
      PermintaanSubkonProduksiApi.dropdown({ tipe: 'customer' }).then((res) =>
        res.data.data.map((k) => ({
          value: k.id_customer,
          label: k.nama_customer,
        }))
      ),
    useQueryDropdown
  );

  const dropdownProyek = useQuery(
    ['dropdown', 'proyek', dropdownMenuKey],
    () =>
      PermintaanSubkonProduksiApi.dropdown({ tipe: 'proyek' }).then((res) =>
        res.data.data.map((k) => ({
          value: k.id_proyek,
          label: k.nama_proyek,
        }))
      ),
    useQueryDropdown
  );

  const dropdownItem = useQuery(
    ['dropdown', 'item', dropdownMenuKey],
    () =>
      PermintaanSubkonProduksiApi.dropdown({ tipe: 'item' }).then((res) =>
        res.data.data.map((k) => ({
          value: k.id_item_buaso,
          label: `${k.kode_item} - ${k.nama_item}`,
        }))
      ),
    useQueryDropdown
  );

  return (
    <DropdownTransferSubkonContext.Provider
      value={{
        dropdownStatusPermintaan,
        dropdownVendor,
        dropdownSatuan,
        dropdownCustomer,
        dropdownProyek,
        dropdownItem,
      }}
    >
      {children}
    </DropdownTransferSubkonContext.Provider>
  );
};

export const withDropdownTransferSubkonContextProvider =
  (Component) => (props) => {
    return (
      <DropdownTransferSubkonContextProvider>
        <Component {...props} />
      </DropdownTransferSubkonContextProvider>
    );
  };
