import { Fragment, useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import ReactToPrint from 'react-to-print';
import { Formik } from 'formik';
import {
  BackButton,
  Alert,
  DataStatus,
  Approval,
  ActionButton,
} from 'components';
import { PurchaseOrderApi } from 'api';
import {
  InformasiVendorSection,
  InfoSection,
  TableListPurchaseOrder,
  PrintPurchaseOrder,
} from './Components';

const DetailPurchaseOrder = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id_purchase_order } = useParams();
  const componentRef = useRef();

  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [dataPurchaseOrder, setDataPurchaseOrder] = useState({
    info: {},
    detail: [],
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    PurchaseOrderApi.getSingle({ id_purchase_order })
      .then((res) => {
        const data = res.data.data ?? {};
        const { po_detail, ...info } = data;

        const detail = po_detail.map((e) => ({
          ...e,
          checked: true,
        }));

        setDataPurchaseOrder({ info, detail });
      })
      .catch(() => setIsFetchingFailed(true))
      .finally(() => setIsPageLoading(false));
  };

  useEffect(() => {
    setNavbarTitle('Purchase Order');
    getInitialData();

    return () => setIsPageLoading(false);
  }, []);

  const initialValues = {
    id_purchase_order,
    no_purchase_order: dataPurchaseOrder.info.no_purchase_order,
    tgl_purchase_order: dataPurchaseOrder.info.tgl_purchase_order,
    tgl_pengiriman: dataPurchaseOrder.info.tgl_pengiriman,
    tgl_jatuh_tempo: dataPurchaseOrder.info.tgl_jatuh_tempo,
    nama_vendor: dataPurchaseOrder.info.nama_vendor,
    kode_vendor: dataPurchaseOrder.info.kode_vendor,
    alamat_tujuan_pengiriman:
      dataPurchaseOrder.info.alamat_tujuan_pengiriman ?? '',
    alamat_vendor: dataPurchaseOrder.info.alamat_vendor,
    contact_person: dataPurchaseOrder.info.contact_person,
    keterangan: dataPurchaseOrder.info.keterangan ?? '',
    diskon: parseFloat(dataPurchaseOrder.info.diskon ?? 0),
    ppn: parseFloat(dataPurchaseOrder.info.ppn ?? 0),
    dp: parseFloat(dataPurchaseOrder.info.dp ?? 0),
    pembayaran: dataPurchaseOrder.info.pembayaran ?? '',
    detail: dataPurchaseOrder?.detail ?? [],
    status_approval: dataPurchaseOrder.info.status_approval,
    status_purchase_order: dataPurchaseOrder.info.status_purchase_order,
  };

  return (
    <Fragment>
      <Row className="d-flex justify-content-between align-items-end mb-1">
        <Col>
          <b>Detail Data Purchase Order</b>
        </Col>
        <Col className="d-flex justify-content-end ">
          {!isPageLoading &&
            dataPurchaseOrder.info.status_approval === 'APP' && (
              <ReactToPrint
                trigger={() => (
                  <ActionButton className="mb-1 mx-2" text="Cetak PO" />
                )}
                content={() => componentRef.current}
              />
            )}

          <BackButton onClick={() => history.goBack()} />
        </Col>
      </Row>

      <Alert
        showCloseButton
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {isPageLoading || isFetchingFailed ? (
        <DataStatus
          loading={isPageLoading}
          text={isFetchingFailed ? 'Data gagal dimuat!' : 'Memuat data...'}
        />
      ) : (
        <Formik enableReinitialize initialValues={initialValues}>
          {() => (
            <Fragment>
              <InfoSection />
              <InformasiVendorSection />
              <TableListPurchaseOrder type="DETAIL" />
              <Approval
                data={{ stakeholder: dataPurchaseOrder.info.stakeholder }}
              />

              <div style={{ display: 'none' }}>
                <PrintPurchaseOrder
                  ref={componentRef}
                  dataPurchaseOrder={dataPurchaseOrder.info}
                  dataPODetail={dataPurchaseOrder.detail}
                />
              </div>
            </Fragment>
          )}
        </Formik>
      )}
    </Fragment>
  );
};
export default DetailPurchaseOrder;
