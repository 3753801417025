import React, { useState, useEffect } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import NumberFormat from 'react-number-format';

import {
  CRUDLayout,
  Input,
  InputSearch,
  Tr,
  Th,
  Td,
  ActionButton,
  DataStatus,
  TdFixed,
  ThFixed,
  UpdateModal,
  THead,
  TBody,
  Alert,
  Pagination,
  Select,
  InputCurrency,
} from 'components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { HargaPerkiraanSendiriApi } from '../../api';
import { TableNumber, RupiahConvert, DateConvert } from '../../utilities';
import { useIsGuest } from 'hooks';

const HargaPerkiraanSendiri = ({ setNavbarTitle }) => {
  const isGuest = useIsGuest();
  const title = 'Harga Perkiraan Sendiri';
  const buaso = [
    {
      label: 'Semua Data',
      value: '',
    },
    {
      label: 'Bahan',
      value: 'Bahan',
    },
    {
      label: 'Upah',
      value: 'Upah',
    },
    {
      label: 'Alat dan mesin',
      value: 'Alat dan mesin',
    },
    {
      label: 'Subkon',
      value: 'Subcon',
    },
    {
      label: 'Prelim',
      value: 'Prelim',
    },
  ];

  // STATE LOADING
  const [isLoading, setIsLoading] = useState(false);

  // MODALS SHOW STATE
  const [isUpdateForm, setIsUpdateForm] = useState(false); // MODAL UPDATE STATE

  // STATE DATA SATUAN
  const [data, setData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(1);
  const [key, setKey] = useState('');
  const [filter, setFilter] = useState({
    label: 'Semua Data',
    value: '',
  });
  const [dataLength, setDataLength] = useState(10);
  const [totalPage, setTotalPage] = useState(1);
  // STATE MENAMPUNG DATA YANG AKAN DI UPDATE
  const [updateData, setUpdateData] = useState([
    {
      harga_perkiraan_sendiri: 10000,
      detail: {},
      grup_buaso: 'B',
    },
  ]);
  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false);
  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
  });

  // FUNCTION GET DATA DARI SERVER
  const getData = () => {
    setIsLoading(true);

    const param = {
      page: page,
      per_page: dataLength,
      q: key,
    };
    if (filter.value !== '') {
      param.grup_buaso = filter.value;
    }

    HargaPerkiraanSendiriApi.page(param)
      .then((res) => {
        setData(res.data.data);
        setTotalPage(res.data.total_page);
        setDataCount(res.data.data_count);
      })
      .catch((err) => alert(err))
      .finally(() => {
        if (key !== '') {
          setAlertConfig({
            variant: 'primary',
            text: `Hasil Pencarian : ${key}`,
          });
          setShowAlert(true);
        }
        setIsLoading(false);
      });
  };

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle(title); // SET JUDUL NAVBAR
    getData(); // GET DATA DARI SERVER

    return () => {
      setIsLoading(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNavbarTitle, page, dataLength, key, filter]);

  // FORMIK VALIDATION SCHEMA DENGAN YUP VALIDATION
  const formValidationSchema = Yup.object().shape({
    hps_beli: Yup.string().required(
      'Harga Perkiraan Sendiri Per Satuan Beli wajib diisi'
    ),
    hps_pakai: Yup.string().required(
      'Harga Perkiraan Sendiri Per Satuan Pakai wajib diisi'
    ),
  });

  // MODAL UBAH COMPONENT
  const UbahModal = () => {
    const formInitialValues = {
      id_item_hps: updateData.id_item,
      hps_beli: updateData.hps_beli,
      hps_pakai: updateData.hps_pakai,
      buaso: String(updateData.grup_buaso).charAt(0).toUpperCase(),
      tgl_harga_perkiraan_sendiri: DateConvert(new Date()).default,
    };

    // KIRIM UPDATE DATA KE SERVER
    const formSubmitHandler = (values) => {
      const finalValues = {
        ...values,
        hps_beli: values.hps_beli,
        hps_pakai: values.hps_pakai,
      };

      // SEND UPDATED DATA TO SERVER
      HargaPerkiraanSendiriApi.update(finalValues)
        .then(() => {
          // SUCCESS ALERT
          setShowAlert(true);
          setAlertConfig({
            variant: 'primary',
            text: 'Ubah data berhasil!',
          });
        })
        .catch((err) => {
          // ERROR ALERT
          setShowAlert(true);
          setAlertConfig({
            variant: 'danger',
            text: `Ubah data gagal! (${err.response.data.message})`,
          });
        })
        .finally(() => {
          // CLOSE UPDATE MODAL FORM
          setIsUpdateForm(false);
          // FETCH DATA DARI SERVER
          getData();
        });
    };

    return (
      <UpdateModal
        show={isUpdateForm}
        onHide={() => setIsUpdateForm(false)}
        title={title}
      >
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Row>
                  <Col md={3}>
                    <small>Kode Item</small>
                  </Col>
                  <Col md={9}>
                    <small>
                      : {updateData.kode_item ? updateData.kode_item : '-'}
                    </small>
                  </Col>

                  <Col md={3}>
                    <small>Nama Item</small>
                  </Col>
                  <Col md={9}>
                    <small>
                      : {updateData.nama_item ? updateData.nama_item : '-'}
                    </small>
                  </Col>

                  <Col md={3}>
                    <small>Grup BUASO</small>
                  </Col>
                  <Col md={9}>
                    <small>
                      : {updateData.grup_buaso ? updateData.grup_buaso : '-'}
                    </small>
                  </Col>

                  <Col md={3}>
                    <small>Satuan Pakai</small>
                  </Col>
                  <Col md={9}>
                    <small>
                      :{' '}
                      {updateData.satuan_pakai ? updateData.satuan_pakai : '-'}
                    </small>
                  </Col>

                  <Col md={3}>
                    <small>Satuan Beli</small>
                  </Col>
                  <Col md={9}>
                    <small>
                      : {updateData.satuan_beli ? updateData.satuan_beli : '-'}
                    </small>
                  </Col>
                </Row>
                <hr></hr>

                <InputCurrency
                  label="Harga Perkiraan Sendiri Per Satuan Pakai"
                  value={parseFloat(values.hps_pakai)}
                  onChange={(e) => setFieldValue('hps_pakai', e)}
                  error={errors.hps_pakai && touched.hps_pakai && true}
                  errorText={errors.hps_pakai}
                />

                <InputCurrency
                  label="Harga Perkiraan Sendiri Per Satuan Beli"
                  value={parseFloat(values.hps_beli)}
                  onChange={(e) => setFieldValue('hps_beli', e)}
                  error={errors.hps_beli && touched.hps_beli && true}
                  errorText={errors.hps_beli}
                />
              </Modal.Body>

              <Modal.Footer>
                <ActionButton
                  type="submit"
                  variant="primary"
                  text="Ubah"
                  className="mt-2 px-4"
                  loading={isSubmitting}
                />
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </UpdateModal>
    );
  };

  // TABLE COMPONENT
  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <Th>Kode Item</Th>
              <Th>Nama Item</Th>
              <Th>Grup BUASO</Th>
              <Th>Satuan Pakai</Th>
              <Th>HPS Pakai</Th>
              <Th>Satuan Beli</Th>
              <Th>HPS Beli</Th>
              <Th>Tgl. HPS</Th>
              {!isGuest && <Th>Aksi</Th>}
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => {
              return (
                <Tr key={val.id_item_buaso}>
                  <TdFixed>{TableNumber(page, dataLength, index)}</TdFixed>
                  <Td className="text-center">
                    {val.kode_item ? val.kode_item : '-'}
                  </Td>
                  <Td>{val.nama_item ? val.nama_item : '-'}</Td>
                  <Td>{val.grup_buaso ? val.grup_buaso : '-'}</Td>
                  <Td>{val.satuan_pakai ? val.satuan_pakai : '-'}</Td>
                  <Td className="text-right text-nowrap">
                    <NumberFormat
                      value={parseFloat(val.hps_pakai ?? 0)}
                      displayType="text"
                      prefix="Rp"
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={2}
                    />
                  </Td>
                  <Td>{val.satuan_beli ? val.satuan_beli : '-'}</Td>
                  <Td className="text-right text-nowrap">
                    <NumberFormat
                      value={parseFloat(val.hps_beli ?? 0)}
                      displayType="text"
                      prefix="Rp"
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={2}
                    />
                  </Td>
                  <Td>
                    {val.tgl_hps
                      ? DateConvert(new Date(val.tgl_hps)).defaultDMY
                      : '-'}
                  </Td>
                  {!isGuest && (
                    <TdFixed>
                      <ActionButton
                        type="button"
                        variant="primary"
                        size="sm"
                        text="Update"
                        onClick={() => {
                          setUpdateData(val);
                          setIsUpdateForm(true);
                        }}
                        className="flex"
                      />
                    </TdFixed>
                  )}
                </Tr>
              );
            })}
          </TBody>
        </CRUDLayout.Table>
        <Pagination
          dataLength={dataLength}
          dataNumber={page * dataLength - dataLength + 1}
          dataPage={page * dataLength}
          dataCount={dataCount}
          onDataLengthChange={(e) => {
            setPage(1);
            setDataLength(e.target.value);
          }}
          currentPage={page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) => setPage(selected + 1)}
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row>
            <Col md="8">
              <InputSearch
                onChange={(e) => {
                  setTimeout(() => {
                    setKey(e.target.value);
                    setPage(1);
                  }, 1000);
                }}
                onSubmit={(e) => e.preventDefault()}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          <Select
            onChange={(e) =>
              setFilter({
                label: '',
                value: e.target.value,
              })
            }
          >
            <option value="" hidden>
              Pilih Buaso
            </option>
            {buaso.map((val) => (
              <option value={val.value}>{val.label}</option>
            ))}
          </Select>
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat Data" />
      ) : !Array.isArray(data) ? (
        <DataStatus text="Tidak dapat memuat data" />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      {/* MODAL */}
      <UbahModal />
    </CRUDLayout>
  );
};

export default HargaPerkiraanSendiri;
