import { Fragment, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IoCheckmark, IoCheckmarkDone } from 'react-icons/io5';

export const CustomTick = ({ isQtyPRFilled }) => {
  const halfFilled = {
    icon: <IoCheckmark />,
    bgColor: 'bg-warning',
    toolitpText: ' Qty. Terpenuhi Sebagian',
  };

  const fullFilled = {
    icon: <IoCheckmarkDone />,
    bgColor: 'bg-success',
    toolitpText: ' Qty. Sudah Terpenuhi Seluruhnya',
  };

  const itemConf = isQtyPRFilled ? fullFilled : halfFilled;

  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={<Tooltip>{itemConf.toolitpText}</Tooltip>}
    >
      <div
        className={`${itemConf.bgColor} d-flex justify-content-center align-items-center`}
        style={{
          margin: '0.5px 5px',
          borderRadius: '20%',
          minWidth: 15,
          maxHeight: 15,
        }}
      >
        {itemConf.icon}
      </div>
    </OverlayTrigger>
  );
};

export const ItemPRCollapse = ({ data = [] }) => {
  const [isMore, setMore] = useState(false);

  const splicedData = [...data].splice(0, 2);
  const results = isMore ? data : splicedData;

  return (
    <Fragment>
      <ul className="pl-3 mb-1" style={{ fontSize: '12px' }}>
        {results?.map((v, index) => {
          const namaItem = `${v.nama_item} ${parseFloat(v.qty_pr ?? 0)} ${
            v.satuan_beli ?? ''
          }`;

          const isQtyPRFilled = Boolean(
            parseFloat(v.qty_ordered) >= parseFloat(v.qty_pr)
          );

          return (
            <li key={index} index={index}>
              <div className="d-flex">
                <span>{namaItem}</span>
                <CustomTick isQtyPRFilled={isQtyPRFilled} />
              </div>
            </li>
          );
        })}
      </ul>

      {data.length > 2 && (
        <u
          className="text-primary"
          style={{
            cursor: 'pointer',
          }}
          onClick={() => setMore((prev) => !prev)}
        >
          {isMore ? 'Sembunyikan' : 'Selengkapnya...'}
        </u>
      )}
    </Fragment>
  );
};
