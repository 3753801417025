import { createContext, useEffect, useState } from 'react';
import axios from 'axios';
import { VendorApi, SeleksiVendorApi } from 'api';
import { useSessionStorage } from 'hooks';

export const DropdownSeleksiVendorContext = createContext();

export const DropdownSeleksiVendorProvider = ({ children }) => {
  const FETCH_INTERVAL_MINUTES = 2 * 60 * 1000; // 2 MINUTES
  const [triggerFetch, setTriggerFetch] = useState(true);
  const [loading, setLoading] = useState(false);
  const [lastFetch, setLastFetch] = useSessionStorage(
    'last_fetch_dropdown_seleksi_vendor',
    null
  );
  const [dropdown, setDropdown] = useSessionStorage('dropdown_seleksi_vendor', {
    vendor: [],
    item: [],
    jabatan: [],
    pengaju: [],
    proyek: [],
    keperluan: [
      { value: 'stok', label: 'Stok' },
      { value: 'produksi', label: 'Produksi' },
      { value: 'Pengadaan Aset', label: 'Pengadaan Aset' },
      {
        value: 'Pengadaan Barang Non Aset',
        label: 'Pengadaan Barang Non Aset',
      },
      {
        value: 'Perbaikan Dan Pemeliharaan Alat Dan Mesin',
        label: 'Perbaikan Dan Pemeliharaan Alat Dan Mesin',
      },
      {
        value: 'Perawatan Gedung Dan Kantor',
        label: 'Perawatan Gedung Dan Kantor',
      },
    ],
    status_approval: [
      { value: 'PEN', label: 'PENDING' },
      { value: 'VER', label: 'VERIFIED' },
      { value: 'REV', label: 'REVISE' },
      { value: 'APP', label: 'APPROVED' },
      { value: 'REJ', label: 'REJECT' },
    ],
  });

  const isRequireToRefetch = lastFetch
    ? Boolean(
        new Date()?.getTime() - new Date(lastFetch).getTime() >
          FETCH_INTERVAL_MINUTES
      )
    : true;

  const getDropdown = () => {
    setLoading(true);

    axios
      .all([
        VendorApi.dropdown(),
        SeleksiVendorApi.dropdownPreform({ tipe: 'jabatan' }),
        SeleksiVendorApi.dropdownPreform({ tipe: 'pengaju' }),
        SeleksiVendorApi.dropdownPreform({ tipe: 'proyek' }),
      ])

      .then(
        axios.spread((resVendor, resJabatan, resPengaju, resProyek) => {
          const vendor =
            resVendor.data.data.map((v) => ({
              ...v,
              value: v.id_vendor,
              label: v.nama_vendor,
            })) ?? [];

          const jabatan =
            resJabatan.data.data.map((j) => ({
              value: j.id_jabatan,
              label: j.nama_jabatan,
            })) ?? [];

          const pengaju =
            resPengaju.data.data.map((j) => ({
              value: j.id_karyawan,
              label: j.nama_karyawan,
            })) ?? [];

          const proyek =
            resProyek.data.data.map((p) => ({
              value: p.id_proyek,
              label: p.nama_proyek,
            })) ?? [];

          const dataDropdown = {
            ...dropdown,
            vendor,
            jabatan,
            pengaju,
            proyek,
          };

          setDropdown(dataDropdown);
        })
      );
  };

  const getDropdownItem = () => {
    SeleksiVendorApi.dropdown({ tipe: 'item' })
      .then((resItem) => {
        const item =
          resItem.data.data.map((v) => ({
            value: v.id_item_buaso,
            label: `${v.kode_item} - ${v.nama_item}`,
          })) ?? [];

        setDropdown((prev) => ({ ...prev, item }));
        setLastFetch(new Date());
      })
      .catch(() => alert('Gagal memuat dropdown Item'))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (triggerFetch && isRequireToRefetch) {
      getDropdown();
      getDropdownItem();
    }

    return () => {
      setDropdown({
        vendor: [],
      });

      setLoading(false);
    };
  }, [triggerFetch]);

  return (
    <DropdownSeleksiVendorContext.Provider
      value={{
        dropdown,
        setDropdown,
        loading,
        setLoading,
        setTriggerFetch,
      }}
    >
      {children}
    </DropdownSeleksiVendorContext.Provider>
  );
};

export const withDropdownSeleksiVendorProvider = (Component) => (props) => {
  return (
    <DropdownSeleksiVendorProvider>
      <Component {...props} />
    </DropdownSeleksiVendorProvider>
  );
};
