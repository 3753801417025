import { Card } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { InfoItemHorizontal } from 'components';

export const InformasiVendorSection = () => {
  const { values } = useFormikContext();

  return (
    <Card className="mt-4">
      <Card.Body>
        <InfoItemHorizontal label="Kode Vendor" text={values.kode_vendor} />
        <InfoItemHorizontal label="Nama Vendor" text={values.nama_vendor} />
        <InfoItemHorizontal
          label="Contact Person"
          text={values.contact_person}
        />
        <InfoItemHorizontal label="Alamat Vendor" text={values.alamat_vendor} />
      </Card.Body>
    </Card>
  );
};
