// React
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// API
import { KontrakPrelimApi } from 'api';

// Component
import {
  CRUDLayout,
  InputSearch,
  Alert,
  Pagination,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  DataStatus,
  BackButton,
} from 'components';
import { DateConvert, TableNumber, RupiahConvert } from 'utilities';
import {
  Button,
  OverlayTrigger,
  Tooltip,
  Row,
  Col,
  Modal,
} from 'react-bootstrap';

// Icon
import { IoEyeOutline, IoAddOutline } from 'react-icons/io5';

const ListPermintaanPrelim = ({ setNavbarTitle }) => {
  const title = 'Permintaan Prelim';

  const history = useHistory();

  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(false);

  // menampung value dari search form
  const [searchKey, setSearchKey] = useState('');

  // data
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [totalData, setTotalData] = useState('');
  const [dataModal, setDataModal] = useState({
    show: false,
    data: {},
  });
  // menampilkan alert
  const [showAlert, setShowAlert] = useState(false);
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
  });
  const [alertConfigSearch, setAlertConfigSearch] = useState({
    data: false,
    variant: 'primary',
    text: '',
  });

  // request data dari server
  const getData = () => {
    setIsLoading(true);

    // request data ke server
    KontrakPrelimApi.permintaan_page({
      page: page,
      per_page: dataLength,
      q: searchKey,
    })
      .then((res) => {
        setData(res.data.data);
        setTotalPage(res.data.total_page);
        setTotalData(res.data.data_count);
      })
      .catch(() => {
        setAlertConfig({
          variant: 'danger',
          text: 'Data gagal dimuat',
        });
        setShowAlert(true);
      })
      .finally(() => {
        if (searchKey !== '') {
          setAlertConfigSearch({
            data: true,
            variant: 'primary',
            text: `Hasil Pencarian : ${searchKey}`,
          });
        } else {
          setAlertConfigSearch({
            data: false,
            variant: 'primary',
            text: `Hasil Pencarian : ${searchKey}`,
          });
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    // set Judul di Navbar
    setNavbarTitle(`List ${title} Available`);

    getData();

    return () => {
      setIsLoading(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNavbarTitle, page, dataLength, searchKey]);

  const DataModal = () => {
    const [dataHistory, setDataHistory] = useState([]);
    const [loadingHistory, setLoadingHistory] = useState(false);

    useEffect(() => {
      setLoadingHistory(true);

      KontrakPrelimApi.page({
        id_permintaan_prelim: dataModal?.data?.id_permintaan_prelim,
        id_item_prelim: dataModal?.data?.id_item_prelim,
      })
        .then((res) => setDataHistory(res.data.data))
        .finally(() => setLoadingHistory(false));
    }, []);

    const InfoItem = ({ title, value }) => (
      <tr>
        <td width="120" className="align-top">
          {title}
        </td>
        <td className="pl-4 pr-2 align-top">:</td>
        <td className="align-top">{value}</td>
      </tr>
    );

    return (
      <Modal
        show={dataModal.show}
        onHide={() =>
          setDataModal({
            show: false,
            data: {},
          })
        }
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-success">
            <h6 className="mb-0">Detail {title}</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md>
              <table style={{ fontSize: '14px' }}>
                <tbody>
                  <InfoItem
                    title="Tgl. Permintaan Prelim"
                    value={
                      dataModal?.data?.tgl_permintaan_prelim
                        ? DateConvert(
                            new Date(dataModal.data.tgl_permintaan_prelim)
                          ).detail
                        : '-'
                    }
                  />
                  <InfoItem
                    title="No. Permintaan Prelim"
                    value={dataModal?.data?.no_permintaan_prelim ?? '-'}
                  />
                  <InfoItem
                    title="No. SPK"
                    value={dataModal?.data?.no_spk ?? '-'}
                  />
                </tbody>
              </table>
            </Col>

            <Col md>
              <table style={{ fontSize: '14px' }}>
                <tbody>
                  <InfoItem
                    title="Item Permintaan"
                    value={
                      dataModal?.data?.nama_item_prelim
                        ? `${
                            dataModal?.data?.kode_item_prelim &&
                            `(${dataModal?.data?.kode_item_prelim}) `
                          }${dataModal?.data?.nama_item_prelim}`
                        : '-'
                    }
                  />
                  <InfoItem
                    title="Qty. Permintaan"
                    value={
                      dataModal?.data?.qty_permintaan
                        ? `${parseFloat(dataModal?.data?.qty_permintaan)} ${
                            dataModal?.data?.nama_satuan_permintaan ?? ''
                          }`
                        : 0
                    }
                  />
                  <InfoItem
                    title="Qty. Kontrak"
                    value={
                      dataModal?.data?.qty_kontrak
                        ? `${parseInt(dataModal?.data?.qty_kontrak)} ${
                            dataModal?.data?.nama_satuan_kontrak ?? ''
                          }`
                        : 0
                    }
                  />
                </tbody>
              </table>
            </Col>
          </Row>

          <hr />
          {loadingHistory === true ? (
            <DataStatus loading={true} text="Memuat data..." />
          ) : (
            <CRUDLayout.Table>
              <THead>
                <Tr className="text-center">
                  <ThFixed>No</ThFixed>
                  <ThFixed>Tgl. Kontrak Prelim</ThFixed>
                  <ThFixed>No. Kontrak Prelim</ThFixed>
                  <Th>Item Prelim</Th>
                  <Th>Qty. Kontrak Prelim</Th>
                  <Th>Vendor</Th>
                  <ThFixed>Harga Satuan Kontrak</ThFixed>
                  <ThFixed>Jumlah</ThFixed>
                </Tr>
              </THead>
              <TBody>
                {dataHistory.length > 0 ? (
                  dataHistory.map((val, index) => (
                    <Tr key={index}>
                      <TdFixed>{index + 1}</TdFixed>
                      <TdFixed>
                        {val.tgl_kontrak_prelim
                          ? DateConvert(new Date(val.tgl_kontrak_prelim))
                              .defaultDMY
                          : '-'}
                      </TdFixed>
                      <TdFixed>{val.no_kontrak_prelim ?? '-'}</TdFixed>
                      <Td>
                        {val.nama_item_prelim
                          ? `${
                              val.kode_item_prelim &&
                              `(${val.kode_item_prelim}) `
                            }${val.nama_item_prelim}`
                          : '-'}
                      </Td>
                      <Td>
                        {val.qty_kontrak
                          ? `${parseFloat(val.qty_kontrak)} ${
                              val.nama_satuan_permintaan ?? ''
                            }`
                          : 0}
                      </Td>
                      <Td>{val.nama_vendor ?? '-'}</Td>
                      {console.log(val)}
                      <TdFixed>
                        {val.harga_satuan
                          ? RupiahConvert(String(parseInt(val.harga_satuan)))
                              .detail
                          : 'Rp0'}
                      </TdFixed>
                      <TdFixed>
                        {Boolean(val.harga_satuan && val.qty_kontrak)
                          ? RupiahConvert(
                              String(
                                parseInt(
                                  parseFloat(val.harga_satuan) *
                                    parseFloat(val.qty_kontrak)
                                )
                              )
                            ).detail
                          : 'Rp0'}
                      </TdFixed>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <TdFixed colSpan={8}>
                      <DataStatus text="Tidak ada data" />
                    </TdFixed>
                  </Tr>
                )}
              </TBody>
            </CRUDLayout.Table>
          )}
        </Modal.Body>
      </Modal>
    );
  };

  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Tgl. Permintaan Prelim</ThFixed>
              <ThFixed>No. Permintaan Prelim</ThFixed>
              <ThFixed>No. SPK</ThFixed>
              <Th>Item Permintaan</Th>
              <Th>Qty. Permintaan Prelim</Th>
              <Th>Qty. Telah Di Kontrak</Th>
              <Th>Durasi Prelim</Th>
              <ThFixed>Aksi</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => {
              const kontrak = val.qty_kontrak ? parseInt(val.qty_kontrak) : 0;
              const permintaan = val.qty_permintaan
                ? parseInt(val.qty_permintaan)
                : 0;

              return (
                <Tr key={index}>
                  <TdFixed>{TableNumber(page, dataLength, index)}</TdFixed>
                  <TdFixed>
                    {val.tgl_permintaan_prelim
                      ? DateConvert(new Date(val.tgl_permintaan_prelim))
                          .defaultDMY
                      : '-'}
                  </TdFixed>
                  <TdFixed>{val.no_permintaan_prelim ?? '-'}</TdFixed>
                  <TdFixed>{val.no_spk ?? '-'}</TdFixed>
                  <Td>
                    {val.nama_item_prelim
                      ? `${
                          val.kode_item_prelim && `(${val.kode_item_prelim}) `
                        }${val.nama_item_prelim}`
                      : '-'}
                  </Td>
                  <Td>
                    {val.qty_permintaan
                      ? `${parseFloat(val.qty_permintaan)} ${
                          val.nama_satuan_permintaan ?? ''
                        }`
                      : 0}
                  </Td>
                  <Td>
                    {val.qty_kontrak
                      ? `${parseInt(val.qty_kontrak)} ${
                          val.nama_satuan_permintaan ?? ''
                        }`
                      : 0}
                  </Td>
                  <Td>
                    {val.qty_durasi ? `${parseInt(val.qty_durasi)} Hari` : 0}
                  </Td>
                  <TdFixed>
                    {kontrak >= permintaan ? (
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Detail</Tooltip>}
                      >
                        <Button
                          variant="primary"
                          size="sm"
                          className="m-1"
                          onClick={() =>
                            setDataModal({
                              show: true,
                              data: val,
                            })
                          }
                        >
                          <IoEyeOutline size={18} />
                        </Button>
                      </OverlayTrigger>
                    ) : (
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Buat Kontrak</Tooltip>}
                      >
                        <Button
                          variant="success"
                          size="sm"
                          className="m-1"
                          onClick={() =>
                            history.push(
                              `/transaksi/kontrak-prelim/tambah/${val.id_permintaan_prelim}/${val.id_item_prelim}`
                            )
                          }
                        >
                          <IoAddOutline size={18} />
                        </Button>
                      </OverlayTrigger>
                    )}
                  </TdFixed>
                </Tr>
              );
            })}
          </TBody>
        </CRUDLayout.Table>
        <Pagination
          dataLength={dataLength}
          dataNumber={page * dataLength - dataLength + 1}
          dataCount={totalData}
          currentPage={page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) => setPage(selected + 1)}
          onDataLengthChange={(e) => {
            setPage(1);
            setDataLength(e.target.value);
          }}
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="mb-3">
            <Col md="8">
              <InputSearch
                onChange={(e) => {
                  setTimeout(() => {
                    setSearchKey(e.target.value);
                    setPage(1);
                  }, 1000);
                }}
                onSubmit={(e) => e.preventDefault()}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          <BackButton onClick={() => history.goBack()} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      {/* ALert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />
      <Alert
        show={alertConfigSearch.data}
        showCloseButton={true}
        variant={alertConfigSearch.variant}
        text={alertConfigSearch.text}
        onClose={() => setShowAlert(false)}
      />

      {isLoading === true ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : data.length > 0 ? (
        <>
          <Table />
          {dataModal.show && <DataModal />}
        </>
      ) : (
        <DataStatus text="Tidak ada data" />
      )}
    </CRUDLayout>
  );
};

export default ListPermintaanPrelim;
