import { useContext, useEffect, useState } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import {
  IoAdd,
  IoCheckmarkOutline,
  IoCloseOutline,
  IoPencilOutline,
  IoTrashOutline,
} from 'react-icons/io5';
import { useFormik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import {
  ActionButton,
  DeleteModal,
  Input,
  SelectSearch,
  Table,
  Td,
  Th,
  ThFixed,
} from 'components';
import { RupiahConvert } from 'utilities';
import { calcSubTotal, calcGrandTotal } from '../Helpers';
import { DropdownTransferUpahContext } from '../Context';

const decimalConvert = (value) => {
  const newValue = value.toString().replace(/[^0-9\.]/g, '');
  const convert = newValue.substring(0, 10);

  return convert;
};

const TableRow = ({
  index,
  data,
  action = 'view',
  setModalConfig,
  readOnly,
  print,
}) => {
  const [currentAction, setCurrentAction] = useState(action);
  const { setValues: setValuesKontrak } = useFormikContext();
  const { dropdownSatuan } = useContext(DropdownTransferUpahContext);

  const formInitialValues = {
    uraian: data?.uraian ?? '',
    qty: data?.qty ?? 0,
    id_satuan: data?.id_satuan ?? '',
    nama_satuan: data?.nama_satuan ?? '',
    unit_price: data?.unit_price ?? 0,
    konstanta: data?.konstanta ?? 1,
  };

  const formValidationSchema = Yup.object().shape({
    uraian: Yup.string().required(),
    qty: Yup.string().required(),
    id_satuan: Yup.string().required(),
    unit_price: Yup.string().required(),
    konstanta: Yup.string().required(),
  });

  const formSubmitHandler = (values, { resetForm }) => {
    if (currentAction === 'update') {
      setValuesKontrak((prev) => ({
        ...prev,
        detail: prev.detail.toSpliced(index, 1, values),
      }));

      setCurrentAction('view');
    } else {
      setValuesKontrak((prev) => ({
        ...prev,
        detail: prev.detail.toSpliced(prev.detail.length, 0, values),
      }));

      resetForm();
    }
  };

  const { values, errors, touched, setValues, setFieldValue, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: formInitialValues,
      validationSchema: formValidationSchema,
      onSubmit: formSubmitHandler,
    });

  if (currentAction === 'view') {
    return (
      <>
        <Td>{index + 1}</Td>
        <Td>{data.uraian}</Td>
        <Td textRight>{parseFloat(data.qty ?? 0)}</Td>
        <Td>{data.nama_satuan}</Td>
        <Td textRight>
          {RupiahConvert(String(parseFloat(data?.unit_price ?? 0))).detail}
        </Td>
        {!print && <Td textRight>{parseFloat(data.konstanta ?? 0)}</Td>}
        <Td textRight>
          {
            RupiahConvert(
              String(
                calcSubTotal({
                  qty: data?.qty,
                  unit_price: data?.unit_price,
                  konstanta: data?.konstanta,
                })
              )
            ).detail
          }
        </Td>

        {!readOnly && (
          <Td>
            <ButtonGroup size="sm">
              <ActionButton
                variant="success"
                onClick={() => setCurrentAction('update')}
              >
                <IoPencilOutline />
              </ActionButton>
              <ActionButton
                variant="danger"
                onClick={() =>
                  setModalConfig({
                    show: true,
                    index: index,
                  })
                }
              >
                <IoTrashOutline />
              </ActionButton>
            </ButtonGroup>
          </Td>
        )}
      </>
    );
  }

  return (
    <>
      {/* No */}
      <Td>{action === 'create' ? '' : index + 1}</Td>

      {/* Item Pekerjaan */}
      <Td className="p-0">
        <Input
          noPadding
          value={values.uraian}
          onChange={(e) => setFieldValue('uraian', e?.target?.value ?? '')}
          error={!!errors.uraian && !!touched.uraian}
        />
      </Td>

      {/* Volume */}
      <Td className="p-0">
        <Input
          noPadding
          className="text-right"
          value={values?.qty ?? 0}
          onChange={(e) =>
            setFieldValue('qty', decimalConvert(e?.target?.value ?? '0'))
          }
          error={!!errors.qty && !!touched.qty}
        />
      </Td>

      {/* Satuan */}
      <Td className="p-0">
        <SelectSearch
          key={values.id_satuan}
          placeholder="Pilih satuan"
          loading={dropdownSatuan.isLoading}
          option={dropdownSatuan?.data ?? []}
          menuShouldBlockScroll={true}
          menuPosition="fixed"
          defaultValue={
            values.id_satuan
              ? dropdownSatuan?.data?.find(
                  (val) => val.value === values.id_satuan
                )
              : ''
          }
          onChange={(val) =>
            setValues({
              ...values,
              id_satuan: val.value,
              nama_satuan: val.label,
            })
          }
          error={Boolean(errors.id_satuan && touched.id_satuan)}
        />
      </Td>

      {/* Harga Satuan */}
      <Td className="p-0">
        <Input
          noPadding
          className="text-right"
          value={
            RupiahConvert(parseFloat(values?.unit_price ?? 0).toString()).detail
          }
          onChange={(e) => {
            const value = Boolean(RupiahConvert(e.target.value).default)
              ? RupiahConvert(e.target.value).default
              : '0';

            setFieldValue('unit_price', value);
          }}
          error={!!errors.unit_price && !!touched.unit_price}
        />
      </Td>

      {/* Konstanta */}
      <Td className="p-0">
        <Input
          noPadding
          className="text-right"
          value={values?.konstanta ?? 0}
          onChange={(e) =>
            setFieldValue('konstanta', decimalConvert(e?.target?.value ?? '0'))
          }
          error={!!errors.konstanta && !!touched.konstanta}
        />
      </Td>

      {/* Jumlah */}
      <Td textRight>
        {
          RupiahConvert(
            calcSubTotal({
              qty: values.qty,
              unit_price: values.unit_price,
              konstanta: values.konstanta,
            }).toString()
          ).detail
        }
      </Td>

      {/* Aksi */}
      {!readOnly && (
        <Td>
          {currentAction === 'create' ? (
            <ActionButton className="col" size="sm" onClick={handleSubmit}>
              <IoAdd />
            </ActionButton>
          ) : (
            <ButtonGroup size="sm">
              <ActionButton variant="outline-success" onClick={handleSubmit}>
                <IoCheckmarkOutline />
              </ActionButton>
              <ActionButton
                variant="outline-danger"
                onClick={() => setCurrentAction('view')}
              >
                <IoCloseOutline />
              </ActionButton>
            </ButtonGroup>
          )}
        </Td>
      )}
    </>
  );
};

export const TableItemKontrak = ({ readOnly, print }) => {
  const { values, setFieldValue, setValues } = useFormikContext();
  const [dropdownLoading, setDropdownLoading] = useState(true);
  const [totalTableFoot, setTotalTableFoot] = useState({
    totalPPN: 0,
    totalSetelahPPN: 0,
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    index: {},
  });

  const sumTableFoot = (data) => {
    const total = calcGrandTotal(values?.detail ?? []);
    const totalPPN = data ? parseFloat(data / 100) * total : 0;
    const totalSetelahPPN = total + totalPPN;

    setTotalTableFoot({
      ...totalTableFoot,
      totalPPN: parseInt(totalPPN),
      totalSetelahPPN: parseInt(totalSetelahPPN),
    });
  };

  useEffect(() => {
    if (readOnly) {
      sumTableFoot(values?.ppn ?? 0);
    }

    return () => {
      setDropdownLoading(true);
    };
  }, []);

  return (
    <>
      <Table>
        <thead>
          <tr>
            <ThFixed>No</ThFixed>
            <Th style={{ minWidth: 200 }}>Item Pekerjaan</Th>
            <Th style={{ width: 80 }}>Volume</Th>
            <Th style={{ minWidth: 100 }}>Satuan</Th>
            <Th style={{ width: 150 }}>Harga Satuan</Th>
            {!print && <Th style={{ width: 80 }}>Konstanta</Th>}
            <Th style={{ width: 150 }}>Jumlah</Th>
            {!readOnly && <ThFixed>Aksi</ThFixed>}
          </tr>
        </thead>
        <tbody>
          {!readOnly && (
            <TableRow
              action="create"
              modalConfig={modalConfig}
              setModalConfig={setModalConfig}
              dropdownLoading={dropdownLoading}
            />
          )}

          {values?.detail?.map((val, index) => (
            <tr key={index}>
              <TableRow
                index={index}
                data={val}
                modalConfig={modalConfig}
                setModalConfig={setModalConfig}
                dropdownLoading={dropdownLoading}
                readOnly={readOnly}
                print={print}
              />
            </tr>
          ))}
        </tbody>

        <tfoot>
          <tr>
            <Td textRight colSpan={print ? 5 : 6}>
              <b>TOTAL HARGA</b>
            </Td>

            <Td textRight>
              <b>
                {
                  RupiahConvert(calcGrandTotal(values?.detail ?? []).toString())
                    .detail
                }
              </b>
            </Td>
            {!readOnly && <Td></Td>}
          </tr>

          {/* total ppn */}
          <tr>
            <Td textRight colSpan={print ? 5 : 6}>
              {readOnly ? (
                <b className="mt-1">PPN ({parseFloat(values?.ppn)} %)</b>
              ) : (
                <div className="d-flex justify-content-end">
                  <b className="mt-1">PPN (%)</b>
                  <input
                    type="text"
                    value={values.ppn ?? 0}
                    className="form-control form-control-sm ml-3"
                    style={{ width: 50 }}
                    onChange={(e) => {
                      const val = decimalConvert(e.target.value)
                        ? parseInt(decimalConvert(e.target.value))
                        : 0;

                      if (val >= 100) {
                        setFieldValue('ppn', 100);
                        sumTableFoot(100);
                      } else {
                        setFieldValue('ppn', val);
                        sumTableFoot(val);
                      }
                    }}
                  />
                </div>
              )}
            </Td>
            <Td textRight>
              <b>
                {RupiahConvert(String(totalTableFoot.totalPPN ?? 0)).detail}
              </b>
            </Td>
            {!readOnly && <Td></Td>}
          </tr>

          {/* total setelah ppn */}
          <tr>
            <Td textRight colSpan={print ? 5 : 6}>
              <b>TOTAL HARGA SETELAH PPN</b>
            </Td>
            <Td textRight>
              <b>
                {
                  RupiahConvert(String(totalTableFoot.totalSetelahPPN ?? 0))
                    .detail
                }
              </b>
            </Td>
            {!readOnly && <Td></Td>}
          </tr>

          {/* total dp */}
          <tr>
            <Td textRight colSpan={print ? 5 : 6}>
              {readOnly ? (
                <b className="mt-1">DP</b>
              ) : (
                <div className="d-flex justify-content-end">
                  <b className="mt-1">DP (%)</b>
                  <input
                    className="form-control form-control-sm ml-3"
                    style={{ width: 50 }}
                    value={values.dp ?? 0}
                    onChange={(e) => {
                      const val = decimalConvert(e.target.value)
                        ? parseInt(decimalConvert(e.target.value))
                        : 0;

                      if (val >= 100) {
                        setFieldValue('dp', 100);
                      } else {
                        setFieldValue('dp', val);
                      }
                    }}
                  />
                </div>
              )}
            </Td>

            <Td textRight>{readOnly && <b>{parseFloat(values?.dp)}%</b>}</Td>

            {!readOnly && <Td></Td>}
          </tr>

          {/* total retensi */}
          <tr>
            <Td textRight colSpan={print ? 5 : 6}>
              {readOnly ? (
                <b className="mt-1">RETENSI</b>
              ) : (
                <div className="d-flex justify-content-end">
                  <b className="mt-1">RETENSI (%)</b>
                  <input
                    type="text"
                    value={values?.retensi ?? 0}
                    className="form-control form-control-sm ml-3"
                    style={{ width: 50 }}
                    onChange={(e) => {
                      const val = decimalConvert(e.target.value)
                        ? parseInt(decimalConvert(e.target.value))
                        : 0;

                      if (val >= 100) {
                        setFieldValue('retensi', 100);
                      } else {
                        setFieldValue('retensi', val);
                      }
                    }}
                  />
                </div>
              )}
            </Td>

            <Td textRight>
              {readOnly && <b>{parseFloat(values?.retensi)}%</b>}
            </Td>

            {!readOnly && <Td></Td>}
          </tr>
        </tfoot>
      </Table>

      <DeleteModal
        show={modalConfig.show}
        onHide={() => setModalConfig({ show: false, index: {} })}
        onConfirm={() => {
          setValues((prev) => ({
            ...prev,
            detail: prev.detail.toSpliced(modalConfig.index, 1),
          }));

          setModalConfig({ show: false, index: null });
        }}
      />
    </>
  );
};
