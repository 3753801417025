import { useContext, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { DatePicker, Input, TextArea } from 'components';
import { useFormikContext } from 'formik';
import { PurchaseOrderApi } from 'api';
import { DropdownPurchaseOrderContext } from '../Contexts';
import { SelectSearchCreateable } from './SelectSearchCreateable';

export const FormSection = ({ type = 'CREATE' }) => {
  const TODAY = new Date();
  const { values, setFieldValue, touched, errors, handleChange } =
    useFormikContext();
  const { dropdownPembayaran, setDropdownPembayaran } = useContext(
    DropdownPurchaseOrderContext
  );
  const [loadingNomor, setLoadingNomor] = useState(false);

  const getNomor = (tanggal = TODAY) => {
    setLoadingNomor(true);
    PurchaseOrderApi.getNomor({ tanggal })
      .then((res) => {
        setFieldValue('no_purchase_order', res.data.data);
        setFieldValue('tgl_purchase_order', tanggal);
      })
      .finally(() => setLoadingNomor(false));
  };

  useEffect(() => {
    if (type === 'CREATE') {
      getNomor();
    }
  }, []);

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col>
            <Row>
              <Col>
                <DatePicker
                  label="Tgl. Purchase Order"
                  className="mt-1"
                  selected={
                    values.tgl_purchase_order
                      ? new Date(values.tgl_purchase_order)
                      : ''
                  }
                  onChange={(e) => {
                    const value = e.toISOString().slice(0, 10);
                    if (type === 'CREATE') {
                      getNomor(value);
                      return;
                    }
                    setFieldValue('tgl_purchase_order', value);
                  }}
                  error={
                    errors.tgl_purchase_order &&
                    touched.tgl_purchase_order &&
                    true
                  }
                  errorText={errors.tgl_purchase_order}
                />
              </Col>

              <Col>
                <Input
                  readOnly
                  type="text"
                  label="No. Purchase Order"
                  placeholder="Pilih tanggal untuk menentukan nomor"
                  value={
                    loadingNomor
                      ? 'Memuat nomor . . . '
                      : values.no_purchase_order
                  }
                  error={
                    errors.no_purchase_order &&
                    touched.no_purchase_order &&
                    true
                  }
                  errorText={errors.no_purchase_order}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <SelectSearchCreateable
                  key={dropdownPembayaran.length}
                  label="Metode Pembayaran"
                  placeholder="Ketik untuk metode lainnya..."
                  className="mt-1"
                  onChange={(val) => setFieldValue('pembayaran', val.value)}
                  option={dropdownPembayaran?.concat({
                    label: 'Ketikkan metode pembayaran lainnya...',
                    value: '',
                    isDisabled: true,
                  })}
                  defaultValue={dropdownPembayaran.find(
                    (d) => d.value === values.pembayaran
                  )}
                  onCreateOption={(val) => {
                    setDropdownPembayaran((prev) =>
                      prev.toSpliced(0, 0, { value: val, label: val })
                    );
                    setFieldValue('pembayaran', val.value);
                  }}
                  formatCreateLabel={(val) => `Tambah '${val}'`}
                  error={errors.pembayaran && touched.pembayaran}
                  errorText={errors.pembayaran}
                />
              </Col>
              <Col>
                <DatePicker
                  label="Tgl. Jatuh Tempo"
                  className="mt-1"
                  selected={
                    values.tgl_jatuh_tempo
                      ? new Date(values.tgl_jatuh_tempo)
                      : ''
                  }
                  onChange={(e) => {
                    const value = e.toISOString().slice(0, 10);
                    setFieldValue('tgl_jatuh_tempo', value);
                  }}
                  error={errors.tgl_jatuh_tempo && touched.tgl_jatuh_tempo}
                  errorText={errors.tgl_jatuh_tempo}
                />
              </Col>
            </Row>

            <TextArea
              label="Alamat Tujuan Pengiriman"
              name="alamat_tujuan_pengiriman"
              placeholder="Masukan alamat tujuan pengiriman"
              rows={3}
              value={values.alamat_tujuan_pengiriman}
              onChange={handleChange}
              error={
                errors.alamat_tujuan_pengiriman &&
                touched.alamat_tujuan_pengiriman &&
                true
              }
              errorText={errors.alamat_tujuan_pengiriman}
            />
          </Col>
          <Col>
            <TextArea
              label="Keterangan Purchase Order"
              name="keterangan"
              placeholder="Masukan keterangan"
              rows={9}
              value={values.keterangan}
              onChange={handleChange}
              error={errors.keterangan && touched.keterangan && true}
              errorText={errors.keterangan}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
