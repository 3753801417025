import Services from '../../../services';

class ItemUpahApi {
  get(data) {
    return Services.get('/vendor_item_upah?id_vendor=' + data);
  }

  single(id) {
    return Services.get('/vendor_item_upah/single?id_item_upah_vendor=' + id);
  }

  getItem() {
    return Services.get('/vendor_item_upah/item_upah');
  }

  create(value) {
    return Services.post('/vendor_item_upah', value);
  }

  delete(value) {
    return Services.post('/vendor_item_upah/delete', value);
  }
  export() {
    return Services.get('/vendor_item_upah/xlsx');
  }
}

export default new ItemUpahApi();
