import { DatePicker, FilterModal, SelectSearch } from 'components';
import { useFormik } from 'formik';
import { useContext } from 'react';
import { DateConvert } from 'utilities';
import { DropdownSeleksiVendorContext } from '../Context';

export const ModalFilterPRSeleksiVendor = ({
  show,
  setShow,
  filter,
  setFilter,
}) => {
  const { dropdown } = useContext(DropdownSeleksiVendorContext);

  const initialValues = {
    tgl_pemakaian_start: filter?.tgl_pemakaian_start,
    tgl_pemakaian_end: filter?.tgl_pemakaian_end,
    tgl_purchase_request_start: filter?.tgl_purchase_request_start,
    tgl_purchase_request_end: filter?.tgl_purchase_request_end,
    id_karyawan_pengaju: filter?.id_karyawan_pengaju,
    id_jabatan_pengaju: filter?.id_jabatan_pengaju,
    id_proyek: filter?.id_proyek,
    id_item_buaso: filter?.id_item_buaso,
    keperluan: filter?.keperluan,
  };

  const onSubmit = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setFilter((prev) => ({
        ...prev,
        ...values,
        active: true,
        page: '1',
      }));

      setShow(false);
      return;
    }

    setFilter({ ...values, filter: false });
    setShow(false);
  };

  const onResetButtonClick = () => {
    setFilter((prev) => ({
      ...prev,
      page: '1',
      active: false,
      tgl_pemakaian_start: undefined,
      tgl_pemakaian_end: undefined,
      tgl_purchase_request_start: undefined,
      tgl_purchase_request_end: undefined,
      id_karyawan_pengaju: undefined,
      id_jabatan_pengaju: undefined,
      id_item_buaso: undefined,
      id_proyek: undefined,
      keperluan: undefined,
    }));
    setShow(false);
  };

  const formik = useFormik({ initialValues, onSubmit });

  const { values, setFieldValue, handleSubmit } = formik;

  return (
    <FilterModal
      show={show}
      setShow={setShow}
      onResetButtonClick={() => onResetButtonClick()}
      onFilterButtonClick={handleSubmit}
    >
      <DatePicker
        selectsRange
        monthsShown={2}
        label="Tgl. Purchase Request "
        placeholderText="Pilih Tgl. Purchase Request"
        startDate={
          values.tgl_purchase_request_start
            ? new Date(values.tgl_purchase_request_start)
            : ''
        }
        endDate={
          values.tgl_purchase_request_end
            ? new Date(values.tgl_purchase_request_end)
            : ''
        }
        onChange={(dates) => {
          const [start, end] = dates;
          const startDate = start ? DateConvert(start).default : null;
          const endDate = end ? DateConvert(end).default : null;

          setFieldValue('tgl_purchase_request_start', startDate);
          setFieldValue('tgl_purchase_request_end', endDate);
        }}
      />

      <SelectSearch
        label="Nama Pengaju Purchase Request"
        placeholder="Pilih Nama Pengaju Purchase Request"
        option={[{ value: undefined, label: 'Semua' }].concat(
          dropdown?.pengaju
        )}
        defaultValue={
          values.id_karyawan_pengaju
            ? dropdown?.pengaju?.find(
                (v) => values.id_karyawan_pengaju === v.value
              )
            : null
        }
        onChange={({ value }) => setFieldValue('id_karyawan_pengaju', value)}
      />

      <SelectSearch
        label="Pilih Jabatan Pengaju Purchase Request"
        placeholder="Pilih Jabatan Pengaju Purchase Request"
        option={[{ value: undefined, label: 'Semua' }].concat(
          dropdown?.jabatan
        )}
        defaultValue={
          values.id_item_buaso
            ? dropdown?.item?.find((v) => values.id_item_buaso === v.value)
            : null
        }
        onChange={({ value }) => setFieldValue('id_item_buaso', value)}
      />

      <SelectSearch
        label="Item Purchase Request"
        placeholder="Pilih Item Purchase Request"
        option={[{ value: undefined, label: 'Semua' }].concat(dropdown?.item)}
        defaultValue={
          values.id_jabatan_pengaju
            ? dropdown?.pengaju?.find(
                (v) => values.id_jabatan_pengaju === v.value
              )
            : null
        }
        onChange={({ value }) => setFieldValue('id_jabatan_pengaju', value)}
      />

      <SelectSearch
        label="Proyek"
        placeholder="Pilih Proyek"
        option={[{ value: undefined, label: 'Semua' }].concat(dropdown?.proyek)}
        defaultValue={
          values.id_proyek
            ? dropdown?.proyek?.find((v) => values.id_proyek === v.value)
            : null
        }
        onChange={({ value }) => setFieldValue('id_proyek', value)}
      />

      <SelectSearch
        label="Keperluan"
        placeholder="Pilih Keperluan"
        onChange={({ value }) => setFieldValue('keperluan', value)}
        option={[{ value: undefined, label: 'Semua' }].concat(
          dropdown?.keperluan
        )}
        defaultValue={
          values.keperluan
            ? dropdown?.keperluan?.find((s) => values.keperluan === s.value)
            : null
        }
      />

      <DatePicker
        selectsRange
        monthsShown={2}
        label="Tgl. Pemakaian"
        placeholderText="Pilih Tgl. Pemakaian"
        startDate={
          values.tgl_pemakaian_start ? new Date(values.tgl_pemakaian_start) : ''
        }
        endDate={
          values.tgl_pemakaian_end ? new Date(values.tgl_pemakaian_end) : ''
        }
        onChange={(dates) => {
          const [start, end] = dates;
          const startDate = start ? DateConvert(start).default : null;
          const endDate = end ? DateConvert(end).default : null;

          setFieldValue('tgl_pemakaian_start', startDate);
          setFieldValue('tgl_pemakaian_end', endDate);
        }}
      />
    </FilterModal>
  );
};
