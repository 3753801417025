/*eslint-disable*/

import {
  IoSpeedometerOutline,
  IoServerOutline,
  IoDocumentOutline,
  IoCashOutline,
} from 'react-icons/io5';
import Dashboard from '../pages/Dashboard';

// MASTER PAGE
import {
  Vendor,
  TambahVendor,
  UbahVendor,
  DetailVendor,
  PrakualifikasiVendor,
  UbahPrakualifikasiVendor,
  TambahPrakualifikasiVendor,
  CalonVendor,
  KualifikasiVendor,
  Kualifikasi,
  DetailKualifikasiVendor,
  HargaPerkiraanSendiri,
  ItemUpah,
  ItemSubcont,
  KelompokUpahSubcont,
  HargaSatuanPrelim,
  JenisKayu,
  PartKayu,
  HargaKubikasiKayu,
  ItemPrelimList,
} from '../pages/Master';

// TRANSAKSI PAGE
import {
  SeleksiVendorList,
  PurchaseRequestSeleksiVendorList,
  SeleksiVendorTambah,

  // purchase order
  PurchaseOrderList,
  PreformPurchaseOrderList,
  DetailPurchaseOrder,
  TambahPurchaseOrder,
  UbahPurchaseOrder,

  // Permintaan Upah Produksi
  PermintaanUpahProduksi,
  DataPermintaanUpahProduksi,

  // Permintaan Subkon Produksi
  TransferSubkonProduksiList,
  TransferSubkonProduksiRegister,
  TransferSubkonProduksiKontrakDetail,
  TambahTransferSubkonProduksi,
  TransferSubkonProduksiPreformList,
  DetailTransferSubkonProduksi,

  // Kontrak Prelim
  ListKontrakPrelim,
  ListPermintaanPrelim,
  TambahKontrakPrelim,
  DetailSPKSection,

  // Transfer Upah Produksi
  TransferUpahProduksiList,
  TambahTransferUpahProduksi,
  TransferUpahProduksiPreformList,
  DetailTransferUpahProduksi,
} from '../pages/Transaksi';

import { LaporanPurchaseOrder } from 'pages/Laporan';

import Logo from '../assets/image/LogoSadhana.png';

export default {
  LOGO: Logo,
  MODUL: 'Procurement',

  /* 
    MENU REQUIREMENTS
      > TEXT  = REQUIRED,
      > LINK  = REQUIRED,
      > EXACT = OPTIONAL (TRUE/FALSE),
      > TYPE  = REQUIRED (MENU/DROPDOWN)
      > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
      > LOGO  = OPTIONAL 
  */

  MENU: [
    {
      text: 'Dashboard',
      link: '/',
      type: 'menu',
      exact: true,
      icon: <IoSpeedometerOutline />,
      hak: ['SUPA', 'PRO'],
    },
    {
      text: 'Master Data',
      type: 'dropdown',
      icon: <IoServerOutline />,
      hak: ['SUPA', 'PRO'],
      menu: [
        {
          text: 'Vendor',
          link: '/master/vendor',
          hak: ['SUPA', 'PRO_MAS_VEN'],
        },
        {
          text: 'Prakualifikasi Vendor',
          link: '/master/prakualifikasi-vendor',
          hak: ['SUPA', 'PRO_MAS_PKV'],
        },
        {
          text: 'Kualifikasi Vendor',
          link: '/master/kualifikasi-vendor',
          hak: ['SUPA', 'PRO_MAS_KV'],
        },
        {
          text: 'Harga Perkiraan Sendiri',
          link: '/master/harga-perkiraan-sendiri',
          hak: ['SUPA', 'PRO_MAS_HPS'],
        },
        {
          text: 'Jenis Kayu',
          link: '/master/jenis-kayu',
          hak: ['SUPA', 'PRO_MAS_JKY'],
        },
        {
          text: 'Part Kayu',
          link: '/master/part-kayu',
          hak: ['SUPA', 'PRO_MAS_PKY'],
        },
        {
          text: 'Harga Kubikasi Kayu',
          link: '/master/harga-kubikasi-kayu',
          hak: ['SUPA', 'PRO_MAS_HKY'],
        },
        {
          text: 'Item Upah',
          link: '/master/item-upah',
          hak: ['SUPA', 'PRO_REG_UPH'],
        },
        {
          text: 'Item Subcont',
          link: '/master/item-subcont',
          hak: ['SUPA', 'PRO_REG_SBC'],
        },
        {
          text: 'Item Prelim',
          link: '/master/item-prelim',
          hak: ['SUPA', 'PRO_MAS_IPRE'],
        },
        {
          text: 'Kelompok Upah & Subcont',
          link: '/master/kelompok-upah-subcont',
          hak: ['SUPA', 'PRO_MAS_KUS'],
        },
        // {
        //   text: "Harga Satuan Prelim",
        //   link: "/master/harga-satuan-prelim",
        //   hak: ["SUPA", "PRO_MAS_HSP"],
        // },
      ],
    },
    {
      text: 'Transaksi',
      type: 'dropdown',
      icon: <IoCashOutline />,
      hak: [
        'SUPA',
        'PRO',
        'PRO_TRN_SV',
        'PRO_TRN_PO',
        'PRO_TRN_PUP',
        'PRO_TRN_PSP',
      ],
      menu: [
        {
          text: 'Seleksi Vendor',
          link: '/transaksi/seleksi-vendor',
          hak: ['SUPA', 'PRO_TRN_SV'],
        },
        {
          text: 'Purchase Order',
          link: '/transaksi/purchase-order',
          hak: ['SUPA', 'PRO_TRN_PO'],
        },

        {
          text: 'Transfer Upah Produksi',
          link: '/transaksi/transfer-upah-produksi',
          hak: ['SUPA', 'PRO_TRN_PUP'],
        },
        {
          text: 'Transfer Subcont Produksi',
          link: '/transaksi/transfer-subkon-produksi',
          hak: ['SUPA', 'PRO_TRN_PSP'],
        },
        {
          text: 'Kontrak Prelim',
          link: '/transaksi/kontrak-prelim',
          hak: ['SUPA', 'PRO_TRN_KPR'],
        },
      ],
    },
    {
      text: 'Laporan',
      type: 'menu',
      icon: <IoDocumentOutline />,
      hak: ['SUPA', 'PRO'],
      menu: [
        {
          text: 'Purchase Order',
          link: '/laporan/purchase-order',
          hak: ['SUPA', 'PRO_RPT_PO'],
        },
      ],
    },
  ],

  ROUTES: [
    {
      exact: true,
      path: '/',
      page: Dashboard,
      hak: ['SUPA', 'PRO'],
    },

    //MASTER
    // VENDOR
    {
      exact: true,
      path: '/master/vendor',
      page: Vendor,
      hak: ['SUPA', 'PRO'],
    },
    {
      exact: true,
      path: '/master/vendor/detail/:id',
      page: DetailVendor,
      hak: ['SUPA', 'PRO'],
    },
    {
      exact: true,
      path: '/master/vendor/tambah',
      page: TambahVendor,
      hak: ['SUPA', 'PRO'],
    },
    {
      exact: true,
      path: '/master/vendor/:id',
      page: UbahVendor,
      hak: ['SUPA', 'PRO'],
    },

    // PRAKUALIFIKASI VENDOR
    {
      exact: true,
      path: '/master/prakualifikasi-vendor',
      page: PrakualifikasiVendor,
      hak: ['SUPA', 'PRO'],
    },
    {
      exact: true,
      path: '/master/prakualifikasi-vendor/calon-vendor',
      page: CalonVendor,
      hak: ['SUPA', 'PRO'],
    },
    {
      exact: true,
      path: '/master/prakualifikasi-vendor/tambah/:id',
      page: TambahPrakualifikasiVendor,
      hak: ['SUPA', 'PRO'],
    },
    {
      exact: true,
      path: '/master/prakualifikasi-vendor/ubah/:id',
      page: UbahPrakualifikasiVendor,
      hak: ['SUPA', 'PRO'],
    },

    // HARGA PERKIRAAN SENDIRI
    {
      exact: true,
      path: '/master/harga-perkiraan-sendiri',
      page: HargaPerkiraanSendiri,
      hak: ['SUPA', 'PRO_MAS_HPS'],
    },

    // JENIS KAYU
    {
      exact: true,
      path: '/master/jenis-kayu',
      page: JenisKayu,
      hak: ['SUPA', 'PRO_MAS_JKY'],
    },

    // JENIS KAYU
    {
      exact: true,
      path: '/master/part-kayu',
      page: PartKayu,
      hak: ['SUPA', 'PRO_MAS_PKY'],
    },

    // HARGA KUBIKASI KAYU
    {
      exact: true,
      path: '/master/harga-kubikasi-kayu',
      page: HargaKubikasiKayu,
      hak: ['SUPA', 'PRO_MAS_HKY'],
    },

    // KUALIFIKASI VENDOR
    {
      exact: true,
      path: '/master/kualifikasi-vendor',
      page: KualifikasiVendor,
      hak: ['SUPA', 'PRO'],
    },
    {
      exact: true,
      path: '/master/kualifikasi-vendor/kualifikasi/:id',
      page: Kualifikasi,
      hak: ['SUPA', 'PRO'],
    },
    {
      exact: true,
      path: '/master/kualifikasi-vendor/detail/:id',
      page: DetailKualifikasiVendor,
      hak: ['SUPA', 'PRO'],
    },
    {
      exact: true,
      path: '/master/item-upah',
      page: ItemUpah,
      hak: ['SUPA', 'PRO_REG_UPH'],
    },
    {
      exact: true,
      path: '/master/item-subcont',
      page: ItemSubcont,
      hak: ['SUPA', 'PRO_REG_SBC'],
    },
    {
      exact: true,
      path: '/master/item-prelim',
      page: ItemPrelimList,
      hak: ['SUPA', 'PRO_MAS_IPRE'],
    },
    {
      exact: true,
      path: '/master/kelompok-upah-subcont',
      page: KelompokUpahSubcont,
      hak: ['SUPA', 'PRO_MAS_KUS'],
    },

    // HARGA SATUAN PRELIM
    {
      text: 'Harga Satuan Prelim',
      path: '/master/harga-satuan-prelim',
      page: HargaSatuanPrelim,
      hak: ['SUPA', 'PRO_MAS_HSP'],
    },

    // TRANSAKSI
    // SELEKSI VENDOR
    {
      exact: true,
      path: '/transaksi/seleksi-vendor',
      page: SeleksiVendorList,
      hak: ['SUPA', 'PRO'],
    },
    {
      exact: true,
      path: '/transaksi/seleksi-vendor/purchase-request',
      page: PurchaseRequestSeleksiVendorList,
      hak: ['SUPA', 'PRO'],
    },
    {
      exact: true,
      path: '/transaksi/seleksi-vendor/tambah/:id_purchase_request',
      page: SeleksiVendorTambah,
      hak: ['SUPA', 'PRO'],
    },

    // PURCHASE ORDER
    {
      exact: true,
      path: '/transaksi/purchase-order',
      page: PurchaseOrderList,
      hak: ['SUPA', 'PRO_TRN_PO'],
    },
    {
      exact: true,
      path: '/transaksi/purchase-order/seleksi-vendor-list',
      page: PreformPurchaseOrderList,
      hak: ['SUPA', 'PRO_TRN_PO'],
    },
    {
      exact: true,
      path: '/transaksi/purchase-order/detail/:id_purchase_order',
      page: DetailPurchaseOrder,
      hak: ['SUPA', 'PRO_TRN_PO'],
    },
    {
      exact: true,
      path: '/transaksi/purchase-order/tambah/:id_vendor',
      page: TambahPurchaseOrder,
      hak: ['SUPA', 'PRO_TRN_PO'],
    },
    {
      exact: true,
      path: '/transaksi/purchase-order/ubah/:id_purchase_order',
      page: UbahPurchaseOrder,
      hak: ['SUPA', 'PRO_TRN_PO'],
    },

    // Transfer Upah Produksi
    {
      exact: true,
      path: '/transaksi/transfer-upah-produksi',
      page: TransferUpahProduksiList,
      hak: ['SUPA', 'PRO_TRN_PUP'],
    },
    {
      exact: true,
      path: '/transaksi/transfer-upah-produksi/list-permintaan-produksi',
      page: TransferUpahProduksiPreformList,
      hak: ['SUPA', 'PRO_TRN_PUP'],
    },
    {
      exact: true,
      path: '/transaksi/transfer-upah-produksi/tambah/:id_permintaan_produksi',
      page: TambahTransferUpahProduksi,
      hak: ['SUPA', 'PRO_TRN_PUP'],
    },
    {
      exact: true,
      path: '/transaksi/transfer-upah-produksi/detail/:id_permintaan_produksi/:id_kontrak_upah',
      page: DetailTransferUpahProduksi,
      hak: ['SUPA', 'PRO_TRN_PUP'],
    },

    // Transfer Subkon Produksi
    {
      exact: true,
      path: '/transaksi/transfer-subkon-produksi',
      page: TransferSubkonProduksiList,
      hak: ['SUPA', 'PRO_TRN_PSP'],
    },

    {
      exact: true,
      path: '/transaksi/transfer-subkon-produksi/list-permintaan-produksi',
      page: TransferSubkonProduksiPreformList,
      hak: ['SUPA', 'PRO_TRN_PSP'],
    },
    {
      exact: true,
      path: '/transaksi/transfer-subkon-produksi/tambah/:id_permintaan_produksi',
      page: TambahTransferSubkonProduksi,
      hak: ['SUPA', 'PRO_TRN_PUP'],
    },
    {
      exact: true,
      path: '/transaksi/transfer-subkon-produksi/detail/:id_permintaan_produksi/:id_kontrak_subkon',
      page: DetailTransferSubkonProduksi,
      hak: ['SUPA', 'PRO_TRN_PUP'],
    },

    // Kontrak Prelim
    {
      exact: true,
      path: '/transaksi/kontrak-prelim',
      page: ListKontrakPrelim,
      hak: ['SUPA', 'PRO_TRN_KPR'],
    },
    {
      exact: true,
      path: '/transaksi/kontrak-prelim/permintaan-prelim',
      page: ListPermintaanPrelim,
      hak: ['SUPA', 'PRO_TRN_KPR'],
    },
    {
      exact: true,
      path: '/transaksi/kontrak-prelim/tambah/detail-spk/:id',
      page: DetailSPKSection,
      hak: ['SUPA', 'PRO_TRN_KPR'],
    },
    {
      exact: true,
      path: '/transaksi/kontrak-prelim/tambah/:id_permintaan_prelim/:id_item_prelim',
      page: TambahKontrakPrelim,
      hak: ['SUPA', 'PRO_TRN_KPR'],
    },

    // Laporan
    {
      exact: true,
      path: '/laporan/purchase-order',
      page: LaporanPurchaseOrder,
      hak: ['SUPA', 'PRO_RPT_PO'],
    },
  ],
};
