import Services from 'services';

class PermintaanUpahProduksiApi {
  page(params) {
    return Services.get('/transfer_produksi_upah/page', { params });
  }

  preformPage(params) {
    return Services.get('transfer_produksi_upah/permintaan_page', { params });
  }

  single(params) {
    return Services.get('/transfer_produksi_upah/single_permintaan', {
      params,
    });
  }

  transfer(value) {
    return Services.get('/transfer_produksi_upah/transfer', {
      params: { ...value },
    });
  }

  getNomor(params) {
    return Services.get('/kontrak_upah/no_baru', { params });
  }

  getDropdownKontrakUpah(params) {
    return Services.get('/kontrak_upah/dropdown', { params });
  }

  saveKontrakUpah(data) {
    return Services.post('/kontrak_upah', data);
  }

  updateStatusPermintaan(data) {
    return Services.post('/kontrak_upah/status_permintaan', data);
  }

  getSingleKontrakUpah(params) {
    return Services.get('/kontrak_upah/single', { params });
  }

  dropdown(params) {
    return Services.get('/dropdown/dropdown', { params });
  }
}

export default new PermintaanUpahProduksiApi();
