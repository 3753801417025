import { useContext } from 'react';
import { Col, Modal } from 'react-bootstrap';
import { IoWarning } from 'react-icons/io5';
import { ActionButton } from 'components';
import { TransferSubkonProduksiContext } from '../Context';

export const ModalKonfirmasi = ({ handleSubmit, isSubmitting }) => {
  const { modalKonfirmasi, setModalKonfirmasi } = useContext(
    TransferSubkonProduksiContext
  );

  const onClose = () => setModalKonfirmasi(false);

  return (
    <Modal show={modalKonfirmasi} onHide={onClose}>
      <Modal.Header closeButton>Konfirmasi Simpan Data</Modal.Header>
      <Modal.Body>
        <Col className="d-flex flex-column justify-content-center align-items-center">
          <IoWarning color="#c93636" size={130} />
        </Col>
        <div
          className="text-center font-weight-bold"
          style={{ fontSize: '.9rem' }}
        >
          Apakah anda yakin ingin menyimpan data ini? <br />
          Data yang telah disimpan tidak bisa diedit!
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center align-items-center">
        <ActionButton
          className="px-3"
          text="Simpan"
          loading={isSubmitting}
          onClick={handleSubmit}
        />
        <ActionButton
          className="px-4"
          variant="danger"
          text="Batal"
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
};
