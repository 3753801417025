export const calcSubTotal = ({ qty = 0, unit_price = 0, konstanta = 0 }) =>
  Math.round(
    parseFloat(qty ?? 0) *
      parseFloat(unit_price ?? 0) *
      parseFloat(konstanta ?? 0)
  );

export const calcGrandTotal = (items = []) =>
  items?.reduce(
    (gt, item) =>
      gt +
      calcSubTotal({
        qty: item?.qty,
        unit_price: item?.unit_price,
        konstanta: item?.konstanta,
      }),
    0
  );
