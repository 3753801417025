import React from 'react';

const Td = ({ children, colSpan, rowSpan, textRight, className, ...props }) => {
  return (
    <td
      {...props}
      colSpan={colSpan}
      rowSpan={rowSpan}
      className={`p-1 px-2 align-middle ${
        textRight && 'text-right'
      } ${className}`}
      style={{
        ...props.style,
        fontSize: '14px',
      }}
    >
      {children}
    </td>
  );
};

export default Td;
