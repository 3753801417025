import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IoWarningSharp } from 'react-icons/io5';
import { ActionButton } from 'components';
import { PurchaseOrderApi } from 'api';

export const ModalKonfirmasiUbahStatusPO = ({
  show,
  data = null,
  onClose,
  setAlertConfig,
  getInitialData,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = () => {
    setIsSubmitting(true);

    const finalValue = {
      id_purchase_order: data.id_purchase_order,
    };

    PurchaseOrderApi.closePurchaseOrder(finalValue)
      .then(() => {
        setAlertConfig({
          show: true,
          variant: 'primary',
          text: 'Status Purchase Order berhasil diubah',
        });
        getInitialData();
        onClose();
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Status Purchase Order gagal diubah',
        });
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        Konfirmasi Ubah Status Purchase Order
      </Modal.Header>

      <Modal.Body className="d-flex flex-column justify-content-center align-items-center">
        <div>
          <IoWarningSharp size={80} color="#c93636" />
        </div>
        <small className="text-center font-weight-bold">
          Apa Anda yakin ingin mengubah status Purchase Order ini?
        </small>
      </Modal.Body>

      <Modal.Footer className="d-flex flex-row justify-content-center align-items-center">
        <ActionButton
          loading={isSubmitting}
          className="m-1 px-4"
          text="Ubah"
          onClick={onSubmit}
        />

        <ActionButton
          disable={isSubmitting}
          text="Batal"
          className="px-4"
          variant="danger"
          onClick={onClose}
        />
      </Modal.Footer>
    </Modal>
  );
};
