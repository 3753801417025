import { useEffect, useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import {
  Approval,
  InfoItemHorizontal,
  ActionButton,
  DataStatus,
} from 'components';
import { InputImages } from './InputImages';
import { SeleksiVendorApi } from 'api';
import { DateConvert, RupiahConvert } from 'utilities';

const InfoSectionModal = ({ data }) => {
  const APPROVAL = {
    PEN: 'PENDING',
    REV: 'REVISE',
    VER: 'VERIFY',
    REJ: 'REJECT',
    APP: 'APPROVED',
  };

  return (
    <Row>
      <Col>
        <InfoItemHorizontal
          label="Nama Pembuat Pengajuan"
          text={data?.nama_karyawan_pengaju ?? '-'}
        />
        <InfoItemHorizontal
          label="Jabatan Pembuat Pengajuan"
          text={data?.nama_jabatan_pengaju ?? '-'}
        />
        <InfoItemHorizontal
          label="Tgl. Input Data"
          text={
            data?.created_at
              ? DateConvert(new Date(data.created_at)).detail
              : '-'
          }
        />
        <InfoItemHorizontal
          label="Tgl. Seleksi Vendor"
          text={
            data?.tgl_seleksi_vendor
              ? DateConvert(new Date(data.tgl_seleksi_vendor)).detail
              : '-'
          }
        />

        <InfoItemHorizontal
          label="No. Seleksi Vendor"
          text={data?.no_seleksi_vendor ?? '-'}
        />

        <InfoItemHorizontal
          label="Tgl. Purchase Request"
          text={
            data?.tgl_purchase_request
              ? DateConvert(new Date(data.tgl_purchase_request)).detail
              : '-'
          }
        />
        <InfoItemHorizontal
          label="No. Purchase Request"
          text={data?.no_purchase_request ?? '-'}
        />
        <InfoItemHorizontal
          label="Status Approval"
          text={APPROVAL[data.status_approval ?? 'PEN']}
        />
      </Col>
      <Col>
        <InfoItemHorizontal label="Proyek" text={data.nama_proyek} />
        <InfoItemHorizontal label="Keperluan" text={data.keperluan} />
        <InfoItemHorizontal label="Item Barang" text={data.nama_item} />
        <InfoItemHorizontal
          label="Qty. Seleksi Vendor"
          text={parseFloat(data.qty_order)}
        />
        <InfoItemHorizontal label="Vendor" text={data.nama_vendor} />
        <InfoItemHorizontal
          label="Harga Satuan Kesepakatan"
          text={RupiahConvert(String(data.harga_kesepakatan)).getWithComaLabel}
        />
        <InfoItemHorizontal
          label="Total Harga"
          text={
            RupiahConvert(String(data.harga_kesepakatan * data.qty_order))
              .getWithComaLabel
          }
        />
        <InfoItemHorizontal
          label="Catatan Seleksi Vendor"
          text={data.keterangan ?? '-'}
        />
      </Col>
    </Row>
  );
};

const LoadingState = ({ loading }) => {
  return (
    <Modal.Body>
      <DataStatus loading={loading} text="Memuat data . . ." />
    </Modal.Body>
  );
};

export const ModalDetailSeleksiVendor = ({
  id_seleksi_vendor,
  show,
  onHide,
}) => {
  const { REACT_APP_FILE_BASE_URL } = process.env;
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const getInitialData = () => {
    setLoading(true);

    SeleksiVendorApi.getSingle({ id_seleksi_vendor })
      .then((res) => {
        const result = res.data.data;
        const gambar = result.gambar.map((g) => ({
          link: `${REACT_APP_FILE_BASE_URL}/${g.path_gambar}`,
        }));

        setData({ ...result, gambar } ?? {});
      })
      .catch(({ response }) =>
        alert(`Data gagal dimuat! ${response.data.message ?? ''}`)
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => !!id_seleksi_vendor && getInitialData(), [id_seleksi_vendor]);

  return (
    <Modal show={show} size="xl" onHide={onHide}>
      <Modal.Header closeButton>Detail Data Seleksi Vendor</Modal.Header>
      {isLoading ? (
        <LoadingState loading={isLoading} />
      ) : (
        <Modal.Body>
          <InfoSectionModal data={data} />
          <InputImages
            readOnly
            downloadButton
            label="Dokumen Seleksi Vendor"
            images={data.gambar ?? []}
          />
          <Approval data={data} />
        </Modal.Body>
      )}
      <Modal.Footer className="d-flex justify-content-end align-items-end">
        <ActionButton text="Tutup" className="btn-light" onClick={onHide} />
      </Modal.Footer>
    </Modal>
  );
};
