import Services from '../../services';

class VendorApi {
  get() {
    return Services.get('/vendor_prakualifikasi');
  }

  single(id) {
    return Services.get(
      '/vendor_prakualifikasi/single?id_vendor_prakualifikasi=' + id
    );
  }

  singleCalonVendor(id) {
    return Services.get(
      '/vendor_prakualifikasi/calon_vendor_single?id_vendor=' + id
    );
  }

  getPage(page, dataLength, key) {
    return Services.get(
      `/vendor_prakualifikasi/page?page=${page}&per_page=${dataLength}&q=${key}`
    );
  }

  getPageCalonVendor(page, dataLength, key) {
    return Services.get(
      `/vendor_prakualifikasi/calon_vendor/page/?page=${page}&per_page=${dataLength}&q=${key}`
    );
  }

  getDokumenUtama(data) {
    return Services.get(
      '/vendor_prakualifikasi/dokumen_utama?id_vendor_prakualifikasi=' + data
    );
  }

  getDokumenLainnya(data) {
    return Services.get(
      '/vendor_prakualifikasi/dokumen_lainnya?id_vendor_prakualifikasi=' + data
    );
  }

  getJumlahItem(data) {
    return Services.get(
      '/vendor_prakualifikasi/jumlah_item?id_vendor_prakualifikasi=' + data
    );
  }

  getBuaso(data, jenis) {
    return Services.get(
      '/vendor_prakualifikasi/item_buaso?id_vendor_prakualifikasi=' +
        data +
        '&jenis=' +
        jenis
    );
  }

  getNoBaru(tanggal) {
    return Services.get(`/vendor_prakualifikasi/no_baru?tanggal=${tanggal}`);
  }

  search(id) {
    return Services.get('/vendor_prakualifikasi/page?q=' + id);
  }

  searchCalonVendor(id) {
    return Services.get('/vendor_prakualifikasi/calon_vendor/page?q=' + id);
  }

  create(value) {
    return Services.post('/vendor_prakualifikasi', value);
  }

  update(value) {
    return Services.put('/vendor_prakualifikasi', value);
  }

  getDokumen() {
    return Services.get('/vendor_prakualifikasi/list_dokumen');
  }

  createDokumenLain(value) {
    return Services.post(
      '/vendor_prakualifikasi/tambah_dokumen_lainnya',
      value
    );
  }

  deleteDokumenLain(value) {
    return Services.post('/vendor_prakualifikasi/hapus_dokumen_lainnya', value);
  }
}

export default new VendorApi();
