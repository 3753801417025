// React
import React, { useEffect, useState } from 'react';

// Component
import {
  DataStatus,
  Table,
  THead,
  TBody,
  Tr,
  Th,
  Td,
} from '../../../components';
import { Row, Col, Card, Button } from 'react-bootstrap';

// Link
import { useParams, useHistory } from 'react-router-dom';

// API
import {
  VendorApi,
  ItemBahanApi,
  ItemUpahApi,
  ItemSubconApi,
} from '../../../api';

// Icon
import { IoArrowBackOutline } from 'react-icons/io5';

const DetailVendor = ({ setNavbarTitle }) => {
  let history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({
    jenis_vendor: [],
  });
  const [dataBahan, setDataBahan] = useState([]);
  const [dataUpah, setDataUpah] = useState([]);
  const [dataSubcon, setDataSubcon] = useState([]);
  let { id } = useParams();

  const getData = () => {
    setIsLoading(true);

    VendorApi.single(id)
      .then((res) => setData(res.data.data))
      .catch((err) => alert(err))
      .finally(() => setIsLoading(false));
  };

  const getDataBahan = () => {
    ItemBahanApi.get(id)
      .then((res) => {
        setDataBahan(res.data.data);
      })
      .catch((err) => alert(err));
  };

  const getDataUpah = () => {
    ItemUpahApi.get(id)
      .then((res) => setDataUpah(res.data.data))
      .catch((err) => alert(err));
  };

  const getDataSubcon = () => {
    ItemSubconApi.get(id)
      .then((res) => setDataSubcon(res.data.data))
      .catch((err) => alert(err));
  };

  useEffect(() => {
    setNavbarTitle('Detail Vendor');

    getData();
    getDataBahan();
    getDataUpah();
    getDataSubcon();
  }, [setNavbarTitle]);

  const ShowData = ({ title, text }) => (
    <div className="d-flex flex-row">
      <div className="text-secondary mb-2 mr-3" style={{ width: 170 }}>
        {title}
      </div>
      <span>:</span>
      <div
        className="text-dark"
        style={{
          paddingLeft: 5,
        }}
      >
        {text ? text : '-'}
      </div>
    </div>
  );

  return (
    <>
      {isLoading === true ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <h5>DETAIL VENDOR</h5>

            <Button
              variant="outline-secondary"
              size="sm"
              className="mx-1 mb-1"
              onClick={() => history.goBack()}
            >
              <IoArrowBackOutline size={20} />
              <span className="ml-2">Kembali Ke Tabel</span>
            </Button>
          </div>

          <Row>
            <Col md="6" className="mb-2">
              <Card>
                <Card.Header>
                  <Card.Title>Data Utama</Card.Title>
                </Card.Header>

                <Card.Body>
                  <ShowData title="Username" text={data.username} />

                  <ShowData title="Kode Vendor" text={data.kode_vendor} />

                  <ShowData title="Nama Vendor" text={data.nama_vendor} />

                  <ShowData
                    title="Jenis Vendor"
                    text={data.jenis_vendor
                      .map((val) => val.jenis_vendor)
                      .join(', ')}
                  />

                  <ShowData
                    title="Pimpinan Perusahaan"
                    text={data.nama_pimpinan_perusahaan}
                  />

                  <ShowData
                    title="Status Vendor"
                    text={
                      data.status_vendor == 0 ? 'Calon Vendor' : 'Vendor Resmi'
                    }
                  />

                  <ShowData
                    title="Status Tender"
                    text={
                      data.status_tender == 0
                        ? 'Belum Pernah Menang Tender'
                        : data.status_tender == 1
                        ? 'Menangani Tender Yang Sedang Berjalan'
                        : 'Tender Sudah Closed'
                    }
                  />
                </Card.Body>
              </Card>
            </Col>

            <Col md="6" className="mb-2">
              <Card>
                <Card.Header>
                  <Card.Title>Data Alamat</Card.Title>
                </Card.Header>

                <Card.Body>
                  <ShowData title="Alamat Vendor" text={data.alamat_vendor} />

                  <ShowData title="Desa" text={data.nama_desa} />

                  <ShowData title="Kecamatan" text={data.nama_kecamatan} />

                  <ShowData title="Kabupaten" text={data.nama_kabupaten} />

                  <ShowData title="Provinsi" text={data.nama_provinsi} />

                  <ShowData title="Kode Pos" text={data.kode_pos} />
                </Card.Body>
              </Card>
            </Col>

            <Col md="6" className="mb-2">
              <Card>
                <Card.Header>
                  <Card.Title>Data Kontak</Card.Title>
                </Card.Header>

                <Card.Body>
                  <ShowData title="Contact Person" text={data.contact_person} />

                  <ShowData title="Telepon" text={data.telepon} />

                  <ShowData title="Email" text={data.email} />

                  <ShowData title="Media Sosial" text={data.media_sosial} />
                </Card.Body>
              </Card>
            </Col>

            <Col md="6" className="mb-2">
              <Card>
                <Card.Header>
                  <Card.Title>Data Bank</Card.Title>
                </Card.Header>

                <Card.Body>
                  <ShowData title="No. Rekening" text={data.no_rekening} />

                  <ShowData title="Bank" text={data.nama_bank} />

                  <ShowData title="Atas Nama" text={data.atas_nama} />
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {data.jenis_vendor.map((value) => {
            if (value.jenis_vendor == 'Supplier') {
              return (
                <>
                  <small>List Item Bahan</small>
                  <Table>
                    <THead>
                      <Tr>
                        <Th>Kode Item Bahan</Th>
                        <Th>Nama Item Bahan</Th>
                        <Th>Kelompok Bahan</Th>
                        <Th>Jenis Bahan</Th>
                        <Th>Tipe Bahan</Th>
                        <Th>Satuan Pakai</Th>
                      </Tr>
                    </THead>

                    <TBody>
                      {dataBahan.length > 0 ? (
                        dataBahan.map((val, index) => (
                          <Tr key={index}>
                            <Td>{val.kode_item}</Td>
                            <Td>{val.nama_item}</Td>
                            <Td>{val.nama_kelompok}</Td>
                            <Td>{val.nama_jenis}</Td>
                            <Td>{val.tipe}</Td>
                            <Td>{val.satuan_pakai}</Td>
                          </Tr>
                        ))
                      ) : (
                        <Tr>
                          <td
                            colSpan="6"
                            className="my-2 font-weight-bold text-center"
                          >
                            Tidak Ada Data
                          </td>
                        </Tr>
                      )}
                    </TBody>
                  </Table>
                </>
              );
            } else if (value.jenis_vendor == 'Mandor') {
              return (
                <>
                  <small>List Item Upah</small>
                  <Table>
                    <THead>
                      <Tr>
                        <Th>Kode Item Bahan</Th>
                        <Th>Nama Item Bahan</Th>
                        <Th>Satuan Pakai</Th>
                      </Tr>
                    </THead>

                    <TBody>
                      {dataUpah.length > 0 ? (
                        dataUpah.map((val, index) => (
                          <Tr key={index}>
                            <Td>{val.kode_item}</Td>
                            <Td>{val.nama_item}</Td>
                            <Td>{val.nama_satuan}</Td>
                          </Tr>
                        ))
                      ) : (
                        <Tr>
                          <td
                            colSpan="3"
                            className="my-2 font-weight-bold text-center"
                          >
                            Tidak Ada Data
                          </td>
                        </Tr>
                      )}
                    </TBody>
                  </Table>
                </>
              );
            } else if (value.jenis_vendor == 'Subcon') {
              return (
                <>
                  <small>List Item Subcon</small>
                  <Table>
                    <THead>
                      <Tr>
                        <Th>Kode Item Subcon</Th>
                        <Th>Nama Item Subcon</Th>
                        <Th>Kelompok Subcon</Th>
                        <Th>Satuan Pakai</Th>
                      </Tr>
                    </THead>

                    <TBody>
                      {dataSubcon.length > 0 ? (
                        dataSubcon.map((val, index) => (
                          <Tr key={index}>
                            <Td>{val.kode_item}</Td>
                            <Td>{val.nama_item}</Td>
                            <Td>{val.nama_kelompok}</Td>
                            <Td>{val.nama_satuan}</Td>
                          </Tr>
                        ))
                      ) : (
                        <Tr>
                          <td
                            colSpan="4"
                            className="my-2 font-weight-bold text-center"
                          >
                            Tidak Ada Data
                          </td>
                        </Tr>
                      )}
                    </TBody>
                  </Table>
                </>
              );
            }
          })}
        </>
      )}
    </>
  );
};

export default DetailVendor;
