import React, { useState, useEffect } from 'react';
import { EditorState, RichUtils, getDefaultKeyBinding } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'draft-js/dist/Draft.css';

const AdvanceTextEditor = ({
  label,
  value,
  height,
  wrapperClassName,
  wrapperStyle,
  style,
  error,
  errorText,
  options,
  onChange,
  ...props
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    const blocksFromHTML = htmlToDraft(`${value ?? ''}`);
    const { contentBlocks, entityMap } = blocksFromHTML;
    const state = EditorState.createWithContent(
      ContentState.createFromBlockArray(contentBlocks, entityMap)
    );

    setEditorState(state);
  }, []);

  return (
    <div className={`mb-2 ${wrapperClassName}`} style={wrapperStyle}>
      <label>
        <small>{label}</small>
      </label>
      <Editor
        {...props}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        editorClassName={`text-editor ${isFocused && 'focused'} ${
          error && 'is-invalid'
        }`}
        editorStyle={{
          height: height ?? '',
          paddingLeft: 10,
          paddingRight: 10,
          margin: 0,
          background: 'white',
          fontSize: 14,
        }}
        toolbar={{
          options: options ?? ['inline', 'list', 'textAlign', 'history'],
          inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough'],
          },
        }}
        editorState={editorState}
        onEditorStateChange={(value) => {
          const convertRaw = convertToRaw(value.getCurrentContent());
          const convertToHTML = draftToHtml(convertRaw);
          setEditorState(value);
          onChange && onChange(convertToHTML);
        }}
        onTab={(e) => {
          if (e.keyCode === 9) {
            setEditorState(RichUtils.onTab(e, editorState, 4));

            return;
          }

          return getDefaultKeyBinding(e);
        }}
      />
      <small className="mt-1 text-danger">{errorText}</small>
    </div>
  );
};

export default AdvanceTextEditor;
