import { Fragment, useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ActionButton } from 'components';
import { TransferSubkonProduksiContext } from '../Context';
import { TablePermintaan } from './TablePermintaan';
import { TableTransfer } from './TableTransfer';

export const TabSubkonFinishing = () => {
  const { dataPermintaan, dataTransfer, dataHistory, setModalForm } =
    useContext(TransferSubkonProduksiContext);

  const showModalKontrak = () =>
    setModalForm((prev) => ({
      ...prev,
      tab: 'sf',
      action: 'create',
      showRegistrasi: true,
    }));

  return (
    <Fragment>
      <Row className="d-flex justify-content-between align-items-end mb-1">
        <Col>
          <small>List Data Permintaan Subkon Finishing</small>
        </Col>

        <Col className="d-flex justify-content-end align-items-end">
          <ActionButton
            size="sm"
            text="Buat Kontrak"
            onClick={showModalKontrak}
          />
        </Col>
      </Row>

      <TablePermintaan tab="sf" data={dataPermintaan?.sf ?? []} />

      <small className="mt-2">List Data Transfer Subkon Finishing</small>
      <TableTransfer tab="sf" tipe="editable" data={dataTransfer?.sf ?? []} />

      <small className="mt-2">History Transfer Subkon Finishing</small>
      <TableTransfer tab="sf" tipe="readonly" data={dataHistory?.sf ?? []} />
    </Fragment>
  );
};
