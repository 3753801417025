import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import { ActionButton, AdvanceTextEditor } from 'components';
import {
  textFormatKontrakTop,
  textFormatKontrakMiddle,
  textFormatKontrakBottom,
} from '../Helpers';
import { DateConvert } from 'utilities';
import { TableItemKontrak } from './TableItemKontrak';
import { TransferUpahProduksiContext } from '../Context';

export const ModalFormKontrak = ({ readOnly = false }) => {
  const { id_permintaan_produksi } = useParams();

  const { modalForm, setModalForm, dataTransfer, setDataTransfer } = useContext(
    TransferUpahProduksiContext
  );

  const data = modalForm?.data ?? {};
  const isPLC = modalForm.tab === 'lc';

  const textTop = textFormatKontrakTop({
    no: data?.no_kontrak ?? data.no_kontrak_upah,
    name: data?.nama_karyawan,
    address: data?.alamat_mgp,
    item: data?.nama_barang_jadi,
    position: data?.nama_jabatan,
    vendorName: data?.nama_vendor,
    vendorPosition: data?.jabatan_vendor,
    vendorAddress: data?.alamat_vendor,
  });

  const textMiddle = textFormatKontrakMiddle({
    vendorName: data?.nama_vendor,
    date: data?.tgl_penawaran
      ? `${DateConvert(new Date(data.tgl_penawaran)).defaultDay} ${
          DateConvert(new Date(data.tgl_penawaran)).detailMonth
        } ${DateConvert(new Date(data.tgl_penawaran)).defaultYear}`
      : '',
  });

  const textBottom = textFormatKontrakBottom();

  const initialValues = {
    id_permintaan_produksi,
    tgl_kontrak_upah: data?.tgl_kontrak ?? '',
    no_kontrak_upah: data?.no_kontrak ?? '',
    id_vendor: data?.id_vendor ?? '',
    teks_kontrak_part_1: textTop,
    teks_kontrak_part_2: textMiddle,
    teks_kontrak_part_3: textBottom,
    ref_kontrak_upah: data.ref_kontrak_upah
      ? data.ref_kontrak_upah
      : isPLC
      ? 'klc'
      : 'klf',
    ppn: data?.ppn ?? 0,
    dp: data?.dp ?? 0,
    retensi: data?.retensi ?? 0,
    detail: data?.detail ?? [],
  };

  const onSubmit = (values, { setSubmitting }) => {
    const finalValues = {
      ...data,
      ...values,
    };

    if (modalForm.action === 'create') {
      setDataTransfer((prev) => ({
        ...prev,
        [modalForm.tab]: prev[modalForm.tab]?.toSpliced(0, 0, finalValues),
      }));
    }

    if (modalForm.action === 'update') {
      const indexItem = dataTransfer[modalForm.tab].findIndex(
        (item) => item.no_kontrak === modalForm.data.no_kontrak
      );

      setDataTransfer((prev) => ({
        ...prev,
        [modalForm.tab]: prev[modalForm.tab]?.toSpliced(
          indexItem,
          1,
          finalValues
        ),
      }));
    }

    setSubmitting(false);

    setModalForm({
      tab: 'lc',
      showRegistrasi: false,
      showKontrak: false,
      data: null,
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, isSubmitting, handleSubmit }) => (
        <Modal
          size="xl"
          scrollable
          show={modalForm.showKontrak}
          onHide={() =>
            setModalForm((prev) => ({ ...prev, showKontrak: false }))
          }
        >
          <Modal.Header closeButton>
            <b>Format Kontrak</b>
          </Modal.Header>

          <Modal.Body>
            {/* Top Text Editor */}
            <AdvanceTextEditor
              readOnly={readOnly}
              value={values.teks_kontrak_part_1}
              onChange={(val) => setFieldValue('teks_kontrak_part_1', val)}
            />
            {/* Table */}
            <div className="mt-4">
              <TableItemKontrak readOnly={readOnly} />
            </div>
            {/* Middle Text Editor */}
            <AdvanceTextEditor
              readOnly={readOnly}
              value={values.teks_kontrak_part_2}
              onChange={(val) => setFieldValue('teks_kontrak_part_2', val)}
            />
            {/* Bottom Text Editor */}
            <AdvanceTextEditor
              readOnly={readOnly}
              value={values.teks_kontrak_part_3}
              onChange={(val) => setFieldValue('teks_kontrak_part_3', val)}
            />
          </Modal.Body>

          <Modal.Footer>
            {readOnly ? null : (
              <ActionButton
                disable={isSubmitting}
                text="Simpan"
                loading={isSubmitting}
                onClick={handleSubmit}
              />
            )}
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  );
};
