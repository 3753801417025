import { useState, useCallback } from 'react';
import AsyncCreateableSelect from 'react-select/async-creatable';

export const SelectSearchCreateable = ({
  name,
  label,
  option,
  placeholder,
  error,
  errorText,
  defaultValue,
  isMulti,
  isDisabled,
  isLoading,
  value,
  onChange,
  onCreateOption,
  ...rest
}) => {
  const options = option ?? [];
  const [isAddingInProgress, setIsAddingInProgress] = useState(false);

  const filterOptions = (inputValue) => {
    return option?.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(Boolean(inputValue) ? filterOptions(inputValue) : option);
      }, 500);
    });

  const addNewOption = async (inputValue) => {
    const newOption = { label: inputValue, value: inputValue };
    options.push(newOption);

    return newOption;
  };

  // 1. Handle Create New Option
  const defaultOnCreateOptions = useCallback(async (inputValue) => {
    setIsAddingInProgress(true);

    const newOption = await addNewOption(inputValue);

    onChange((prev) => [...prev, newOption]);
    setIsAddingInProgress(false);
  }, []);

  return (
    <div className="mb-2">
      <small style={{ textTransform: 'capitalize' }}>{label}</small>
      {isLoading ? (
        <div className="text-secondary text-center">
          <small>Memuat . . .</small>
        </div>
      ) : (
        <AsyncCreateableSelect
          cacheOptions
          defaultOptions
          name={name}
          placeholder={placeholder}
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          loadOptions={promiseOptions}
          onCreateOption={onCreateOption ?? defaultOnCreateOptions}
          isMulti={isMulti}
          isLoading={isAddingInProgress}
          classNamePrefix={error ? 'react-select-invalid' : 'react-select'}
          styles={{
            control: (base) => ({
              ...base,
              minHeight: 28,
              fontSize: 14,
            }),
            valueContainer: (base) => ({
              ...base,
              paddingLeft: 5,
              margin: 0,
            }),
            dropdownIndicator: (base) => ({
              ...base,
              padding: 0,
              paddingLeft: 5,
              paddingRight: 5,
            }),
            menu: (base) => ({
              ...base,
              fontSize: 13,
            }),
          }}
          {...rest}
        />
      )}

      <small className="text-danger">{error && errorText}</small>
    </div>
  );
};
