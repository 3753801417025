import Services from 'services';

class AnalisaBarangJadi {
  getSingle(params) {
    return Services.get('/analisa_barang_jadi_rab/single', { params });
  }

  getAnalisaSingle(params) {
    return Services.get('/rab/analisa_single/', { params });
  }

  getAnalisaRAESingle(params) {
    return Services.get('/rab/analisa_rae_single', { params });
  }

  getKayu(params) {
    return Services.get('/analisa_barang_jadi_rab/dropdown_kayu', { params });
  }

  getKelompokTipeBarangJadi(params) {
    return Services.get('/analisa_barang_jadi_rab/dropdown_kelompok_tipe', {
      params,
    });
  }

  getTipeBarangJadi(params) {
    return Services.get('/analisa_barang_jadi_rab/dropdown_tipe', { params });
  }

  getDropdownTipeBarangJadi(params) {
    return Services.get('/analisa_barang_jadi_rab/dropdown_tipe', { params });
  }

  getSingleTipeBarangJadi(params) {
    return Services.get('/analisa_barang_jadi_rab/dropdown_tipe', { params });
  }

  getSingleTemplatePenunjang(params) {
    return Services.get('/analisa_barang_jadi_rab/list_template', { params });
  }

  getFinishingBarangJadi(params) {
    return Services.get('/analisa_barang_jadi_rab/dropdown_finishing', {
      params,
    });
  }

  getDropdownFinishingBarangJadi(params) {
    return Services.get('/analisa_barang_jadi_rab/dropdown_finishing', {
      params,
    });
  }

  getSingleFinishingBarangJadi(params) {
    return Services.get('/analisa_barang_jadi_rab/dropdown_finishing', {
      params,
    });
  }

  getDropdownDeskripsi() {
    return Services.get('/analisa_barang_jadi_rab/d_deskripsi');
  }

  getDropdownPartKayu() {
    return Services.get('/analisa_barang_jadi_rab/d_part_kayu');
  }

  getDropdownTipeSisi() {
    return Services.get('/analisa_barang_jadi_rab/d_tipe_sisi');
  }

  getDropdownTipeFinishing() {
    return Services.get('/analisa_barang_jadi_rab/d_finishing');
  }

  getDropdownSpekKayu() {
    return Services.get('/analisa_barang_jadi_rab/d_jenis_kayu');
  }

  getDropdownSpekPlywood() {
    return Services.get('/analisa_barang_jadi_rab/d_plywood');
  }

  getHargaKubikasiKayu(params) {
    return Services.get('/analisa_barang_jadi_rab/harga_kubikasi', { params });
  }

  getDropdownTipeProduksi(params) {
    return Services.get('/analisa_barang_jadi_rab/d_tipe_produksi', { params });
  }

  getDropdownBentuk(params) {
    return Services.get('/analisa_barang_jadi_rab/d_bentuk', { params });
  }

  getDropdownUkuran(params) {
    return Services.get('/analisa_barang_jadi_rab/d_ukuran', { params });
  }

  getDropdownItemLabourCost(params) {
    return Services.get('/dropdown/upah', { params });
  }

  getTemplateLabourCost(params) {
    return Services.get('/rab/template_labour_cost_on_site', { params });
  }

  save(data) {
    return Services.post('/analisa_barang_jadi_rab', data);
  }

  uploadFile(data) {
    return Services.post('/analisa_barang_jadi_rab/gambar', data);
  }

  deleteFile(data) {
    return Services.post('/analisa_barang_jadi_rab/gambar_delete', data);
  }

  getKelompokPrelim(params) {
    return Services.get('/analisa_barang_jadi_rab/list_kelompok_prelim', {
      params,
    });
  }

  getItemPrelim(params) {
    return Services.get('/analisa_barang_jadi_rab/dropdown_item_prelim', {
      params,
    });
  }

  getRabExport(params) {
    return Services.get('/rab_report', { params });
  }
}

export default new AnalisaBarangJadi();
