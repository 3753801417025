import { Fragment } from 'react';
import {
  CRUDLayout,
  DataStatus,
  THead,
  Tr,
  ThFixed,
  Th,
  Td,
  TBody,
  ActionButton,
} from 'components';
import { RupiahConvert } from 'utilities';
import { IoAddOutline } from 'react-icons/io5';

export const TablePurchaseRequest = ({ data, setModal }) => {
  const onCreateItem = (data) =>
    setModal({ action: 'create', type: 'form', show: true, data });

  return (
    <Fragment>
      <div className="font-weight-bold mt-4" style={{ fontSize: '.9rem' }}>
        List Item Purchase Request
      </div>
      <CRUDLayout.Table>
        <THead>
          <Tr className="text-center">
            <ThFixed>No.</ThFixed>
            <ThFixed>Kode Item</ThFixed>
            <Th>Nama Item</Th>
            <Th width={100} className="text-nowrap">
              Satuan Beli
            </Th>
            <Th width={100} className="text-nowrap">
              Qty. Purchase <br /> Request
            </Th>
            <Th width={100} className="text-nowrap">
              Total Qty. Telah <br /> Diseleksi
            </Th>
            <Th width={100} className="text-nowrap">
              HPS Beli
            </Th>
            <ThFixed>Aksi</ThFixed>
          </Tr>
        </THead>

        <TBody>
          {data?.length > 0 ? (
            data?.map((val, index) => (
              <Tr key={val.id_convert_barang}>
                <Td className="text-center">{index + 1}</Td>
                <Td>{val.kode_item}</Td>
                <Td>{val.nama_item}</Td>
                <Td>{val.satuan_beli}</Td>
                <Td textRight>{parseFloat(val.qty_pr ?? null)}</Td>
                <Td textRight>{parseFloat(val.qty_ordered ?? null)}</Td>
                <Td textRight>
                  {
                    RupiahConvert(String(val.harga_perkiraan_sendiri))
                      .getWithComaLabel
                  }
                </Td>
                <Td>
                  <ActionButton
                    disable={!!val.id_vendor}
                    size="sm"
                    onClick={() => onCreateItem(val)}
                  >
                    <IoAddOutline />
                  </ActionButton>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={10}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </Tr>
          )}
        </TBody>
      </CRUDLayout.Table>
    </Fragment>
  );
};
