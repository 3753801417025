// React
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// API
import { KontrakPrelimApi } from 'api';

// Component
import {
  CRUDLayout,
  InputSearch,
  Alert,
  Pagination,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  DataStatus,
  ActionButton,
  CreateButton,
} from 'components';
import { DateConvert, RupiahConvert, TableNumber } from 'utilities';
import {
  Button,
  OverlayTrigger,
  Tooltip,
  Row,
  Col,
  Modal,
} from 'react-bootstrap';

// Icon
import { IoEyeOutline } from 'react-icons/io5';

const ListKontrakPrelim = ({ setNavbarTitle }) => {
  const title = 'Kontrak Prelim';

  const history = useHistory();
  const location = useLocation();

  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(false);

  // menampung value dari search form
  const [searchKey, setSearchKey] = useState('');

  // data
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [totalData, setTotalData] = useState('');
  const [dataModal, setDataModal] = useState({
    show: false,
    data: {},
  });
  // menampilkan alert
  const [showAlert, setShowAlert] = useState(false);
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
  });
  const [alertConfigSearch, setAlertConfigSearch] = useState({
    data: false,
    variant: 'primary',
    text: '',
  });

  // request data dari server
  const getData = () => {
    setIsLoading(true);

    // request data ke server
    KontrakPrelimApi.page({
      page: page,
      per_page: dataLength,
      q: searchKey,
    })
      .then((res) => {
        setData(res.data.data);
        setTotalPage(res.data.total_page);
        setTotalData(res.data.data_count);
      })
      .catch(() => {
        setAlertConfig({
          variant: 'danger',
          text: 'Data gagal dimuat',
        });
        setShowAlert(true);
      })
      .finally(() => {
        if (searchKey !== '') {
          setAlertConfigSearch({
            data: true,
            variant: 'primary',
            text: `Hasil Pencarian : ${searchKey}`,
          });
        } else {
          setAlertConfigSearch({
            data: false,
            variant: 'primary',
            text: `Hasil Pencarian : ${searchKey}`,
          });
        }
        setIsLoading(false);
      });
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });

        setShowAlert(locationState.alert.show);
      }
    }
  };

  const dismissAlert = () => {
    setAlertConfig({ variant: 'primary', text: '' });
    setShowAlert(false);

    const locationState = location.state;
    let state = { ...locationState };
    delete state.alert;
    history.replace({ ...state });
  };

  useEffect(() => {
    // set Judul di Navbar
    setNavbarTitle(title);

    getData();
    checkAlert();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNavbarTitle, page, dataLength, searchKey]);

  const DataModal = () => {
    const InfoItem = ({ title, value }) => (
      <tr>
        <td width="120">{title}</td>
        <td className="pl-4 pr-2">:</td>
        <td>{value}</td>
      </tr>
    );

    return (
      <Modal
        show={dataModal.show}
        onHide={() =>
          setDataModal({
            show: false,
            data: {},
          })
        }
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-success">
            <h6 className="mb-0">Detail {title}</h6>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table style={{ fontSize: '14px' }}>
            <tbody>
              <InfoItem
                title="Tgl. Kontrak Prelim"
                value={
                  dataModal?.data?.tgl_kontrak_prelim
                    ? DateConvert(new Date(dataModal.data.tgl_kontrak_prelim))
                        .detail
                    : '-'
                }
              />
              <InfoItem
                title="No. Kontrak Prelim"
                value={dataModal?.data?.no_kontrak_prelim ?? '-'}
              />
              <InfoItem
                title="No. Permintaan Prelim"
                value={dataModal?.data?.no_permintaan_prelim ?? '-'}
              />
              <InfoItem
                title="No. SPK"
                value={dataModal?.data?.no_spk ?? '-'}
              />
              <InfoItem
                title="Item Prelim"
                value={
                  dataModal?.data?.nama_item_prelim
                    ? `${
                        dataModal?.data?.kode_item_prelim &&
                        `(${dataModal?.data?.kode_item_prelim}) `
                      }${dataModal?.data?.nama_item_prelim}`
                    : '-'
                }
              />
              <InfoItem
                title="Qty Kontrak Prelim"
                value={
                  dataModal?.data?.qty_kontrak
                    ? `${parseFloat(dataModal?.data?.qty_kontrak)} ${
                        dataModal?.data?.nama_satuan_kontrak
                      }`
                    : '-'
                }
              />
              <InfoItem
                title="Vendor"
                value={dataModal?.data?.nama_vendor ?? '-'}
              />
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    );
  };

  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Tgl. Kontrak Prelim</ThFixed>
              <ThFixed>No. Kontrak Prelim</ThFixed>
              <ThFixed>No. Permintaan Prelim</ThFixed>
              <Th>Item Prelim</Th>
              <Th>Qty. Kontrak Prelim</Th>
              <Th>Vendor</Th>
              <ThFixed>Harga Satuan</ThFixed>
              <Th>Durasi Prelim</Th>
              <ThFixed>Jumlah</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={index}>
                <TdFixed>{TableNumber(page, dataLength, index)}</TdFixed>
                <TdFixed>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>Detail</Tooltip>}
                  >
                    <Button
                      variant="primary"
                      size="sm"
                      className="m-1"
                      onClick={() =>
                        setDataModal({
                          show: true,
                          data: val,
                        })
                      }
                    >
                      <IoEyeOutline size={18} />
                    </Button>
                  </OverlayTrigger>
                </TdFixed>
                <TdFixed>
                  {val.tgl_kontrak_prelim
                    ? DateConvert(new Date(val.tgl_kontrak_prelim)).defaultDMY
                    : '-'}
                </TdFixed>
                <TdFixed>{val.no_kontrak_prelim ?? '-'}</TdFixed>
                <TdFixed>{val.no_permintaan_prelim ?? '-'}</TdFixed>
                <Td>
                  {val.nama_item_prelim
                    ? `${val.kode_item_prelim && `(${val.kode_item_prelim}) `}${
                        val.nama_item_prelim
                      }`
                    : '-'}
                </Td>
                <Td>
                  {val.qty_kontrak
                    ? `${parseFloat(val.qty_kontrak)} ${
                        val.nama_satuan_kontrak
                      }`
                    : '-'}
                </Td>
                <Td>{val.nama_vendor ?? '-'}</Td>
                <Td>
                  {val.harga_satuan
                    ? RupiahConvert(String(parseInt(val.harga_satuan))).detail
                    : 'Rp0'}
                </Td>
                <Td>
                  {val.qty_durasi ? `${parseInt(val.qty_durasi)} Hari` : '-'}
                </Td>
                <Td>
                  {Boolean(
                    val.qty_durasi && val.qty_kontrak && val.harga_satuan
                  )
                    ? RupiahConvert(
                        String(
                          parseInt(
                            parseFloat(val.qty_kontrak) *
                              parseInt(val.harga_satuan)
                          )
                        )
                      ).detail
                    : 'Rp0'}
                </Td>
              </Tr>
            ))}
          </TBody>
        </CRUDLayout.Table>
        <Pagination
          dataLength={dataLength}
          dataNumber={page * dataLength - dataLength + 1}
          dataCount={totalData}
          currentPage={page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) => setPage(selected + 1)}
          onDataLengthChange={(e) => {
            setPage(1);
            setDataLength(e.target.value);
          }}
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="mb-3">
            <Col md="8">
              <InputSearch
                onChange={(e) => {
                  setTimeout(() => {
                    setSearchKey(e.target.value);
                    setPage(1);
                  }, 1000);
                }}
                onSubmit={(e) => e.preventDefault()}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() =>
              history.push('/transaksi/kontrak-prelim/permintaan-prelim')
            }
          />
          {/* <ActionButton
            type="button"
            variant="primary"
            text="Tambah Data"
            onClick={() => history.push('/transaksi/kontrak-prelim/permintaan-prelim')}
          /> */}
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      {/* ALert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => dismissAlert()}
      />
      <Alert
        show={alertConfigSearch.data}
        showCloseButton={true}
        variant={alertConfigSearch.variant}
        text={alertConfigSearch.text}
        onClose={() => setShowAlert(false)}
      />

      {isLoading === true ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : data.length > 0 ? (
        <>
          <Table />
          <DataModal />
        </>
      ) : (
        <DataStatus text="Tidak ada data" />
      )}
    </CRUDLayout>
  );
};

export default ListKontrakPrelim;
