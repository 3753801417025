import { Fragment, useState } from 'react';

export const RincianPekerjaanCollapse = ({ data = [] }) => {
  const [isMore, setMore] = useState(false);

  const splicedData = [...data].splice(0, 2);

  const results = isMore ? data : splicedData;

  const textItem = (v) => {
    const toFloat = (number) => parseFloat(number);

    return `${v.uraian} ${toFloat(v.qty)} ${v.nama_satuan ?? ''}`;
  };

  return (
    <Fragment>
      <ul className="pl-3 mb-1" style={{ fontSize: '12px' }}>
        {results?.map((item, index) => (
          <li key={index} index={index}>
            <div className="d-flex">
              <span>{textItem(item)}</span>
            </div>
          </li>
        ))}
      </ul>

      {data.length > 2 && (
        <u
          className="text-primary"
          style={{
            cursor: 'pointer',
          }}
          onClick={() => setMore((prev) => !prev)}
        >
          {isMore ? 'Sembunyikan' : 'Selengkapnya...'}
        </u>
      )}
    </Fragment>
  );
};
