import { Fragment } from 'react';
import { Modal, Row, Col, Card } from 'react-bootstrap';
import { InfoItemHorizontal, ActionButton } from 'components';
import { TableSeleksiVendor } from './TableSeleksiVendor';
import { DateConvert } from 'utilities';

const InfoSectionModal = ({ data }) => {
  return (
    <Fragment>
      <div className="font-weight-bold" style={{ fontSize: '.9rem' }}>
        Detail Data Purchase Request
      </div>
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <InfoItemHorizontal
                label="Tgl. Purchase Request"
                text={
                  data.tgl_purchase_request
                    ? DateConvert(new Date(data.tgl_purchase_request)).detail
                    : '-'
                }
              />
              <InfoItemHorizontal
                label="No. Purchase Request"
                text={data?.no_purchase_request ?? '-'}
              />
              <InfoItemHorizontal
                className="text-nowrap"
                label="Pengajuan Purchase Request"
                text={data?.nama_karyawan_pengaju ?? '-'}
              />
              <InfoItemHorizontal
                label="Tgl. Pemakaian"
                text={
                  data?.tgl_pemakaian
                    ? DateConvert(new Date(data?.tgl_pemakaian)).detail
                    : '-'
                }
              />
            </Col>
            <Col>
              <InfoItemHorizontal label="Keperluan" text={data?.keperluan} />
              <InfoItemHorizontal label="Proyek" text={data?.nama_proyek} />
              <InfoItemHorizontal
                label="Catatan Purchase Request"
                text={data?.catatan_purchase_request}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export const ModalKonfirmasiSeleksiVendor = ({
  show,
  data,
  purchaseRequest,
  isSubmitting,
  onHide,
  onSubmitConfirm,
}) => {
  return (
    <Modal show={show} size="xl" onHide={onHide}>
      <Modal.Header closeButton>
        Apakah Anda Yakin Akan Menyimpan Data Berikut?
      </Modal.Header>
      <Modal.Body>
        <InfoSectionModal data={data} />
        <TableSeleksiVendor
          type="detail"
          data={purchaseRequest?.filter((pr) => !!pr.id_vendor)}
        />
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end align-items-end">
        <ActionButton
          disable={isSubmitting}
          text="Batal"
          className="btn-light"
          onClick={onHide}
        />
        <ActionButton
          loading={isSubmitting}
          text="Simpan"
          onClick={onSubmitConfirm}
        />
      </Modal.Footer>
    </Modal>
  );
};
