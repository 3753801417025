// React
import React, { useState, useEffect } from 'react';

// Component
import { Col, Modal, Row } from 'react-bootstrap';
import {
  CRUDLayout,
  Input,
  InputSearch,
  Tr,
  Th,
  Td,
  ActionButton,
  DataStatus,
  TdFixed,
  ThFixed,
  THead,
  TBody,
  Alert,
  Pagination,
  Select,
} from 'components';
import { TableNumber, RupiahConvert, DateConvert } from 'utilities';

// Form
import { Formik } from 'formik';
import * as Yup from 'yup';

// API
import { HargaSatuanPrelimApi } from 'api';
import { useIsGuest } from 'hooks';

const HargaPerkiraanSendiri = ({ setNavbarTitle }) => {
  const title = 'Harga Satuan Prelim';
  const isGuest = useIsGuest();

  // STATE LOADING
  const [isLoading, setIsLoading] = useState(false);

  // MODALS SHOW STATE
  const [modal, setModal] = useState({
    show: false,
    data: {},
  });

  // STATE DATA SATUAN
  const [data, setData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [page, setPage] = useState(1);
  const [key, setKey] = useState('');
  const [filter, setFilter] = useState({
    label: 'Semua Data',
    value: '',
  });
  const [dataLength, setDataLength] = useState(10);
  const [totalPage, setTotalPage] = useState(1);
  // STATE MENAMPUNG DATA YANG AKAN DI UPDATE
  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false);
  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
  });

  // FUNCTION GET DATA DARI SERVER
  const getData = () => {
    setIsLoading(true);
    setShowAlert(false);

    HargaSatuanPrelimApi.page({
      page: page,
      per_page: dataLength,
      q: key,
    })
      .then((res) => {
        setData(res.data.data);
        setTotalPage(res.data.total_page);
        setDataCount(res.data.data_count);
      })
      .catch((err) => alert(err))
      .finally(() => {
        if (key !== '') {
          setAlertConfig({
            variant: 'primary',
            text: `Hasil Pencarian : ${key}`,
          });
          setShowAlert(true);
        }
        setIsLoading(false);
      });
  };

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle(title); // SET JUDUL NAVBAR
    getData(); // GET DATA DARI SERVER

    return () => {
      setIsLoading(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNavbarTitle, page, dataLength, key, filter]);

  // FORMIK VALIDATION SCHEMA DENGAN YUP VALIDATION
  const formValidationSchema = Yup.object().shape({
    harga_satuan_prelim: Yup.string().required(
      'Harga Satuan Prelim wajib diisi'
    ),
  });

  // MODAL UBAH COMPONENT
  const UbahModal = () => {
    const formInitialValues = {
      id_item_buaso_prelim: modal?.data?.id_item_buaso_prelim,
      harga_satuan_prelim:
        modal?.data?.harga_satuan_prelim &&
        RupiahConvert(String(parseInt(modal.data.harga_satuan_prelim))).detail,
    };

    // KIRIM UPDATE DATA KE SERVER
    const formSubmitHandler = (values) => {
      values.harga_satuan_prelim = values.harga_satuan_prelim
        ? RupiahConvert(values.harga_satuan_prelim).default
        : 0;

      // SEND UPDATED DATA TO SERVER
      HargaSatuanPrelimApi.update(values)
        .then(() => {
          // SUCCESS ALERT
          setAlertConfig({
            variant: 'primary',
            text: 'Ubah data berhasil!',
          });
        })
        .catch((err) => {
          // ERROR ALERT
          setAlertConfig({
            variant: 'danger',
            text: `Ubah data gagal! (${err.response.data.message})`,
          });
        })
        .finally(() => {
          // CLOSE UPDATE MODAL FORM
          setModal({
            ...modal,
            show: false,
          });
          // SHOW ALERT
          setShowAlert(true);
          // FETCH DATA DARI SERVER
          getData();
        });
    };

    return (
      <Modal
        show={modal.show}
        onHide={() =>
          setModal({
            ...modal,
            show: false,
          })
        }
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-success">
            <h6 className="mb-0">Ubah Data {title}</h6>
          </Modal.Title>
        </Modal.Header>

        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Row>
                  <Col md={3}>
                    <small>Kode Item</small>
                  </Col>
                  <Col md={9}>
                    <small>: {modal.data.kode_item ?? '-'}</small>
                  </Col>

                  <Col md={3}>
                    <small>Item Prelim</small>
                  </Col>
                  <Col md={9}>
                    <small>: {modal.data.nama_item ?? '-'}</small>
                  </Col>

                  <Col md={3}>
                    <small>Kelompok Prelim</small>
                  </Col>
                  <Col md={9}>
                    <small>: {modal.data.nama_kelompok ?? '-'}</small>
                  </Col>

                  <Col md={3}>
                    <small>Satuan Pakai</small>
                  </Col>
                  <Col md={9}>
                    <small>: {modal.data.nama_satuan ?? '-'}</small>
                  </Col>

                  <Col md={3}>
                    <small>Harga Satuan Prelim</small>
                  </Col>
                  <Col md={9}>
                    <small>
                      :{' '}
                      {modal.data.harga_satuan_prelim
                        ? RupiahConvert(
                            String(parseInt(modal.data.harga_satuan_prelim))
                          ).detail
                        : 'Rp 0'}
                    </small>
                  </Col>

                  <Col md={3}>
                    <small>Tanggal Update Terakhir</small>
                  </Col>
                  <Col md={9}>
                    <small>
                      :{' '}
                      {modal.data.updated_at
                        ? DateConvert(new Date(modal.data.updated_at)).detail
                        : '-'}
                    </small>
                  </Col>
                </Row>
                <hr></hr>

                <Input
                  label="Harga Satuan Prelim Terbaru"
                  type="text"
                  name="harga_satuan_prelim"
                  value={values.harga_satuan_prelim}
                  autoComplete="off"
                  onChange={(e) => {
                    let nilai = e.target.value;
                    let number = nilai.replace(/[^,\d]/g, '').toString();
                    let convert = RupiahConvert(number).detail;
                    setFieldValue('harga_satuan_prelim', convert);
                  }}
                  error={
                    errors.harga_satuan_prelim &&
                    touched.harga_satuan_prelim &&
                    true
                  }
                  errorText={errors.harga_satuan_prelim}
                />
              </Modal.Body>

              <Modal.Footer>
                <ActionButton
                  type="submit"
                  variant="primary"
                  text="Ubah"
                  className="mt-2 px-4"
                  loading={isSubmitting}
                />
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </Modal>
    );
  };

  // TABLE COMPONENT
  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <Th>Kode Item</Th>
              <Th>Item Prelim</Th>
              <Th>Nama Kelompok</Th>
              <Th>Satuan Pakai</Th>
              <Th>Harga Satuan Prelim</Th>
              <Th>Tgl. Update Terakhir</Th>
              {!isGuest && <Th>Aksi</Th>}
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => {
              return (
                <Tr key={val.id_item_buaso}>
                  <TdFixed>{TableNumber(page, dataLength, index)}</TdFixed>
                  <Td className="text-center">{val.kode_item ?? '-'}</Td>
                  <Td>{val.nama_item ?? '-'}</Td>
                  <Td>{val.nama_kelompok ?? '-'}</Td>
                  <Td>{val.nama_satuan ?? '-'}</Td>
                  <Td className="text-right text-nowrap">
                    {val.harga_satuan_prelim
                      ? RupiahConvert(String(parseInt(val.harga_satuan_prelim)))
                          .detail
                      : 'Rp 0'}
                  </Td>
                  <TdFixed>
                    {val.updated_at
                      ? DateConvert(new Date(val.updated_at)).defaultDMY
                      : '-'}
                  </TdFixed>
                  {!isGuest && (
                    <TdFixed>
                      <ActionButton
                        type="button"
                        variant="primary"
                        size="sm"
                        text="Update"
                        onClick={() =>
                          setModal({
                            show: true,
                            data: val,
                          })
                        }
                        className="flex"
                      />
                    </TdFixed>
                  )}
                </Tr>
              );
            })}
          </TBody>
        </CRUDLayout.Table>
        <Pagination
          dataLength={dataLength}
          dataNumber={page * dataLength - dataLength + 1}
          dataPage={page * dataLength}
          dataCount={dataCount}
          onDataLengthChange={(e) => {
            setPage(1);
            setDataLength(e.target.value);
          }}
          currentPage={page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) => setPage(selected + 1)}
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row>
            <Col md="8">
              <InputSearch
                onChange={(e) => {
                  setKey(e.target.value);
                  setPage(1);
                }}
                onSubmit={(e) => e.preventDefault()}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat Data" />
      ) : !Array.isArray(data) ? (
        <DataStatus text="Tidak dapat memuat data" />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      {/* MODAL */}
      <UbahModal />
    </CRUDLayout>
  );
};

export default HargaPerkiraanSendiri;
