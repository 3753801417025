import { Fragment, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IoCheckmark, IoCheckmarkDone } from 'react-icons/io5';

export const ItemPermintaanCollapse = ({ data = [] }) => {
  const [isMore, setMore] = useState(false);

  const splicedData = [...data].splice(0, 2);

  const results = isMore ? data : splicedData;

  const textItem = (v) => {
    const toFloat = (number) => parseFloat(number);

    return `${v.nama_item ?? v.nama_item_aset ?? v.uraian_pekerjaan} 
    ${toFloat(v.qty)} 
    ${v.nama_satuan ?? ''}`;
  };

  return (
    <Fragment>
      <ul className="pl-3 mb-1" style={{ fontSize: '12px' }}>
        {results?.map((item, index) => (
          <li key={index} index={index}>
            <div className="d-flex">
              <span>{textItem(item)}</span>
              <CustomTick
                statusPermintaan={item.status_permintaan ?? 'waiting'}
              />
            </div>
          </li>
        ))}
      </ul>

      {data.length > 2 && (
        <u
          className="text-primary"
          style={{
            cursor: 'pointer',
          }}
          onClick={() => setMore((prev) => !prev)}
        >
          {isMore ? 'Sembunyikan' : 'Selengkapnya...'}
        </u>
      )}
    </Fragment>
  );
};

export const CustomTick = ({ statusPermintaan }) => {
  if (statusPermintaan === 'waiting') {
    return null;
  }

  const onProgress = {
    icon: <IoCheckmark />,
    bgColor: 'bg-warning',
    toolitpText: 'On Progress',
  };

  const done = {
    icon: <IoCheckmarkDone />,
    bgColor: 'bg-success',
    toolitpText: 'Done',
  };

  const itemConf = statusPermintaan === 'done' ? done : onProgress;

  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={<Tooltip>{itemConf.toolitpText}</Tooltip>}
    >
      <div
        className={`${itemConf.bgColor} d-flex justify-content-center align-items-center`}
        style={{
          margin: '0.5px 5px',
          borderRadius: '20%',
          minWidth: 15,
          maxHeight: 15,
        }}
      >
        {itemConf.icon}
      </div>
    </OverlayTrigger>
  );
};
