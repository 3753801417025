import { Fragment, useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ActionButton } from 'components';
import { TransferUpahProduksiContext } from '../Context';
import { TablePermintaan } from './TablePermintaan';
import { TableTransfer } from './TableTransfer';

export const TabLabourCost = () => {
  const { dataPermintaan, dataTransfer, dataHistory, setModalForm } =
    useContext(TransferUpahProduksiContext);

  const showModalKontrak = () =>
    setModalForm((prev) => ({
      ...prev,
      action: 'create',
      tab: 'lc',
      showRegistrasi: true,
    }));

  return (
    <Fragment>
      <Row className="d-flex justify-content-between align-items-end mb-2">
        <Col>
          <small>List Data Permintaan Labour Cost</small>
        </Col>
        <Col className="d-flex justify-content-end align-items-end">
          <ActionButton
            size="sm"
            text="Buat Kontrak"
            onClick={showModalKontrak}
          />
        </Col>
      </Row>

      <TablePermintaan tab="lc" data={dataPermintaan?.lc ?? []} />

      <small className="mt-2">List Data Transfer Upah Produksi</small>
      <TableTransfer tab="lc" tipe="editable" data={dataTransfer.lc ?? []} />

      <small className="mt-2">History Transfer Upah Produksi</small>
      <TableTransfer tab="lc" tipe="readonly" data={dataHistory?.lc ?? []} />
    </Fragment>
  );
};
