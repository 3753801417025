import { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { Row, Col, Spinner } from 'react-bootstrap';
import {
  CRUDLayout,
  InputSearch,
  CreateButton,
  Alert,
  DataStatus,
  FilterButton,
  ReadButton,
  DeleteButton,
  ApprovalStatusButton,
  THead,
  Tr,
  ThFixed,
  Th,
  Td,
  TBody,
  Pagination,
  ActionButton,
} from 'components';
import { TableNumber, DateConvert, RupiahConvert } from 'utilities';
import {
  getSingleBeforeUpdate,
  mapDataonSubmitUpdate,
  submitImages,
  tglInputFormat,
} from './Helpers';
import { SeleksiVendorApi } from 'api';
import { useSessionStorage } from 'hooks';
import {
  ModalDetailSeleksiVendor,
  ModalFilterSeleksiVendor,
  ModalItemSeleksiVendor,
} from './Components';
import { withDropdownSeleksiVendorProvider } from './Context';
import { AiOutlineEdit } from 'react-icons/ai';

const SeleksiVendorList = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const PAGINATION_KEY = `${location.pathname}_pagination`;

  const [isPageLoading, setPageLoading] = useState(true);
  const [dataSeleksiVendor, setDataSeleksiVendor] = useState([]);
  const [modalDetail, setModalDetail] = useState({
    show: false,
    id_seleksi_vendor: null,
  });
  const [modalForm, setModalForm] = useState({
    show: false,
    data: null,
  });
  const [modalFilter, setModalFilter] = useState(false);
  const [pagination, setPagination] = useSessionStorage(PAGINATION_KEY, {
    q: '',
    page: '1',
    per_page: '10',
    data_count: '0',
    total_page: '1',

    active: false,
    created_at_start: undefined,
    created_at_end: undefined,
    tgl_seleksi_vendor_start: undefined,
    tgl_seleksi_vendor_end: undefined,
    tgl_purchase_request_start: undefined,
    tgl_purchase_request_end: undefined,
    id_item_buaso: undefined,
    id_vendor: undefined,
    status_approval: undefined,
  });

  const { data_count, total_page, ...deps } = pagination;

  const [alert, setAlert] = useState({
    variant: 'primary',
    text: '',
  });

  const searchHandler = (e) => {
    const searchKey = e.target.value;

    setPagination({
      ...pagination,
      q: searchKey,
      page: '1',
    });
  };

  const getInitialData = () => {
    setPageLoading(true);

    const query = { ...pagination };

    ['data_count', 'total_page', 'active'].forEach((key) => delete query[key]);

    SeleksiVendorApi.get(query)
      .then((res) => {
        setDataSeleksiVendor(res.data.data ?? []);
        setPagination((prev) => ({
          ...prev,
          data_count: res.data.data_count,
          total_page: res.data.total_page,
        }));
      })
      .catch(() => setAlert({ text: 'Data gagal dimuat', variant: 'danger' }))
      .finally(() => setPageLoading(false));
  };

  const checkAlert = () => {
    const alertState = location?.state?.alert;
    if (alertState) {
      setAlert({ text: alertState.text, variant: alertState.variant });
    }
  };

  useEffect(() => getInitialData(), [JSON.stringify(deps)]);

  useEffect(() => {
    setNavbarTitle('Seleksi Vendor');
    checkAlert();
  }, []);

  return (
    <CRUDLayout>
      <Row className="d-flex align-items-center mb-2">
        <Col md="10" className="d-flex">
          <InputSearch
            defaultValue={pagination.q}
            onChange={debounce(searchHandler, 1500)}
          />
          <FilterButton
            active={pagination.active}
            onClick={() => setModalFilter({ show: true })}
          />
        </Col>

        <Col className="d-flex justify-content-end align-items-end">
          <CreateButton
            onClick={() =>
              history.push('/transaksi/seleksi-vendor/purchase-request')
            }
          />
        </Col>
      </Row>
      <Alert
        showCloseButton
        className="mt-2"
        show={!!alert?.text?.length}
        variant={alert.variant}
        text={alert.text}
        onClose={() => setAlert((prev) => ({ ...prev, text: '' }))}
      />
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : dataSeleksiVendor.length > 0 ? (
        <TableContent
          pagination={pagination}
          data={dataSeleksiVendor}
          setPagination={setPagination}
          setModalDetail={setModalDetail}
          modalForm={modalForm}
          setModalForm={setModalForm}
        />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      {modalFilter && (
        <ModalFilterSeleksiVendor
          show={modalFilter}
          setShow={setModalFilter}
          filter={pagination}
          setFilter={setPagination}
        />
      )}

      <ModalDetailSeleksiVendor
        id_seleksi_vendor={modalDetail.id_seleksi_vendor}
        show={modalDetail.show}
        onHide={() => setModalDetail({ show: false, id: null })}
      />

      <ModalItemSeleksiVendor
        action="update"
        type="form"
        show={modalForm.show}
        data={modalForm.data}
        setModal={setModalForm}
        onSubmitModalItem={async (values) => {
          const path_gambar = await submitImages(values.images);
          const finalValue = mapDataonSubmitUpdate({ ...values, path_gambar });

          await SeleksiVendorApi.update(finalValue)
            .then(() =>
              setAlert({ text: 'Berhasil update data', variant: 'primary' })
            )
            .catch(({ response }) =>
              setAlert({
                text: `Gagal update data ${response?.data?.message ?? ''}`,
                variant: 'danger',
              })
            )
            .finally(() => {
              getInitialData();
              setModalForm({ show: false, data: null });
            });
        }}
      />
    </CRUDLayout>
  );
};

const TableContent = ({
  data,
  pagination,
  setPagination,
  setModalDetail,
  modalForm,
  setModalForm,
}) => {
  return (
    <>
      <div className="font-weight-bold mt-2" style={{ fontSize: '.9rem' }}>
        List Data Seleksi Vendor
      </div>

      <CRUDLayout.Table>
        <THead>
          <Tr className="text-center">
            <ThFixed>No.</ThFixed>
            <ThFixed>Aksi</ThFixed>
            <ThFixed>
              <div className="text-nowrap">Tgl. Input Data</div>
            </ThFixed>
            <ThFixed>Informasi Seleksi Vendor</ThFixed>
            <ThFixed>Informasi Purchase Request</ThFixed>
            <Th style={{ minWidth: 300 }}>Item Barang</Th>
            <Th style={{ minWidth: 300 }}>Vendor</Th>
            <ThFixed>
              <div className="text-nowrap">
                Harga Satuan <br /> Kesepakatan
              </div>
            </ThFixed>
            <ThFixed>Total Harga</ThFixed>
            <ThFixed>Status Approval</ThFixed>
          </Tr>
        </THead>

        <TBody>
          {data?.length > 0 ? (
            data?.map((val, index) => {
              const tglInput = tglInputFormat({ created_at: val.created_at });

              const totalHarga = String(
                Math.round(
                  parseFloat(val.qty_order ?? 0) *
                    parseFloat(val.harga_kesepakatan ?? 0)
                ) ?? 0
              );

              return (
                <Tr key={val.id_seleksi_vendor_detail}>
                  <Td className="text-center">
                    {TableNumber(pagination.page, pagination.per_page, index)}
                  </Td>

                  <Td>
                    <div className="d-flex flex-row">
                      <ReadButton
                        onClick={() =>
                          setModalDetail({
                            show: true,
                            id_seleksi_vendor: val.id_seleksi_vendor,
                          })
                        }
                      />

                      {(val.status_approval === 'REV' ||
                        val.status_approval === null) && (
                        <ActionButton
                          size="sm"
                          className="btn btn-success m-1"
                          disable={modalForm.isLoading}
                          onClick={() =>
                            getSingleBeforeUpdate(
                              val.id_seleksi_vendor,
                              setModalForm
                            )
                          }
                        >
                          <div className="d-flex justify-content-center align-items-center">
                            {modalForm.isLoading ? (
                              <Spinner
                                animation="border"
                                variant="white"
                                size="sm"
                              />
                            ) : (
                              <AiOutlineEdit />
                            )}
                          </div>
                        </ActionButton>
                      )}

                      {val.deleteable && <DeleteButton onClick={() => {}} />}
                    </div>
                  </Td>

                  <Td className="text-left text-nowrap">
                    <div>{tglInput.date}</div>
                    <div>{tglInput.time}</div>
                  </Td>

                  <Td>
                    <div>
                      {val.tgl_seleksi_vendor
                        ? DateConvert(new Date(val.tgl_seleksi_vendor))
                            .defaultDMY
                        : '-'}
                    </div>
                    <div>{val.no_seleksi_vendor}</div>
                  </Td>

                  <Td>
                    <div>
                      {val.tgl_purchase_request
                        ? DateConvert(new Date(val.tgl_purchase_request))
                            .defaultDMY
                        : '-'}
                    </div>
                    <div>{val.no_purchase_request ?? '-'}</div>
                  </Td>

                  <Td>
                    {`${val.nama_item ?? ''} ${parseFloat(
                      val.qty_order ?? 0
                    )} ${val.satuan_beli ?? ''}
                    `}
                  </Td>

                  <Td>{val.nama_vendor ?? '-'}</Td>

                  <Td textRight>
                    {
                      RupiahConvert(val.harga_kesepakatan ?? '0')
                        .getWithComaLabel
                    }
                  </Td>

                  <Td textRight>
                    {RupiahConvert(totalHarga).getWithComaLabel}
                  </Td>

                  <Td>
                    <ApprovalStatusButton
                      status={val.status_approval}
                      defaultStatus="PEN"
                    />
                  </Td>
                </Tr>
              );
            })
          ) : (
            <Tr>
              <Td colSpan={10}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </Tr>
          )}
        </TBody>
      </CRUDLayout.Table>

      <Pagination
        dataLength={pagination?.per_page}
        dataCount={pagination?.data_count}
        currentPage={pagination?.page}
        totalPage={pagination?.total_page}
        dataNumber={
          pagination?.page * pagination?.per_page - pagination?.per_page + 1
        }
        dataPage={
          pagination?.data_count < pagination?.per_page
            ? pagination?.data_count
            : pagination?.page * pagination?.per_page
        }
        onPaginationChange={({ selected }) =>
          setPagination((prev) => ({
            ...prev,
            page: selected + 1,
          }))
        }
        onDataLengthChange={(e) =>
          setPagination((prev) => ({
            ...prev,
            page: 1,
            per_page: e.target.value,
          }))
        }
      />
    </>
  );
};

export default withDropdownSeleksiVendorProvider(SeleksiVendorList);
