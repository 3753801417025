export const textFormatKontrakTop = ({
  no,
  date,
  name,
  position,
  address,
  vendorName,
  vendorPosition,
  vendorAddress,
  item,
}) => {
  return `
    <p style="text-align:center;">
      <ins>SURAT PERINTAH KERJA</ins>
    </p>
    <p>No. Kontrak : ${no ?? ''}</p>
    <p>Pada hari ini, <strong>${date ?? ''}</strong>. </p>
    <p>Yang bertanda tangan dibawah ini : <br>Nama : <strong>${
      name ?? ''
    }</strong>
      <br>Jabatan : <strong>${position ?? ''}</strong>
      <br>Alamat : <strong>${address ?? ''}</strong>
      <br>Dalam hal ini bertindak untuk dan atas PT MARDIKA GRIYA PRASTA, yang selanjutnya disebut sebagai <strong>PIHAK PERTAMA</strong>
    </p>
    <p>Dengan ini memerintahkan kepada : <br>Nama : <strong>${
      vendorName ?? ''
    }</strong>
      <br>Jabatan : <strong>${vendorPosition ?? ''}</strong>
      <br>Alamat : <strong>${vendorAddress ?? ''}</strong>
      <br>Dalam hal ini bertindak untuk dan atas NAMA PRIBADI, yang selanjutnya disebut sebagai <strong>PIHAK KEDUA</strong>
    </p>
    <p style="margin-bottom: 0">Untuk melaksanakan pengadaan barang sebagai berikut : <br>a. Jenis Barang : <strong>${
      item ?? ''
    }</strong><br>b. Uraian Pengadaan :
    </p>
  `;
};

export const textFormatKontrakMiddle = ({ price, vendorName, date }) => {
  return `
    <p>c. Jumlah Harga : ${
      price ? `${price},-` : ''
    } tidak termasuk PPn 10%.</p>
    <p>d. Harga tersebut adalah <strong>Franco Gudang ${
      vendorName ?? ''
    }</strong>, berdasarkan penawaran harga negosiasi <strong>PIHAK KEDUA</strong> Melalui Surat Penawaran tertanggal ${
    date ?? ''
  } dan sesuai dengan persyaratan Umum Surat Perintah Kerja yang tercantum dibalik ini kecuali dinyatakan lain secara tertulis. </p>
    <p>e. <strong>PIHAK KEDUA</strong> harus bertanggung jawab penuh atas hasil pengadaan barang dalam keadaan yang sesuai dengan kriteria <strong>PIHAK PERTAMA</strong>, kecuali disebabkan oleh tindakan atau kelalaian <strong>PIHAK PERTAMA</strong>, maka <strong>PIHAK KEDUA</strong> atas biayanya sendiri wajib mengadakan perbaikan serta mengganti pengadaan barang tersebut tanpa mempengaruhi jadwal penyelesaian atau mengganggu pelaksanaan pengadaan. Termasuk yang menjadi tanggung jawab <strong>PIHAK KEDUA</strong> adalah : <br>Segala akibat yang ditimbulkan oleh pengadaan ini menjadi tanggungjawab <strong> PIHAK KEDUA.</strong><br>Biaya-biaya yang timbul diluar item pengadaan yang dikontrakkan, sepenuhnya menjadi tanggung jawab <strong>PIHAK KEDUA</strong>.</p>
    <p>f. Pelaksanaan pengadaan barang tersebut harus selesai tepat waktu sesuai dengan schedule yang telah diberikan /dijelaskan oleh PIHAK PERTAMA, kecuali bilamana terjadi keterlambatan diluar kesalahan PIHAK KEDUA.</p>
    <p>g. Kerugian-kerugian yang timbul karena keterlambatan atau karena kesalahan PIHAK KEDUA adalah tanggung jawab dan menjadi beban PIHAK KEDUA sepenuhnya.</p>
  `;
};

export const textFormatKontrakBottom = () => {
  return `
    <p style="text-align:center;">
      <strong>PERSYARATAN UMUM SURAT PERINTAH KERJA</strong>
    </p>
    <p style="text-align:center;">
      <strong>Pasal 1. RUANG LINGKUP PENGADAAN BARANG</strong>
    </p>
    <ol>
      <li>Lingkup Pengadaan barang adalah sebagai berikut :</li>
      <ol>
        <li>Mendatangkan alat sesuai dengan spesifikasi yang telah disetujui.</li>
        <li>Mendatangkan tenaga kerja yang terampil yang memenuhi syarat untuk mendukung proses pengadaan barang tersebut.</li>
        <li><strong>PIHAK KEDUA</strong> wajib menempatkan seorang wakilnya/Supevisor yang dipercaya dan bertanggung jawab terhadap seluruh proses pengadaan dan setiap hari ada dilokasi proyek sesuai kebutuhan.</li>
        <li>Metode pengadaan harus atas persetujuan <strong>PIHAK PERTAMA</strong>, termasuk metode aplikasi yang disesuaikan dengan standar IK dari <strong>PIHAK PERTAMA</strong>. </li>
      </ol>
      <li><strong>PIHAK KEDUA</strong> harus menyediakan hal-hal sebagai berikut :</li>
      <ol>
        <li>Segala kebutuhan konsumsi tenaga kerja selama pelaksanaan pengadaan tersebut.</li>
        <li>Keamanan, Keselamatan dan Kebersihan (K-3) tenaga kerja. <br>Meliputi : <br>- Penyediaan alat pelindung diri bagi pekerja <br>- Adanya jaminan penanganan kecelakaan kerja </li>
        <li>Kipem/Kartu Tanda Penduduk untuk pendatang.</li>
        <li>Alat bantu pengadaan barang.</li>
        <li>Untuk biaya biaya antara lain : <br>Transportasi tenaga kerja ke lokasi dan luar lokasi proyek, konsumsi lembur tenaga kerja, dan lembur tenaga menjadi tanggung jawab <strong>PIHAK KEDUA</strong>. </li>
      </ol>
    </ol>
    <p style="text-align:center;">
      <strong>Pasal 2. SIFAT KONTRAK</strong>
    </p>
    <ol>
      <li>Perjanjian ini bersifat Unit Price sesuai dimaksud dari kesepakatan final penawaran harga dengan volume yang dibayar adalah sesuai dengan volume yang terpasang di lapangan.</li>
      <li>Harga Satuan adalah tetap dan tidak dapat berubah jika terjadi perubahan harga dari bahan baku, bahan bakar, tarif listrik, air, telpon, angkutan, pajak-pajak dan cukai, asuransi, upah kerja atau naik turunnya nilai tukar mata uang. Kecuali ada perubahan dari konsultan maka akan di charger sesuai perubahan.</li>
      <li>
        <strong>PIHAK KEDUA</strong> sudah memperhitungkan biaya non teknis dan sudah termasuk dalam penawaran.
      </li>
    </ol>
    <p style="text-align:center;">
      <strong>Pasal 3. WAKTU PELAKSANAAN PENGADAAN BARANG</strong>
    </p>
    <p><strong>PIHAK KEDUA</strong> akan memulai pengadaan menyesuaikan kondisi site dan akan menyelesaikan pengadaan barang tersebut sesuai jadwal yang diberikan <strong>PIHAK PERTAMA</strong>, dan jumlah tenaga disesuaikan dengan waktu yang telah disepakati. <strong>PIHAK KEDUA</strong> sudah harus menyelesaikan Pengadaan barang sesuai schedule terlampir yang telah disepakati <strong>PARA PIHAK</strong>.</p>
    <p style="text-align:center;">
      <strong>Pasal 4. CARA PEMBAYARAN</strong>
    </p>
    <p><strong>PIHAK PERTAMA</strong> akan membayar <strong>PIHAK KEDUA</strong> sebagai berikut :</p>
    <ol>
      <li><strong>Uang Muka sebesar 30% ( Tiga puluh persen )</strong> dari nilai pengadaan barang dibayarkan dalam waktu 14 (Empat Belas) hari kalender setelah <strong>Surat Perintah Kerja</strong> ini ditandatangani dan <strong>PIHAK KEDUA</strong>.</li>
      <li>Pembayaran untuk kemajuan pengadaan barang yang telah diselesaikan oleh <strong>PIHAK KEDUA </strong>dilakukan berdasarkan hasil kerja off site dan on site setelah <strong>PIHAK KEDUA</strong> mengajukan semua dokumen tagihan lengkap dan benar yang diperlukan untuk perhitungan jumlah pembayaran kepada <strong>PIHAK PERTAMA</strong> dikurangi dengan retensi sebesar 5%. </li>
      <li>Pekerjaan Off site yang sudah selesai di pabrik (terfinishing) dapat diprogres 35% </li>
      <li>Barang terpasang di site dapat diprogres 30%</li>
      <li>Retensi sebesar 5 ( Lima ) persen dari nilai pengadaan, dibayarkan 2,5 % setelah <strong>PIHAK KEDUA</strong> melakukan perbaikan atas defect list oleh <strong>PIHAK PERTAMA</strong> ( BAST I ), dan 2,5 % dibayarkan setelah <strong>PIHAK KEDUA</strong> telah memperbaiki semua pengadaan cacat sesuai defect list dan dapat diterima <strong>PIHAK PERTAMA</strong> ( BAST II ). </li>
      <li>Lama waktu pembayaran 14 (empat belas) hari kalender sejak <strong>PIHAK KEDUA</strong> mengajukan berkas penagihan (opname) lengkap dan benar kepada bagian keuangan <strong>PIHAK PERTAMA.</strong>
      </li>
    </ol>
    <p style="text-align:center;">
      <strong>Pasal 5. SANKSI DAN DENDA</strong>
    </p>
    <ol>
      <li>Jika <strong>PIHAK KEDUA</strong> tidak dapat menyelesaikan pengadaan barang sesuai dengan jadwal yang telah disetujui akibat kelalaian <strong>PIHAK KEDUA</strong>, maka untuk setiap hari keterlambatan, <strong>PIHAK KEDUA</strong> wajib membayar denda keterlambatan sebesar 1 ‰ (satu permil) perhari keterlambatan dengan maksimal denda sebesar 5% (lima persen) dari nilai pengadaan barang dan jasa sebagaimana yang tercantum pada pasal 4 perjanjian ini. </li>
      <li>Denda–denda tersebut dalam ayat 1 Pasal ini, akan diperhitungkan dengan kewajiban pembayaran <strong>PIHAK PERTAMA</strong> kepada <strong>PIHAK KEDUA</strong>. </li>
      <li>Dalam hal denda keterlambatan tersebut diatas melebihi 5%, dari Nilai pengadaan barang maka <strong>PIHAK PERTAMA</strong> berhak untuk mengakhiri Perjanjian ini secara sepihak namun tidak membebaskan <strong>PIHAK KEDUA</strong> dari tanggung jawab &amp; kewajiban <strong>PIHAK KEDUA</strong> atas pengadaan barang lain yang tidak dibatalkan oleh <strong>PIHAK PERTAMA</strong>. </li>
      <li>Atas pembatalan tersebut diatas <strong>PIHAK PERTAMA</strong> berhak untuk mengalihkan dan menunjuk Pihak Lain untuk menyelesaikan sisa pengadaan barang <strong>PIHAK KEDUA</strong> yang belum diperbaiki dengan biaya dari <strong>PIHAK KEDUA</strong>, yang dipotong dari sisa pembayaran yang seharusnya menjadi hak <strong>PIHAK KEDUA </strong>dan apabila masih terdapat kekurangan biaya maka menjadi beban <strong>PIHAK KEDUA</strong>. </li>
      <li>Apabila <strong>PIHAK KEDUA</strong> tidak dapat menyelesaikan pengadaan barang pada waktu yang telah ditentukan dan tidak dapat mengikuti syarat-syarat Perjanjian ini, maka <strong>PIHAK PERTAMA</strong> berhak membatalkan Perjanjian ini tanpa memberi ganti rugi dalam bentuk apapun dan berapapun besarnya kepada <strong>PIHAK KEDUA</strong>. </li>
      <li>Apabila <strong>PIHAK PERTAMA</strong> memutuskan hubungan dengan <strong>PIHAK KEDUA</strong> oleh karena kelalaian dari <strong>PIHAK KEDUA</strong> maka <strong>PIHAK KEDUA</strong> wajib menyerahkan data-data administrasi dan data-data laporan atas pengadaan barang yang sudah dilaksanakan dan diserah terimakan kepada <strong>PIHAK PERTAMA</strong>. </li>
    </ol>
    <p style="text-align:center;">
      <strong>Pasal 6. PERSELISIHAN</strong>
    </p>
    <p>Apabila dalam pelaksanaan Perjanjian ini terjadi perselisihan, <strong>PARA PIHAK</strong> sepakat menyelesaikannya melalui musyawarah mufakat. Apabila dalam waktu 30 (tiga puluh) hari kalender belum dapat diselesaikan, <strong>PARA PIHAK</strong> sepakat menyelesaikan melalui Badan Arbitrase Nasional Indonesia (BANI). </p>
    <p style="text-align:center;">
      <strong>Pasal 7. PENUTUP</strong>
    </p>
    <ol>
      <li>Hal-hal yang belum diatur dalam Perjanjian ini dan/atau ketentuan-ketentuan dalam Perjanjian ini mengalami perubahan, <strong>PARA PIHAK</strong> sepakat menuangkan dalam Perjanjian Tambahan (Addendum) yang merupakan bagian yang tidak terpisahkan dari Perjanjian ini. </li>
      <li>Setiap pemberitahuan atau komunikasi lainnya harus diajukan tertulis dan dianggap telah diberikan efektif (i) bila disampaikan dengan surat elektronik atau faksimili, atau jasa kurir, atau (ii) 3 (tiga) hari kalender setelah tanggal cap pos jika dikirimkan dengan surat tercatat, ditujukan kepada pihak yang alamatnya tertulis pada paragraph pertama Perjanjian ini atau ke alamat lainnya yang diberitahukan secara tertulis kepada pihak lain sesuai dengan ketentuan Pasal ini. </li>
    </ol>
<p>Demikian Surat Perjanjian ini dibuat dan ditandatangani oleh Para Pihak dalam rangkap 2 (dua), dengan sadar dan tanpa paksaan dari pihak manapun juga dimana masing - masing pihak telah mengetahui dan memahami isinya serta memiliki kekuatan hukum yang sama&nbsp;</p>
  `;
};
