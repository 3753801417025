import { useContext } from 'react';
import {
  THead,
  Tr,
  Th,
  Td,
  ThFixed,
  TdFixed,
  TBody,
  DataStatus,
  Table,
  SelectSearch,
} from 'components';
import { RupiahConvert } from 'utilities';
import {
  TransferSubkonProduksiContext,
  DropdownTransferSubkonContext,
} from '../Context';
import { calcSubTotal, calcGrandTotal } from '../Helpers/calculation';

export const TablePermintaan = ({ data = [], tipe = 'editable', tab }) => {
  const { dropdownStatusPermintaan } = useContext(
    DropdownTransferSubkonContext
  );

  const { setDataPermintaan } = useContext(TransferSubkonProduksiContext);

  const isReadonly = tipe === 'readonly';

  const onChangeStatusPermintaan = ({ value, index }) =>
    setDataPermintaan((prv) => ({
      ...prv,
      [tab]: prv[tab].toSpliced(index, 1, {
        ...prv[tab][index],
        status_permintaan: value,
      }),
    }));

  return (
    <Table>
      <THead>
        <Tr className="text-center">
          <ThFixed>No.</ThFixed>
          <Th>Uraian Pekerjaan</Th>
          <Th>Qty.</Th>
          <Th>Satuan</Th>
          <Th>Unit Price (Rp)</Th>
          <Th>Konst</Th>
          <Th>Sub Total Price (Rp)</Th>
          {isReadonly ? null : <Th>Status Permintaan</Th>}
        </Tr>
      </THead>

      <TBody>
        {data?.length ? (
          data.map((val, index) => (
            <Tr key={index}>
              <TdFixed className="text-center">{index + 1}</TdFixed>
              <Td>{val?.uraian_pekerjaan ?? '-'}</Td>
              <Td textRight>
                {val?.qty
                  ? `${parseFloat(val.qty)} ${val?.kode_satuan ?? ''}`
                  : '-'}
              </Td>
              <Td>{val?.nama_satuan ?? '-'}</Td>
              <Td textRight>
                {val?.unit_price
                  ? RupiahConvert(parseInt(val.unit_price).toString()).detail
                  : '-'}
              </Td>
              <Td textRight>
                {val?.konstanta ? parseFloat(val.konstanta) : '-'}
              </Td>
              <Td textRight>
                {
                  RupiahConvert(
                    String(
                      calcSubTotal({
                        qty: val.qty,
                        unit_price: val.unit_price,
                        konstanta: val.konstanta,
                      })
                    )
                  ).detail
                }
              </Td>
              {isReadonly ? null : (
                <td className="p-0 align-self-center">
                  <SelectSearch
                    option={dropdownStatusPermintaan}
                    menuShouldBlockScroll={true}
                    menuPosition="fixed"
                    defaultValue={dropdownStatusPermintaan?.find(
                      ({ value }) => value === val.status_permintaan
                    )}
                    onChange={({ value }) =>
                      onChangeStatusPermintaan({ value, index })
                    }
                  />
                </td>
              )}
            </Tr>
          ))
        ) : (
          <Tr>
            <Td colSpan={15}>
              <DataStatus text="Tidak ada data" />
            </Td>
          </Tr>
        )}

        {data?.length ? (
          <Tr>
            <Td colSpan={6} textRight>
              Total Price
            </Td>
            <Td textRight>
              {RupiahConvert(String(calcGrandTotal(data))).detail}
            </Td>
            {isReadonly ? null : <Td />}
          </Tr>
        ) : null}
      </TBody>
    </Table>
  );
};
