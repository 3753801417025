import { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  BackButton,
  ActionButton,
  InfoItemHorizontal,
} from 'components';
import { DateConvert } from 'utilities';
import { SeleksiVendorApi } from 'api';
import {
  TablePurchaseRequest,
  TableSeleksiVendor,
  ModalItemSeleksiVendor,
  ModalKonfirmasiSeleksiVendor,
  ModalAlert,
} from './Components';
import {
  ModalItemSeleksiVendorContext,
  withDropdownSeleksiVendorProvider,
} from './Context';
import {
  createOrUpdateItemSVTable,
  mapDataOnSubmitCreate,
  submitImages,
} from './Helpers';
import { withModalItemSeleksiVendorProvider } from './Context';
import { useMockNumber } from './Hooks/useMockNumber';

const SeleksiVendorTambah = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id_purchase_request } = useParams();
  const { modal, setModal } = useContext(ModalItemSeleksiVendorContext);

  const [data, setData] = useState({});
  const [purchaseRequest, setPurchaseRequest] = useState([]);
  const [historySeleksiVendor, setHistorySeleksiVendor] = useState([]);
  const [generateMockNumber] = useMockNumber(purchaseRequest);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isPageLoading, setPageLoading] = useState(false);
  const [modalConfirm, setModalConfirm] = useState({ show: false });
  const [modalStatus, setModalStatus] = useState({
    show: false,
    type: 'success',
    title: 'Pesan Sistem',
    message: 'Simpan Data Sukses',
  });
  const [alert, setAlert] = useState({
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setPageLoading(true);

    SeleksiVendorApi.getSinglePurchaseRequest({
      id_purchase_request,
    })
      .then((res) => {
        const data = res.data.data ?? {};
        setData(data);
        setPurchaseRequest(data.detail ?? []);
        setHistorySeleksiVendor(
          data.histori.map((h) => ({ ...h, qty_sv: h.qty_order })) ?? []
        );
      })
      .catch(() => setAlert({ text: 'Data gagal dimuat', variant: 'danger' }))
      .finally(() => setPageLoading(false));
  };

  const onSubmitModalItem = (values) =>
    setPurchaseRequest(createOrUpdateItemSVTable(purchaseRequest, values));

  const handleSubmit = async () => {
    setSubmitting(true);

    const filterSubmitData = purchaseRequest?.filter((pr) =>
      Boolean(pr.id_vendor)
    );

    const result = await Promise.all(
      filterSubmitData.map(async (pr) => {
        const resUploadImage = await submitImages(pr.images);

        return { ...pr, path_gambar: resUploadImage };
      })
    );

    const finalValue = mapDataOnSubmitCreate(result);

    SeleksiVendorApi.create(finalValue)
      .then(() => {
        setSubmitting(false);
        setModalConfirm({ show: false });
        setModalStatus({
          show: true,
          type: 'success',
          title: 'Pesan Sistem',
          message: 'Simpan Data Sukses',
        });
      })
      .catch(({ response }) => {
        setSubmitting(false);
        setModalConfirm({ show: false });
        setModalStatus({
          show: true,
          type: 'error',
          title: 'Pesan Sistem',
          message: `Data gagal disimpan! ${response.data.message ?? ''}`,
        });
      });
  };

  useEffect(() => {
    setNavbarTitle('Seleksi Vendor');
    getInitialData();
  }, []);

  return (
    <CRUDLayout>
      <Row className="d-flex align-items-center mb-2">
        <Col className="d-flex justify-content-end align-items-end">
          <BackButton onClick={() => history.goBack()} />
        </Col>
      </Row>

      <Alert
        showCloseButton
        className="mt-2"
        show={!!alert?.length}
        variant={alert.variant}
        text={alert.text}
        onClose={() => setAlert((prev) => ({ ...prev, text: '' }))}
      />

      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : purchaseRequest?.length > 0 ? (
        <Fragment>
          <div className="font-weight-bold mt-2" style={{ fontSize: '.9rem' }}>
            Detail Data Purchase Request
          </div>
          <Card>
            <Card.Body>
              <Row>
                <Col>
                  <InfoItemHorizontal
                    className="text-nowrap mb-1"
                    label="Tgl. Purchase Request"
                    text={
                      data.tgl_purchase_request
                        ? DateConvert(new Date(data.tgl_purchase_request))
                            .detail
                        : '-'
                    }
                  />
                  <InfoItemHorizontal
                    className="mb-1"
                    label="No. Purchase Request"
                    text={data.no_purchase_request}
                  />
                  <InfoItemHorizontal
                    className="mb-1"
                    label="Pengaju Purchase Request"
                    text={data.nama_karyawan_pengaju ?? '-'}
                  />
                  <InfoItemHorizontal
                    className="mb-1"
                    label="Tgl. Pemakaian"
                    text={
                      data.tgl_pemakaian
                        ? DateConvert(new Date(data.tgl_pemakaian)).detail
                        : '-'
                    }
                  />
                </Col>

                <Col>
                  <InfoItemHorizontal
                    className="mb-1"
                    label="Keperluan"
                    text={data.keperluan}
                  />
                  <InfoItemHorizontal
                    className="mb-1"
                    label="Proyek"
                    text={data.nama_proyek}
                  />

                  <InfoItemHorizontal
                    className="text-nowrap"
                    label="Catatan Purchase Request"
                    text={data.catatan_purchase_request}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <TablePurchaseRequest data={purchaseRequest} setModal={setModal} />
          <TableSeleksiVendor
            type="action"
            data={purchaseRequest}
            setData={setPurchaseRequest}
            setModal={setModal}
          />
          <TableSeleksiVendor type="history" data={historySeleksiVendor} />
          <Row className="d-flex justify-content-end align-items-end p-3">
            <ActionButton
              disable={
                !!purchaseRequest.filter((pr) => Boolean(pr.id_vendor)).length <
                1
              }
              text="Simpan"
              onClick={() => setModalConfirm({ show: true })}
            />
          </Row>
        </Fragment>
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      {/* show form to create new / update item seleksi vendor */}
      <ModalItemSeleksiVendor
        action={modal.action}
        type={modal.type}
        show={modal.show}
        data={modal.data}
        setModal={setModal}
        generateMockNumber={generateMockNumber}
        onSubmitModalItem={onSubmitModalItem}
      />

      {/* before submit show modal confirm */}
      <ModalKonfirmasiSeleksiVendor
        show={!modal.show && modalConfirm.show}
        data={data}
        isSubmitting={isSubmitting}
        purchaseRequest={purchaseRequest}
        onHide={() => setModalConfirm({ show: false })}
        onSubmitConfirm={handleSubmit}
      />

      {/* after submit show modal status */}
      <ModalAlert
        modalStatus={modalStatus}
        onModalClose={() =>
          setModalStatus((prev) => ({ ...prev, show: false }))
        }
        onSuccessClose={() => {
          setModalStatus((prev) => ({ ...prev, show: false }));
          history.push('/transaksi/seleksi-vendor');
        }}
      />
    </CRUDLayout>
  );
};

export default withDropdownSeleksiVendorProvider(
  withModalItemSeleksiVendorProvider(SeleksiVendorTambah)
);
