import Services from '../../../services';
import UploadServices from 'services/UploadService';

class SeleksiVendorApi {
  get(params) {
    return Services.get('/seleksi_vendor/page', { params });
  }

  getSingle(params) {
    return Services.get('/seleksi_vendor/single', { params });
  }

  getPagePurchaseRequest(params) {
    return Services.get('/seleksi_vendor/purchase_request', { params });
  }

  getSinglePurchaseRequest(params) {
    return Services.get('/seleksi_vendor/detail_pr', { params });
  }

  getNomor(params) {
    return Services.get('/seleksi_vendor/no_baru', { params });
  }

  create(data) {
    return Services.post('/seleksi_vendor', data);
  }

  update(data) {
    return Services.put('/seleksi_vendor', data);
  }

  uploadMultipleFiles(data) {
    return UploadServices.post('/upload/seleksi_vendor_multiple', data);
  }

  dropdown(params) {
    return Services.get('/seleksi_vendor/dropdown_filter_page', { params });
  }

  dropdownPreform(params) {
    return Services.get('/seleksi_vendor/dropdown_filter_pre_form', { params });
  }

  delete(data) {
    return Services.post('/seleksi_vendor/delete', data);
  }
}

export default new SeleksiVendorApi();
