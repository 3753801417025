import { Fragment, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Axios from 'axios';
import {
  ActionButton,
  BackButton,
  Alert,
  DataStatus,
  Approval,
} from 'components';
import { PurchaseOrderApi } from 'api';
import {
  FormSection,
  InformasiVendorSection,
  TableListPurchaseOrder,
} from './Components';
import {
  DropdownPurchaseOrderContext,
  withDropdownPurchaseOrderContextProvider,
} from './Contexts';

const UbahPurchaseOrder = withDropdownPurchaseOrderContextProvider(
  ({ setNavbarTitle }) => {
    const history = useHistory();
    const location = useLocation();
    const { id_purchase_order } = useParams();
    const { id_vendor } = location.state;
    const { setDropdownPembayaran, dropdownPembayaran } = useContext(
      DropdownPurchaseOrderContext
    );

    const [isPageLoading, setIsPageLoading] = useState(false);
    const [isFetchingFailed, setIsFetchingFailed] = useState(false);
    const [dataPurchaseOrder, setDataPurchaseOrder] = useState({
      info: {},
      detail: [],
    });
    const [alertConfig, setAlertConfig] = useState({
      show: false,
      variant: 'primary',
      text: '',
    });

    const getInitialData = () => {
      setIsPageLoading(true);

      Axios.all([
        PurchaseOrderApi.getSingle({ id_purchase_order }),
        PurchaseOrderApi.getSingleVendor({ id_vendor }),
      ])
        .then(
          Axios.spread((single, singleVendor) => {
            const data = single.data.data ?? {};
            const dataSv = singleVendor.data.data ?? {};

            const { po_detail, ...info } = data;
            const { detail: listSV } = dataSv;

            const detail = listSV.map((item) => ({
              ...item,
              checked:
                po_detail.find(
                  (d) =>
                    d.id_seleksi_vendor_detail === item.id_seleksi_vendor_detail
                ) ?? false,
            }));

            if (!dropdownPembayaran.includes(info.pembayaran)) {
              setDropdownPembayaran((prev) =>
                prev.toSpliced(0, 0, {
                  value: info.pembayaran,
                  label: info.pembayaran,
                })
              );
            }

            setDataPurchaseOrder({ info, detail });
          })
        )
        .catch(() => setIsFetchingFailed(true))
        .finally(() => setIsPageLoading(false));
    };

    useEffect(() => {
      setNavbarTitle('Purchase Order');
      getInitialData();

      return () => setIsPageLoading(false);
    }, []);

    const initialValues = {
      id_purchase_order,
      id_vendor,
      no_purchase_order: dataPurchaseOrder.info.no_purchase_order,
      tgl_purchase_order: dataPurchaseOrder.info.tgl_purchase_order,
      tgl_pengiriman: dataPurchaseOrder.info.tgl_pengiriman,
      tgl_jatuh_tempo: dataPurchaseOrder.info.tgl_jatuh_tempo,
      nama_vendor: dataPurchaseOrder.info.nama_vendor,
      kode_vendor: dataPurchaseOrder.info.kode_vendor,
      alamat_tujuan_pengiriman:
        dataPurchaseOrder.info.alamat_tujuan_pengiriman ?? '',
      alamat_vendor: dataPurchaseOrder.info.alamat_vendor,
      contact_person: dataPurchaseOrder.info.contact_person,
      keterangan: dataPurchaseOrder.info.keterangan ?? '',
      diskon: dataPurchaseOrder.info.diskon ?? '0',
      ppn: dataPurchaseOrder.info.ppn ?? '0',
      dp: dataPurchaseOrder.info.dp ?? '0',
      pembayaran: dataPurchaseOrder.info.pembayaran ?? '',
      detail: dataPurchaseOrder?.detail ?? [],
    };

    const validationSchema = Yup.object().shape({
      tgl_purchase_order: Yup.string().required('Pilih tanggal purchase order'),
      alamat_tujuan_pengiriman: Yup.string().required(
        'Masukan alamat tujuan pengiriman'
      ),
      pembayaran: Yup.string().required('Masukan Pembayaran'),
    });

    const onSubmit = (values, { setSubmitting }) => {
      const finalValue = {
        ...values,
        tgl_purchase_order: values.tgl_purchase_order,
        tgl_pengiriman: values.tgl_pengiriman,
        tgl_jatuh_tempo: values.tgl_jatuh_tempo,
        ppn: values?.ppn ?? 0,
        diskon: values.diskon ?? 0,
        dp: values.dp ?? 0,
        detail:
          values.detail
            .filter((item) => item.checked)
            .map((item) => ({
              id_seleksi_vendor_detail: item.id_seleksi_vendor_detail,
              id_item_buaso: item.id_item_buaso,
              qty_order: item.qty_order,
              harga_kesepakatan: item.harga_kesepakatan,
            })) ?? [],
      };

      PurchaseOrderApi.createOrUpdate(finalValue)
        .then(() => {
          history.push('/transaksi/purchase-order', {
            alert: {
              show: true,
              variant: 'primary',
              text: 'Data berhasil diubah!',
            },
          });
        })
        .catch(() => {
          setAlertConfig({
            show: true,
            variant: 'danger',
            text: 'Data gagal ditambah!',
          });
        })
        .finally(() => setSubmitting(false));
    };

    return (
      <Fragment>
        <Row className="d-flex justify-content-between align-items-end mb-1">
          <Col>
            <b>Ubah Data Purchase Order</b>
          </Col>
          <Col className="d-flex justify-content-end ">
            <BackButton onClick={() => history.goBack()} />
          </Col>
        </Row>

        <Alert
          showCloseButton
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() =>
            setAlertConfig({
              ...alertConfig,
              show: false,
            })
          }
        />

        {isPageLoading || isFetchingFailed ? (
          <DataStatus
            loading={isPageLoading}
            text={isFetchingFailed ? 'Data gagal dimuat!' : 'Memuat data...'}
          />
        ) : (
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, isSubmitting }) => (
              <Fragment>
                <FormSection type="UPDATE" />
                <InformasiVendorSection />
                <TableListPurchaseOrder />
                <Approval
                  data={{ stakeholder: dataPurchaseOrder.info.stakeholder }}
                />
                <Row className="py-3">
                  <Col className="d-flex justify-content-end">
                    <ActionButton
                      text="Simpan"
                      onClick={handleSubmit}
                      loading={isSubmitting}
                    />
                  </Col>
                </Row>
              </Fragment>
            )}
          </Formik>
        )}
      </Fragment>
    );
  }
);

export default UbahPurchaseOrder;
