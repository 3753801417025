import { Fragment, useMemo, useContext } from 'react';
import {
  CRUDLayout,
  DataStatus,
  THead,
  Tr,
  ThFixed,
  Th,
  Td,
  TBody,
  ApprovalStatusButton,
  UpdateButton,
  DeleteButton,
  ActionButton,
} from 'components';
import { DateConvert, RupiahConvert } from 'utilities';
import { removeItemSVTable } from '../Helpers';
import { IoImage } from 'react-icons/io5';
import { ModalItemSeleksiVendorContext } from '../Context';

// type = 'action' | 'history' | 'detail'
export const TableSeleksiVendor = ({
  data,
  setData,
  // setModal,
  type = 'action',
}) => {
  const { setModal } = useContext(ModalItemSeleksiVendorContext);
  const isTypeHistory = type === 'history';
  const isTypeDetail = type === 'detail';

  const dataSV = useMemo(
    () => data.filter((pr) => Boolean(pr.id_vendor)),
    [data]
  );

  const onRemoveItemSV = (values) => setData(removeItemSVTable(data, values));

  return (
    <Fragment>
      <div className="font-weight-bold mt-4" style={{ fontSize: '.9rem' }}>
        {isTypeHistory ? 'Histori' : 'List Data'} Seleksi Vendor
      </div>
      <CRUDLayout.Table>
        <THead>
          <Tr className="text-center">
            <ThFixed>No.</ThFixed>
            <ThFixed>Informasi Seleksi Vendor</ThFixed>
            <Th>Item Barang</Th>
            <Th>Vendor</Th>
            <ThFixed>Satuan Beli</ThFixed>
            <ThFixed>Qty. Seleksi Vendor</ThFixed>
            <ThFixed>Harga Kesepakatan</ThFixed>
            <Th width={100} className="text-nowrap">
              Total Harga
            </Th>
            <ThFixed>{isTypeHistory ? 'Status Approval' : 'Aksi'}</ThFixed>
          </Tr>
        </THead>

        <TBody>
          {dataSV?.length > 0 ? (
            dataSV?.map((val, index) => (
              <Tr key={val.id_convert_barang}>
                <Td className="text-center">{index + 1}</Td>
                <Td>
                  <div>
                    {DateConvert(new Date(val.tgl_seleksi_vendor)).defaultDMY}
                  </div>
                  <div>{val.no_seleksi_vendor}</div>
                </Td>
                <Td>{val.nama_item ?? '-'}</Td>
                <Td>{val.nama_vendor ?? '-'}</Td>
                <Td>{val.satuan_beli}</Td>
                <Td textRight>{val.qty_sv}</Td>
                <Td textRight>
                  {
                    RupiahConvert(String(val.harga_kesepakatan))
                      .getWithComaLabel
                  }
                </Td>
                <Td textRight>
                  {
                    RupiahConvert(
                      String(
                        Math.round(
                          parseFloat(val.qty_sv) *
                            parseFloat(val.harga_kesepakatan)
                        )
                      )
                    ).getWithComaLabel
                  }
                </Td>
                <Td noMargin>
                  {isTypeHistory ? (
                    <ApprovalStatusButton defaultStatus="PEN" />
                  ) : isTypeDetail ? (
                    <ActionButton
                      size="sm"
                      onClick={() =>
                        setModal({
                          action: 'detail',
                          type: 'readOnly',
                          show: true,
                          data: val,
                        })
                      }
                    >
                      <IoImage />
                    </ActionButton>
                  ) : (
                    <div className="d-flex ">
                      <UpdateButton
                        onClick={() =>
                          setModal({
                            action: 'tambah',
                            type: 'form',
                            show: true,
                            data: val,
                          })
                        }
                      />
                      <DeleteButton onClick={() => onRemoveItemSV(val)} />
                    </div>
                  )}
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={10}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </Tr>
          )}
        </TBody>
      </CRUDLayout.Table>
    </Fragment>
  );
};
