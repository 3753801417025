import Services from 'services';

class PermintaanSubkonProduksiApi {
  page(params) {
    return Services.get('/transfer_produksi_subkon/page', { params });
  }

  preformPage(params) {
    return Services.get('transfer_produksi_subkon/permintaan_page', { params });
  }

  single(params) {
    return Services.get('/transfer_produksi_subkon/single_permintaan', {
      params,
    });
  }

  transfer(params) {
    return Services.get('/transfer_produksi_subkon/transfer', {
      params,
    });
  }

  getNomor(params) {
    return Services.get('/kontrak_subkon/no_baru', { params });
  }

  getDropdownKontrakSubkon(params) {
    return Services.get('/kontrak_subkon/dropdown', { params });
  }

  saveKontrakSubkon(data) {
    return Services.post('/kontrak_subkon', data);
  }

  updateStatusPermintaan(data) {
    return Services.post('/kontrak_subkon/status_permintaan', data);
  }

  getSingleKontrakSubkon(params) {
    return Services.get('/kontrak_subkon/single', { params });
  }

  dropdown(params) {
    return Services.get('/dropdown/dropdown', { params });
  }

  // page(value) {
  //   return Services.get('/transfer_produksi_subkon/page', {
  //     params: { ...value },
  //   });
  // }

  // single(value) {
  //   return Services.get('/transfer_produksi_subkon/single', {
  //     params: { ...value },
  //   });
  // }

  // transfer(value) {
  //   return Services.get('/transfer_produksi_subkon/transfer', {
  //     params: { ...value },
  //   });
  // }

  // dropdown(value) {
  //   return Services.get('/transfer_produksi_subkon/dropdown', {
  //     params: { ...value },
  //   });
  // }

  // no_baru(value) {
  //   return Services.get('/transfer_produksi_subkon/no_baru', {
  //     params: { ...value },
  //   });
  // }

  // create(value) {
  //   return Services.post('/transfer_produksi_subkon', value);
  // }

  // delete(value) {
  //   return Services.post('/transfer_produksi_subkon/delete', value);
  // }

  // getPageKontrakSubkon(params) {
  //   return Services.get('/kontrak_subkon', { params });
  // }

  // getSingleKontrakSubkon(params) {
  //   return Services.get('/kontrak_subkon/single', { params });
  // }

  // getNomorKontrakSubkon(params) {
  //   return Services.get('/kontrak_subkon/no_baru', { params });
  // }

  // getDropdownKontrakSubkon(params) {
  //   return Services.get('/kontrak_subkon/dropdown', { params });
  // }

  // saveKontrakSubkon(data) {
  //   return Services.post('/kontrak_subkon', data);
  // }
}

export default new PermintaanSubkonProduksiApi();
