import { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { IoAddOutline } from 'react-icons/io5';
import { Row, Col } from 'react-bootstrap';
import {
  CRUDLayout,
  InputSearch,
  Alert,
  DataStatus,
  FilterButton,
  ActionButton,
  THead,
  Tr,
  ThFixed,
  Th,
  Td,
  TBody,
  Pagination,
  BackButton,
} from 'components';
import { TableNumber, DateConvert } from 'utilities';
import { useSessionStorage } from 'hooks';
import { SeleksiVendorApi } from 'api';
import { ItemPRCollapse } from './Components';
import { withDropdownSeleksiVendorProvider } from './Context';
import { ModalFilterPRSeleksiVendor } from './Components/ModalFilterPRSeleksiVendor';

const TableContent = ({ history, pagination, setPagination, data }) => {
  return (
    <>
      <div className="font-weight-bold mt-2" style={{ fontSize: '.9rem' }}>
        List Purchase Request Yang Siap Dibuatkan Seleksi Vendor
      </div>

      <CRUDLayout.Table>
        <THead>
          <Tr className="text-center">
            <ThFixed>No.</ThFixed>
            <ThFixed>Informasi Purchase Request</ThFixed>
            <Th style={{ minWidth: 250 }}>Pengaju Purchase Request</Th>
            <Th style={{ minWidth: 250 }}>Item Purchase Request</Th>
            <Th style={{ minWidth: 250 }}>Proyek</Th>
            <Th style={{ minWidth: 250 }}>Keperluan</Th>
            <ThFixed>Tanggal Pemakaian</ThFixed>
            <ThFixed>Aksi</ThFixed>
          </Tr>
        </THead>

        <TBody>
          {data?.length > 0 ? (
            data?.map((val, index) => (
              <Tr key={val.id_purchase_request}>
                <Td className="text-center">
                  {TableNumber(pagination.page, pagination.per_page, index)}
                </Td>

                <Td>
                  <div>
                    {val.tgl_purchase_request
                      ? DateConvert(new Date(val.tgl_purchase_request))
                          .defaultDMY
                      : '-'}
                  </div>
                  <div>{val.no_purchase_request ?? '-'}</div>
                </Td>

                <Td>
                  <div>{val.nama_karyawan_pengaju}</div>
                  <div>{val.nama_jabatan_pengaju ?? '-'}</div>
                </Td>

                <Td>
                  <ItemPRCollapse data={val.detail ?? []} />
                </Td>

                <Td>{val.nama_proyek ?? '-'}</Td>

                <Td className="text-capitalize">{val.keperluan}</Td>

                <Td>
                  {val.tgl_pemakaian
                    ? DateConvert(new Date(val.tgl_pemakaian)).defaultDMY
                    : '-'}
                </Td>

                <Td>
                  <ActionButton
                    size="sm"
                    className="m-1 text-nowrap"
                    onClick={() =>
                      history.push(
                        '/transaksi/seleksi-vendor/tambah/' +
                          val.id_purchase_request
                      )
                    }
                  >
                    <IoAddOutline />
                  </ActionButton>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={10}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </Tr>
          )}
        </TBody>
      </CRUDLayout.Table>

      <Pagination
        dataLength={pagination?.per_page}
        dataCount={pagination?.data_count}
        currentPage={pagination?.page}
        totalPage={pagination?.total_page}
        dataNumber={
          pagination?.page * pagination?.per_page - pagination?.per_page + 1
        }
        dataPage={
          pagination?.data_count < pagination?.per_page
            ? pagination?.data_count
            : pagination?.page * pagination?.per_page
        }
        onPaginationChange={({ selected }) =>
          setPagination((prev) => ({
            ...prev,
            page: selected + 1,
          }))
        }
        onDataLengthChange={(e) =>
          setPagination((prev) => ({
            ...prev,
            page: 1,
            per_page: e.target.value,
          }))
        }
      />
    </>
  );
};

const PurchaseRequestSeleksiVendorList = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const FILTER_KEY = `${location.pathname}_filter`;
  const PAGINATION_KEY = `${location.pathname}_pagination`;
  const [isPageLoading, setPageLoading] = useState(true);
  const [dataPurchaseRequest, setDataPurchaseRequest] = useState([]);
  const [pagination, setPagination] = useSessionStorage(PAGINATION_KEY, {
    q: '',
    page: '1',
    per_page: '10',
    data_count: '0',
    total_page: '1',

    active: false,
    tgl_pemakaian_start: undefined,
    tgl_pemakaian_end: undefined,
    tgl_purchase_request_start: undefined,
    tgl_purchase_request_end: undefined,
    id_karyawan_pengaju: undefined,
    id_jabatan_pengaju: undefined,
    id_item_buaso: undefined,
    id_proyek: undefined,
    keperluan: undefined,
  });
  const { data_count, total_page, ...deps } = pagination;
  const [alert, setAlert] = useState({
    variant: 'primary',
    text: '',
  });
  const [modalFilter, setModalFilter] = useState(false);

  const searchHandler = (e) => {
    const searchKey = e.target.value;

    setPagination({
      ...pagination,
      q: searchKey,
      page: '1',
    });
  };

  const getInitialData = () => {
    setPageLoading(true);

    const query = { ...pagination };

    ['data_count', 'total_page', 'active'].forEach((key) => delete query[key]);

    SeleksiVendorApi.getPagePurchaseRequest(query)
      .then((res) => {
        setDataPurchaseRequest(res.data.data ?? []);
        setPagination((prev) => ({
          ...prev,
          data_count: res.data.data_count,
          total_page: res.data.total_page,
        }));
      })
      .catch(() => setAlert({ text: 'Data gagal dimuat', variant: 'danger' }))
      .finally(() => setPageLoading(false));
  };

  useEffect(() => getInitialData(), [JSON.stringify(deps)]);

  useEffect(() => {
    setNavbarTitle('Seleksi Vendor');
  }, []);

  return (
    <CRUDLayout>
      <Row className="d-flex align-items-center mb-2">
        <Col md="10" className="d-flex">
          <InputSearch
            defaultValue={pagination.q}
            onChange={debounce(searchHandler, 1500)}
          />
          <FilterButton
            active={pagination.active}
            onClick={() => setModalFilter({ show: true })}
          />
        </Col>

        <Col className="d-flex justify-content-end align-items-end">
          <BackButton onClick={() => history.goBack()} />
        </Col>
      </Row>

      <Alert
        showCloseButton
        className="mt-2"
        show={!!alert?.length}
        variant={alert.variant}
        text={alert.text}
        onClose={() => setAlert((prev) => ({ ...prev, text: '' }))}
      />

      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : dataPurchaseRequest?.length > 0 ? (
        <TableContent
          history={history}
          pagination={pagination}
          setPagination={setPagination}
          data={dataPurchaseRequest ?? []}
        />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      {modalFilter && (
        <ModalFilterPRSeleksiVendor
          show={modalFilter}
          setShow={setModalFilter}
          filter={pagination}
          setFilter={setPagination}
          filterKey={FILTER_KEY}
        />
      )}
    </CRUDLayout>
  );
};

export default withDropdownSeleksiVendorProvider(
  PurchaseRequestSeleksiVendorList
);
