// React
import React, { useState, useEffect } from 'react';

// Router
import { useHistory } from 'react-router-dom';

// Component
import {
  CRUDLayout,
  THead,
  TBody,
  ThFixed,
  Alert,
  InputSearch,
  TdFixed,
  DataStatus,
  Tr,
  Th,
  Td,
  Pagination,
  ActionButton,
} from '../../../components';
import { Button, Col, Row } from 'react-bootstrap';
import { TableNumber } from '../../../utilities';

// API
import { PrakualifikasiVendorApi } from '../../../api';

// Icon
import { IoArrowBackOutline } from 'react-icons/io5';

const CalonVendor = ({ setNavbarTitle }) => {
  let history = useHistory();
  // indikator pemanggilan data sedang dimuat di server
  const [isLoading, setIsLoading] = useState(false);

  // indikator apakah sedang melakukan searching
  const [isSearching, setIsSearching] = useState(false);

  // menampung value dari search form
  const [searchKey, setSearchKey] = useState('');

  // Data View
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [dataCount, setDataCount] = useState(0);
  const [totalPage, setTotalPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);

  // alert
  const [showAlert, setShowAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
  });

  const getData = () => {
    setIsLoading(true);
    setShowAlert(false);
    setIsSearching(false);

    PrakualifikasiVendorApi.getPageCalonVendor(page, dataLength, searchKey)
      .then((res) => {
        setData(res.data.data);
        setTotalPage(res.data.total_page);
        setDataCount(res.data.data_count);
      })
      .catch((err) => alert(err))
      .finally(() => {
        if (searchKey != '') {
          setAlertConfig({
            variant: 'primary',
            text: `Hasil Pencarian : ${searchKey}`,
          });
          setShowAlert(true);
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    // set judul di Navbar
    setNavbarTitle('Calon Vendor');

    // jalankan function request data ke server
    getData();

    // menangani pembersihan saat fetching data
    return () => {
      setIsLoading(false);
    };
  }, [setNavbarTitle, page, dataLength, searchKey]);

  // Tabel
  const Table = () => (
    <>
      <CRUDLayout.Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <Th>Kode Vendor</Th>
            <Th>Nama Vendor</Th>
            <Th>Jenis Vendor</Th>
            <Th>Contact Person</Th>
            <Th>Telepon</Th>
            <Th>Aksi</Th>
          </Tr>
        </THead>
        <TBody>
          {data.map((val, index) => (
            <Tr key={index}>
              <TdFixed>{TableNumber(page, dataLength, index)}</TdFixed>
              <Td>
                <div className="text-center">{val.kode_vendor}</div>
              </Td>
              <Td>{val.nama_vendor}</Td>
              <Td>{val.jenis_vendor}</Td>
              <Td>{val.contact_person}</Td>
              <Td>{val.telepon}</Td>
              <TdFixed>
                <div className="d-flex justify-content-center">
                  <Button
                    color="primary"
                    className="mx-1"
                    onClick={() => {
                      history.push('/master/vendor/detail/' + val.id_vendor);
                    }}
                  >
                    Profil Vendor
                  </Button>

                  <Button
                    color="primary"
                    className="mx-1"
                    onClick={() => {
                      history.push(
                        '/master/prakualifikasi-vendor/tambah/' + val.id_vendor
                      );
                    }}
                  >
                    Prakualifikasi
                  </Button>
                </div>
              </TdFixed>
            </Tr>
          ))}
        </TBody>
      </CRUDLayout.Table>
      {!isSearching && (
        <Pagination
          dataLength={dataLength}
          dataNumber={page * dataLength - dataLength + 1}
          dataPage={page * dataLength}
          dataCount={dataCount}
          onDataLengthChange={(e) => {
            setDataLength(e.target.value);
            setPage(1);
          }}
          currentPage={page}
          totalPage={totalPage}
          onPaginationChange={({ selected }) => setPage(selected + 1)}
        />
      )}
    </>
  );

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row>
            <Col md="8">
              <InputSearch
                onChange={(e) => {
                  setTimeout(() => {
                    setSearchKey(e.target.value);
                    setPage(1);
                  }, 1000);
                }}
                onSubmit={(e) => e.preventDefault()}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          <Button
            variant="outline-secondary"
            size="md"
            onClick={() => history.goBack()}
          >
            <IoArrowBackOutline size={20} />
            <span className="ml-2">Kembali Ke Tabel</span>
          </Button>
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      {/* Table */}
      {
        // cek apakah data sedang dimuat (loading)
        isLoading === true ? (
          <DataStatus loading={true} text="Memuat data..." />
        ) : // Cek apakah ada data
        data.length > 0 ? (
          <Table />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      }
    </CRUDLayout>
  );
};

export default CalonVendor;
