import { createContext, useState } from 'react';

export const TransferUpahProduksiContext = createContext();

export const TransferUpahProduksiProvider = ({ children }) => {
  const [dataJobOrder, setJobOrder] = useState({});

  const [dataPermintaan, setDataPermintaan] = useState({
    lc: [],
    lcf: [],
  });

  const [dataTransfer, setDataTransfer] = useState({
    lc: [],
    lcf: [],
  });

  const [dataHistory, setDataHistory] = useState({
    lc: [],
    lcf: [],
  });

  const [modalForm, setModalForm] = useState({
    tab: 'lc',
    action: 'create',
    data: null,
    showRegistrasi: false,
    showKontrak: false,
  });

  const [modalKonfirmasi, setModalKonfirmasi] = useState(false);

  return (
    <TransferUpahProduksiContext.Provider
      value={{
        dataJobOrder,
        setJobOrder,
        dataPermintaan,
        setDataPermintaan,
        dataTransfer,
        setDataTransfer,
        dataHistory,
        setDataHistory,
        modalForm,
        setModalForm,
        modalKonfirmasi,
        setModalKonfirmasi,
      }}
    >
      {children}
    </TransferUpahProduksiContext.Provider>
  );
};

export const withTransferUpahProduksiProvider = (Component) => (props) => {
  return (
    <TransferUpahProduksiProvider>
      <Component {...props} />
    </TransferUpahProduksiProvider>
  );
};
