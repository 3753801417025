import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Col, Modal, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Axios from 'axios';
import NumberFormat from 'react-number-format';
import {
  InputCurrency,
  ActionButton,
  CreateButton,
  SelectSearch,
  InputSearch,
  CRUDLayout,
  DataStatus,
  Pagination,
  TdFixed,
  ThFixed,
  THead,
  Input,
  TBody,
  Alert,
  Tr,
  Th,
  Td,
} from 'components';
import { TableNumber, RupiahConvert, DateConvert } from 'utilities';
import { HargaKubikasiKayuApi } from 'api';
import { useIsGuest } from 'hooks';

const HargaKubikasiKayu = ({ setNavbarTitle }) => {
  const title = 'Harga Kubikasi Kayu';
  const location = useLocation();
  const isGuest = useIsGuest();

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dropdownJenisKayu, setDropdownJenisKayu] = useState([]);
  const [dropdownPartKayu, setDropdownPartKayu] = useState([]);
  const [processedData, setProcessedData] = useState({});
  const [paginationConfig, setPaginationConfig] = useState({
    page: '1',
    dataLength: '10',
    totalPage: '1',
    dataCount: '0',
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: 'detail',
  });

  const mapDropdownJenisKayu = (data) =>
    data.map((item) => ({
      ...item,
      value: item.id_jenis_kayu,
      label: item.nama_jenis_kayu,
    }));

  const mapDropdownPartKayu = (data) =>
    data.map((item) => ({
      ...item,
      value: item.id_part_kayu,
      label: item.nama_part_kayu,
    }));

  const getInitialData = () => {
    setIsPageLoading(true);

    Axios.all([
      HargaKubikasiKayuApi.getPage({
        q: searchConfig.key,
        page: paginationConfig.page,
        per_page: paginationConfig.dataLength,
      }),
      HargaKubikasiKayuApi.getDrodpownJenisKayu(),
      HargaKubikasiKayuApi.getDrodpownPartKayu(),
    ])
      .then(
        Axios.spread((data, dropdown, part) => {
          const dataPage = data?.data?.data ?? [];
          const dataDropdown = dropdown?.data?.data ?? [];
          const dataPart = part?.data?.data ?? [];

          setData(dataPage);
          setDropdownJenisKayu(mapDropdownJenisKayu(dataDropdown));
          setDropdownPartKayu(mapDropdownPartKayu(dataPart));
          setPaginationConfig({
            ...paginationConfig,
            dataCount: data.data.data_count,
            totalPage: data.data.total_page,
          });
        })
      )
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        })
      )
      .finally(() => setIsPageLoading(false));
  };
  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  useEffect(() => {
    setNavbarTitle(title);
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
      setData([]);
    };
  }, [
    setNavbarTitle,
    paginationConfig.page,
    paginationConfig.dataLength,
    searchConfig.key,
  ]);

  const formInitialValues = {
    id_jenis_kayu: processedData?.id_jenis_kayu ?? '',
    id_part_kayu: processedData?.id_part_kayu ?? '',
    t: processedData?.t ?? '',
    w: processedData?.w ?? '',
    l: processedData?.l ?? '',
    harga_rata_rata_per_kubik: processedData?.harga_rata_rata_per_kubik ?? '',
  };
  const formValidationSchema = Yup.object().shape({
    harga_rata_rata_per_kubik: Yup.string().required(
      'Masukkan Harga Rata-rata/M3'
    ),
  });
  const formCreateValidationSchema = Yup.object().shape({
    id_jenis_kayu: Yup.string().required(
      'Masukkan kolom Jenis Kayu diperlukan'
    ),
    id_part_kayu: Yup.string().required('Masukkan kolom Part Kayu diperlukan'),
    harga_rata_rata_per_kubik: Yup.string().required(
      'Masukkan Harga Rata-rata/M3'
    ),
    t: Yup.string()
      .required('Masukkan kolom Thickness diperlukan')
      .test({
        test: (val) => parseFloat(val) >= 1,
        message: 'Minimum thickness adalah 1',
      }),
    w: Yup.string()
      .required('Masukkan kolom Width diperlukan')
      .test({
        test: (val) => parseFloat(val) >= 1,
        message: 'Minimum width adalah 1',
      }),
    l: Yup.string()
      .required('Masukkan kolom Length diperlukan')
      .test({
        test: (val) => parseFloat(val) >= 1,
        message: 'Minimum length adalah 1',
      }),
  });
  const formSubmitHandler = (values, { setSubmitting }) => {
    HargaKubikasiKayuApi.save(values)
      .then(() =>
        setAlertConfig({
          show: true,
          variant: 'primary',
          text: 'Data berhasil disimpan!',
        })
      )
      .catch((err) =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: err.response.data.message,
        })
      )
      .finally(() => {
        setSubmitting(false);
        setProcessedData({});
        setModalConfig({ show: false });
        getInitialData();
      });
  };

  const PageModal = () => {
    // MODAL TITLE
    const ModalTitle = () =>
      modalConfig.type.toLowerCase() === 'update' ? (
        <span className="text-success">Ubah Data Harga Kubikasi Kayu</span>
      ) : (
        <span className="text-primary">Tambah Data Harga Kubikasi Kayu</span>
      );

    // BODY MODAL DETAIL
    const ModalInfoSection = () => {
      const ShowData = ({ title, text }) => (
        <div className="d-flex flex-row">
          <div
            className="text-secondary mb-2 mr-3"
            style={{ width: 170, fontSize: '14px' }}
          >
            {title}
          </div>
          <span style={{ fontSize: '14px' }}>:</span>
          <div
            className="text-dark text-wrap"
            style={{ paddingLeft: 5, fontSize: '14px' }}
          >
            {text ? text : '-'}
          </div>
        </div>
      );

      return (
        <>
          <ShowData
            title="Jenis Kayu"
            text={processedData.nama_jenis_kayu ?? '-'}
          />
          <ShowData title="Thickness (T)" text={processedData.t ?? '0'} />
          <ShowData title="Width (W)" text={processedData.w ?? '0'} />
          <ShowData title="Length (L)" text={processedData.l ?? '0'} />
          <ShowData
            title="Tgl. Update Terakhir"
            text={
              processedData.updated_at
                ? DateConvert(new Date(processedData.updated_at)).detail
                : ''
            }
          />
        </>
      );
    };

    // BODY MODAL FORM
    const ModalForm = ({ formik }) => {
      const {
        values,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        handleSubmit,
      } = formik;

      return (
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            {modalConfig.type === 'update' && (
              <>
                <ModalInfoSection /> <hr />
              </>
            )}
            {modalConfig.type === 'tambah' && (
              <>
                <Row>
                  <Col md>
                    <SelectSearch
                      label="Jenis Kayu"
                      name="id_jenis_kayu"
                      placeholder="Pilih Jenis Kayu"
                      onChange={(val) =>
                        setFieldValue('id_jenis_kayu', val.value)
                      }
                      option={dropdownJenisKayu}
                      error={
                        errors.id_jenis_kayu && touched.id_jenis_kayu && true
                      }
                      errorText={
                        errors.id_jenis_kayu &&
                        touched.id_jenis_kayu &&
                        errors.id_jenis_kayu
                      }
                    />
                  </Col>
                  <Col md>
                    <SelectSearch
                      label="Part Kayu"
                      name="id_part_kayu"
                      placeholder="Pilih Jenis Kayu"
                      onChange={(val) => {
                        setFieldValue('id_part_kayu', val.value);
                      }}
                      option={dropdownPartKayu}
                      error={
                        errors.id_part_kayu && touched.id_part_kayu && true
                      }
                      errorText={
                        errors.id_part_kayu &&
                        touched.id_part_kayu &&
                        errors.id_part_kayu
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md>
                    <Input
                      label="Thickness (T)"
                      type="number"
                      className="text-right"
                      value={values.t}
                      onChange={(e) => setFieldValue('t', e.target.value)}
                      error={errors.t && touched.t && true}
                      errorText={errors.t}
                    />
                  </Col>
                  <Col md>
                    <Input
                      label="Width (W)"
                      type="number"
                      className="text-right"
                      value={values.w}
                      error={errors.w && touched.w && true}
                      onChange={(e) => setFieldValue('w', e.target.value)}
                      errorText={errors.w}
                    />
                  </Col>
                  <Col md>
                    <Input
                      label="Length (L)"
                      type="number"
                      className="text-right"
                      value={values.l}
                      onChange={(e) => setFieldValue('l', e.target.value)}
                      error={errors.l && touched.l && true}
                      errorText={errors.l}
                    />
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <Col md>
                <InputCurrency
                  className="text-right"
                  label="Harga Rata-Rata/M3 Terbaru"
                  value={parseFloat(values.harga_rata_rata_per_kubik)}
                  onChange={(e) =>
                    setFieldValue('harga_rata_rata_per_kubik', e)
                  }
                  error={
                    errors.harga_rata_rata_per_kubik &&
                    touched.harga_rata_rata_per_kubik &&
                    true
                  }
                  errorText={errors.harga_rata_rata_per_kubik}
                />

                {/* <Input
                  value={
                    RupiahConvert(parseInt(values.harga_rata_rata_per_kubik || 0).toString()).detail
                  }
                  onChange={(e) => {
                    const value = RupiahConvert(e.target.value).default
                    setFieldValue("harga_rata_rata_per_kubik", value)
                  }}
                /> */}
              </Col>
            </Row>
            <div className="mt-2 d-flex justify-content-end">
              <ActionButton
                onClick={handleSubmit}
                loading={isSubmitting}
                text={modalConfig.type === 'tambah' ? 'Tambah' : 'Update'}
                variant={modalConfig.type === 'tambah' ? 'primary' : 'success'}
              />
            </div>
          </form>
        </Modal.Body>
      );
    };

    return (
      <Modal
        size="md"
        show={modalConfig.show}
        onHide={() => {
          setModalConfig({ ...modalConfig, show: false });
          setProcessedData({});
        }}
      >
        <Modal.Header closeButton>
          <ModalTitle />
        </Modal.Header>
        <Formik
          initialValues={formInitialValues}
          validationSchema={
            modalConfig.type === 'tambah'
              ? formCreateValidationSchema
              : formValidationSchema
          }
          onSubmit={formSubmitHandler}
        >
          {(formik) => <ModalForm formik={formik} />}
        </Formik>
      </Modal>
    );
  };

  const DataTable = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <Th>Jenis Kayu</Th>
              <Th>Part Kayu</Th>
              <ThFixed>T</ThFixed>
              <ThFixed>W</ThFixed>
              <ThFixed>L</ThFixed>
              <Th>Harga Rata-rata/M3</Th>
              <ThFixed>Tgl. Update Terakhir</ThFixed>
              {!isGuest && <ThFixed>Aksi</ThFixed>}
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => {
              return (
                <Tr key={val.id_item_buaso}>
                  <TdFixed>
                    {TableNumber(
                      paginationConfig.page,
                      paginationConfig.dataLength,
                      index
                    )}
                  </TdFixed>
                  <Td>{val.nama_jenis_kayu ?? '-'}</Td>
                  <Td>{val.nama_part_kayu ?? '-'}</Td>
                  <Td className="text-right">{val.t ?? '0'}</Td>
                  <Td className="text-right">{val.w ?? '0'}</Td>
                  <Td className="text-right">{val.l ?? '0'}</Td>
                  <Td className="text-right text-nowrap">
                    <NumberFormat
                      value={parseFloat(val.harga_rata_rata_per_kubik ?? 0)}
                      displayType="text"
                      prefix="Rp"
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale={2}
                    />
                  </Td>
                  <Td>
                    {val.updated_at
                      ? DateConvert(new Date(val.updated_at)).defaultDMY
                      : '-'}
                  </Td>
                  {!isGuest && (
                    <TdFixed>
                      <div className="d-flex justify-content-start align-items-center">
                        <ActionButton
                          text="Update"
                          size="sm"
                          onClick={() => {
                            setProcessedData(val);
                            setModalConfig({ show: true, type: 'update' });
                          }}
                        />
                      </div>
                    </TdFixed>
                  )}
                </Tr>
              );
            })}
          </TBody>
        </CRUDLayout.Table>
        <Pagination
          dataLength={paginationConfig.dataLength}
          dataNumber={
            paginationConfig.page * paginationConfig.dataLength -
            paginationConfig.dataLength +
            1
          }
          dataPage={
            paginationConfig.dataCount < paginationConfig.dataLength
              ? paginationConfig.dataCount
              : paginationConfig.page * paginationConfig.dataLength
          }
          dataCount={paginationConfig.dataCount}
          currentPage={paginationConfig.page}
          totalPage={paginationConfig.totalPage}
          onPaginationChange={({ selected }) =>
            setPaginationConfig({
              ...paginationConfig,
              page: selected + 1,
            })
          }
          onDataLengthChange={(e) =>
            setPaginationConfig({
              ...paginationConfig,
              page: 1,
              dataLength: e.target.value,
            })
          }
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row>
            <Col md="8">
              <InputSearch
                onChange={(e) => {
                  const key = e.target.value;
                  setSearchConfig({
                    ...searchConfig,
                    key: e.target.value,
                  });
                  setAlertConfig({
                    show: key ? true : false,
                    variant: 'primary',
                    text: 'Hasil dari pencarian: ' + key,
                  });
                }}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() => setModalConfig({ show: true, type: 'tambah' })}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <div className="mt-2">
        <Alert
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          showCloseButton={true}
          onClose={() =>
            setAlertConfig({
              ...alertConfig,
              show: false,
            })
          }
        />
      </div>

      {isPageLoading ? (
        <DataStatus loading={isPageLoading} text="Memuat Data" />
      ) : data.length > 0 ? (
        <DataTable />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      {/* MODAL */}
      {modalConfig.show && <PageModal />}
    </CRUDLayout>
  );
};

export default HargaKubikasiKayu;
