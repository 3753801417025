import { SeleksiVendorApi } from 'api';
import { DateConvert } from 'utilities';

export const tglInputFormat = ({ created_at = null, separator = ' ' }) => {
  if (created_at) {
    const inputDate = new Date(created_at);
    const date = inputDate.toLocaleDateString('id-ID');
    const time = inputDate.toLocaleTimeString('en-US', { hour12: false });

    return {
      default: `${date}${separator}${time}`,
      date,
      time,
    };
  }
  return '';
};

export const createOrUpdateItemSVTable = (listPurchaseRequest = [], values) => {
  const tempPR = [...listPurchaseRequest];
  const findIndex = tempPR.findIndex(
    (pr) => pr.id_purchase_request_detail === values.id_purchase_request_detail
  );

  tempPR[findIndex] = values;

  return tempPR;
};

export const removeItemSVTable = (listPurchaseRequest = [], values) => {
  const keysToRemove = [
    'id_vendor',
    'nama_vendor',
    'tgl_seleksi_vendor',
    'no_seleksi_vendor',
    'harga_kesepakatan',
    'qty_sv',
    'keterangan',
  ];
  const tempPR = [...listPurchaseRequest];

  const findIndex = tempPR.findIndex(
    (pr) => pr.id_purchase_request_detail === values.id_purchase_request_detail
  );

  keysToRemove.forEach((key) => delete tempPR[findIndex][key]);

  return tempPR;
};

export const submitImages = async (images = []) => {
  const filterPrevImages = images.filter((i) => Boolean(i.path_gambar));
  const imagesToSubmit = images.filter((i) => i.data);

  if (imagesToSubmit?.length > 0) {
    const formData = new FormData();

    imagesToSubmit.forEach((file, index) =>
      formData.append(`file_${index}`, file.data)
    );

    return await SeleksiVendorApi.uploadMultipleFiles(formData).then((res) => {
      return filterPrevImages.concat(
        Object.values(res.data.data).map((path_gambar) => ({
          path_gambar,
        }))
      );
    });
  }

  return filterPrevImages ?? [];
};

export const mapDataOnSubmitCreate = (data = []) => {
  const mapItem = (item) => ({
    id_purchase_request: item.id_purchase_request,
    tgl_seleksi_vendor: DateConvert(item.tgl_seleksi_vendor).default,
    no_seleksi_vendor: item.no_seleksi_vendor,
    id_vendor: item.id_vendor,
    keterangan: item.keterangan,
    detail: [
      {
        id_item_buaso: item.id_item_buaso,
        qty_order: item.qty_sv,
        harga_kesepakatan: item.harga_kesepakatan,
        path: item.path_gambar ?? [],
        keterangan: item.keterangan,
      },
    ],
  });

  const result = data.map((d) => mapItem(d));

  return { data: result };
};

export const mapDataonSubmitUpdate = (data) => {
  return {
    id_seleksi_vendor: data?.id_seleksi_vendor,
    no_seleksi_vendor: data?.no_seleksi_vendor,
    tgl_seleksi_vendor: DateConvert(data?.tgl_seleksi_vendor).default,
    id_vendor: data?.id_vendor,
    id_purchase_request: data?.id_purchase_request,
    keterangan: data.keterangan,
    detail: [
      {
        id_item_buaso: data?.id_item_buaso,
        qty_order: data?.qty_sv,
        harga_kesepakatan: data.harga_kesepakatan,
        path: data.path_gambar,
        keterangan: data.keterangan,
      },
    ],
  };
};

export const getSingleBeforeUpdate = (id_seleksi_vendor, setModalForm) => {
  const { REACT_APP_FILE_BASE_URL } = process.env;
  setModalForm({ show: false, data: null, isLoading: true });

  SeleksiVendorApi.getSingle({ id_seleksi_vendor }).then((res) => {
    const data = res.data.data;
    const mapData = {
      ...data,
      qty_sv: data.qty_order,
      images: data?.gambar?.map((i) => ({
        link: `${REACT_APP_FILE_BASE_URL}/${i.path_gambar}`,
        path_gambar: i.path_gambar,
      })),
    };
    setModalForm({ show: true, data: mapData, isLoading: false });
  });
};
