import { ButtonGroup } from 'react-bootstrap';
import {
  Table,
  THead,
  Tr,
  Th,
  Td,
  TdFixed,
  ThFixed,
  TBody,
  UpdateButton,
  DeleteButton,
  DataStatus,
} from 'components';
import { useContext } from 'react';
import { DateConvert, RupiahConvert } from 'utilities';
import { TransferSubkonProduksiContext } from '../Context';
import { calcGrandTotal } from '../Helpers/calculation';

import { RincianPekerjaanCollapse } from './RincianPekerjaanCollapse';

export const TableTransfer = ({
  tipe = 'editable', // editable | readonly
  tab, // sf
  data = [],
}) => {
  const { setDataTransfer, setModalForm } = useContext(
    TransferSubkonProduksiContext
  );
  const isEditable = tipe === 'editable';

  const onUpdateClick = (data) => {
    setModalForm({
      tab,
      data,
      action: 'update',
      showRegistrasi: false,
      showKontrak: true,
    });
  };

  const onDeleteClick = (index) => {
    setDataTransfer((prev) => ({
      ...prev,
      [tab]: prev[tab].toSpliced(index, 1),
    }));
  };

  return (
    <Table>
      <THead>
        <Tr className="text-center">
          <ThFixed>No.</ThFixed>
          <ThFixed>Informasi Transfer Subkon Produksi</ThFixed>
          <Th>Vendor</Th>
          <Th>Rincian Pekerjaan</Th>
          <Th>PPN</Th>
          <Th>Total Harga</Th>
          <Th>DP</Th>
          <Th>Retensi</Th>
          {isEditable && <ThFixed>Aksi</ThFixed>}
        </Tr>
      </THead>

      <TBody>
        {data?.length ? (
          data.map((val, index) => (
            <Tr key={index}>
              <TdFixed className="text-center">{index + 1}</TdFixed>
              <Td>
                <div>
                  {val.tgl_kontrak_subkon
                    ? DateConvert(new Date(val.tgl_kontrak_subkon)).defaultDMY
                    : '-'}
                </div>
                {val.no_kontrak_subkon}
              </Td>
              <Td>{val.nama_vendor}</Td>
              <Td>
                <RincianPekerjaanCollapse data={val.detail} />
              </Td>
              <Td textRight>{parseFloat(val.ppn ?? 0)}%</Td>
              <Td textRight>
                {RupiahConvert(String(calcGrandTotal(val.detail))).detail}
              </Td>
              <Td textRight>{parseFloat(val.dp ?? 0)}%</Td>
              <Td textRight>{parseFloat(val.retensi ?? 0)}%</Td>
              {isEditable && (
                <Td>
                  <ButtonGroup>
                    <UpdateButton
                      className="m-0"
                      onClick={() => onUpdateClick(val)}
                    />
                    <DeleteButton
                      className="m-0"
                      onClick={() => onDeleteClick(index)}
                    />
                  </ButtonGroup>
                </Td>
              )}
            </Tr>
          ))
        ) : (
          <Tr>
            <Td colSpan={isEditable ? 9 : 8}>
              <DataStatus text="Tidak ada data" />
            </Td>
          </Tr>
        )}
      </TBody>
    </Table>
  );
};
