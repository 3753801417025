import { createContext, useState } from 'react';

export const ModalItemSeleksiVendorContext = createContext();

export const ModalItemSeleksiVendorProvider = ({ children }) => {
  const [modal, setModal] = useState({
    action: 'create', // create | update | detail
    type: 'form', // form | readOnly
    show: false,
    data: null,
  });

  return (
    <ModalItemSeleksiVendorContext.Provider value={{ modal, setModal }}>
      {children}
    </ModalItemSeleksiVendorContext.Provider>
  );
};

export const withModalItemSeleksiVendorProvider = (Component) => (props) => {
  return (
    <ModalItemSeleksiVendorProvider>
      <Component {...props} />
    </ModalItemSeleksiVendorProvider>
  );
};
