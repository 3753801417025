import { useState, useContext } from 'react';
import { Formik } from 'formik';
import { DatePicker, FilterModal, SelectSearch } from 'components';
import { DateConvert } from 'utilities';
import { DropdownPurchaseOrderContext } from '../Contexts';

export const ModalFilterPurchaseOrder = ({
  show,
  setShow,
  filter,
  setFilter,
}) => {
  const [idJabatan, setIdJabatan] = useState(filter?.id_jabatan_pengaju);
  const defaultDropdown = { value: null, label: 'Semua' };

  const {
    dropdownApproval,
    dropdownStatusPO,
    dropdownPembayaran,
    dropdownItem,
    dropdownJabatan,
    dropdownVendor,
    useDropdownKaryawan,
  } = useContext(DropdownPurchaseOrderContext);

  const optStatusPO = [defaultDropdown].concat(dropdownStatusPO);
  const optApproval = [defaultDropdown].concat(dropdownApproval);
  const optPembayaran = [defaultDropdown].concat(dropdownPembayaran);
  const optItems = [defaultDropdown].concat(dropdownItem.data ?? []);
  const optJabatan = [defaultDropdown].concat(dropdownJabatan.data ?? []);
  const optVendor = [defaultDropdown].concat(dropdownVendor.data ?? []);

  const karyawan = useDropdownKaryawan(idJabatan);
  const optKaryawan = [defaultDropdown].concat(karyawan.data ?? []);

  const initialValues = {
    tgl_input_start: filter?.tgl_input_start,
    tgl_input_end: filter?.tgl_input_end,
    tgl_purchase_order_start: filter?.tgl_purchase_order_start,
    tgl_purchase_order_end: filter?.tgl_purchase_order_end,
    id_jabatan_pengaju: filter?.id_jabatan_pengaju,
    id_karyawan_pengaju: filter?.id_karyawan_pengaju,
    id_vendor: filter?.id_vendor,
    list_item_po: filter?.list_item_po,
    pembayaran: filter?.pembayaran,
    tgl_jatuh_tempo_start: filter?.tgl_jatuh_tempo_start,
    tgl_jatuh_tempo_end: filter?.tgl_jatuh_tempo_end,
    status_approval: filter?.status_approval,
    status_purchase_order: filter?.status_purchase_order,
  };

  const onSubmit = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setFilter((prev) => ({
        ...prev,
        ...values,
        active: true,
        page: '1',
      }));

      setShow(false);
      return;
    }

    setFilter({ ...values, filter: false });
    setShow(false);
  };

  const onResetButtonClick = () => {
    setFilter((prev) => ({
      ...prev,
      page: '1',
      active: false,
      tgl_input_start: undefined,
      tgl_input_end: undefined,
      tgl_purchase_order_start: undefined,
      tgl_purchase_order_end: undefined,
      id_jabatan_pengaju: undefined,
      id_karyawan_pengaju: undefined,
      id_vendor: undefined,
      list_item_po: undefined,
      pembayaran: undefined,
      tgl_jatuh_tempo_start: undefined,
      tgl_jatuh_tempo_end: undefined,
      status_approval: undefined,
      status_purchase_order: undefined,
    }));
    setShow(false);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, handleSubmit, setValues }) => (
        <FilterModal
          show={show}
          setShow={setShow}
          onResetButtonClick={() => onResetButtonClick(setValues)}
          onFilterButtonClick={handleSubmit}
        >
          <DatePicker
            selectsRange
            label="Tgl. Input Data"
            placeholderText="Pilih Tanggal Input Data"
            startDate={
              values.tgl_input_start ? new Date(values.tgl_input_start) : ''
            }
            endDate={values.tgl_input_end ? new Date(values.tgl_input_end) : ''}
            onChange={(dates) => {
              const [start, end] = dates;
              const startDate = start ? DateConvert(start).default : null;
              const endDate = end ? DateConvert(end).default : null;

              setFieldValue('tgl_input_start', startDate);
              setFieldValue('tgl_input_end', endDate);
            }}
            monthsShown={2}
          />

          <DatePicker
            selectsRange
            label="Tgl. Purchase Order"
            placeholderText="Pilih Tanggal Purchase Order"
            startDate={
              values.tgl_purchase_order_start
                ? new Date(values.tgl_purchase_order_start)
                : ''
            }
            endDate={
              values.tgl_purchase_order_end
                ? new Date(values.tgl_purchase_order_end)
                : ''
            }
            onChange={(dates) => {
              const [start, end] = dates;
              const startDate = start ? DateConvert(start).default : null;
              const endDate = end ? DateConvert(end).default : null;

              setFieldValue('tgl_purchase_order_start', startDate);
              setFieldValue('tgl_purchase_order_end', endDate);
            }}
            monthsShown={2}
          />

          <SelectSearch
            label="Jabatan Pembuat Purchase Order"
            placeholder="Pilih Jabatan Pembuat Purchase Order"
            loading={dropdownJabatan?.isLoading}
            option={optJabatan}
            defaultValue={
              values.id_jabatan_pengaju
                ? optJabatan.find((v) => values.id_jabatan_pengaju === v.value)
                : defaultDropdown
            }
            onChange={({ value }) => {
              setFieldValue('id_jabatan_pengaju', value);
              setIdJabatan(value);
            }}
          />

          <SelectSearch
            key={idJabatan}
            label="Nama Pembuat Purchase Order"
            placeholder="Nama Pembuat Purchase Order"
            loading={karyawan?.isLoading}
            option={optKaryawan}
            isDisabled={idJabatan === null || idJabatan === undefined}
            defaultValue={
              values.id_karyawan_pengaju
                ? optKaryawan.find(
                    (v) => values.id_karyawan_pengaju === v.value
                  )
                : defaultDropdown
            }
            onChange={({ value }) =>
              setFieldValue('id_karyawan_pengaju', value)
            }
          />

          <SelectSearch
            label="Vendor"
            placeholder="Pilih Nama Vendor"
            loading={dropdownVendor?.isLoading}
            option={optVendor}
            defaultValue={
              values.id_vendor
                ? optVendor.find((v) => values.id_vendor === v.value)
                : defaultDropdown
            }
            onChange={({ value }) => setFieldValue('id_vendor', value)}
          />

          <SelectSearch
            label="Item Purchase Order"
            placeholder="Pilih Item Purchase Order"
            loading={dropdownItem?.isLoading}
            option={optItems}
            defaultValue={
              values.list_item_po
                ? optItems.find((v) => values.list_item_po === v.value)
                : defaultDropdown
            }
            onChange={({ value }) => setFieldValue('list_item_po', value)}
          />

          <SelectSearch
            label="Metode Pembayaran"
            placeholder="Pilih Metode Pembayaran"
            option={optPembayaran}
            defaultValue={
              values.pembayaran
                ? optPembayaran.find((v) => values.pembayaran === v.value)
                : defaultDropdown
            }
            onChange={({ value }) => setFieldValue('pembayaran', value)}
          />

          <DatePicker
            selectsRange
            label="Tgl. Jatuh Tempo"
            placeholderText="Pilih Tanggal Jatuh Tempo"
            startDate={
              values.tgl_jatuh_tempo_start
                ? new Date(values.tgl_jatuh_tempo_start)
                : ''
            }
            endDate={
              values.tgl_jatuh_tempo_end
                ? new Date(values.tgl_jatuh_tempo_end)
                : ''
            }
            onChange={(dates) => {
              const [start, end] = dates;
              const startDate = start ? DateConvert(start).default : null;
              const endDate = end ? DateConvert(end).default : null;

              setFieldValue('tgl_jatuh_tempo_start', startDate);
              setFieldValue('tgl_jatuh_tempo_end', endDate);
            }}
            monthsShown={2}
          />

          <SelectSearch
            label="Status Approval"
            placeholder="Pilih Status Approval"
            option={optApproval}
            defaultValue={
              values.status_approval
                ? optApproval.find((v) => values.status_approval === v.value)
                : defaultDropdown
            }
            onChange={({ value }) => setFieldValue('status_approval', value)}
          />

          <SelectSearch
            label="Status Purchase Order"
            placeholder="Pilih Status Purchase Order"
            option={optStatusPO}
            defaultValue={
              values.status_purchase_order
                ? optStatusPO.find(
                    (v) => values.status_purchase_order === v.value
                  )
                : defaultDropdown
            }
            onChange={({ value }) =>
              setFieldValue('status_purchase_order', value)
            }
          />
        </FilterModal>
      )}
    </Formik>
  );
};
