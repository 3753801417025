import { Fragment, useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col, Tab, Nav, Card } from 'react-bootstrap';
import Axios from 'axios';
import { Formik } from 'formik';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  BackButton,
  ActionButton,
  InfoItemHorizontal,
} from 'components';
import { PermintaanSubkonProduksiApi } from 'api';
import {
  InfoDetailJobOrder,
  ModalFormKontrak,
  ModalFormRegistrasi,
  TableItemKontrak,
  TablePermintaan,
} from './Components';
import {
  TransferSubkonProduksiContext,
  withDropdownTransferSubkonContextProvider,
  withTransferSubkonProduksiProvider,
} from './Context';
import { useMockNumber } from './Hooks';
import { DateConvert } from 'utilities';

const NavTab = ({ eventKey, title }) => (
  <Nav.Item>
    <Nav.Link eventKey={eventKey}>{title}</Nav.Link>
  </Nav.Item>
);

const TabPane = ({ eventKey, component }) => (
  <Tab.Pane eventKey={eventKey}>{component}</Tab.Pane>
);

const DetailTransferSubkonProduksi = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id_permintaan_produksi, id_kontrak_subkon } = useParams();
  const [generateMockNumber] = useMockNumber();
  const {
    dataJobOrder,
    setJobOrder,
    modalForm,
    dataPermintaan,
    setDataPermintaan,
    setModalForm,
  } = useContext(TransferSubkonProduksiContext);

  const [dataKontrak, setDataKontrak] = useState({});
  const [isPageLoading, setPageLoading] = useState(true);
  const [alert, setAlert] = useState({
    variant: 'primary',
    text: '',
  });
  const isSubkonFin = dataKontrak?.ref_kontrak_subkon === 'ksf';
  // const title = isSubkonFin ? 'Subkon Finishing' : '';
  const title = 'Subkon Finishing';

  const getInitialData = () => {
    setPageLoading(true);

    Axios.all([
      PermintaanSubkonProduksiApi.single({ id_permintaan_produksi }),
      PermintaanSubkonProduksiApi.getSingleKontrakSubkon({ id_kontrak_subkon }),
    ])
      .then(
        Axios.spread((resPermintaan, resKontrakSubkon) => {
          const { subcon_finishing, history, ...jobOrder } =
            resPermintaan?.data?.data;

          setJobOrder(jobOrder ?? {});
          setDataKontrak(resKontrakSubkon?.data?.data ?? {});
          setDataPermintaan({
            sf: subcon_finishing?.map((item) => ({
              ...item,
              status_permintaan: item.status_permintaan ?? 'waiting',
            })),
          });
        })
      )
      .catch(() => setAlert({ text: 'Data gagal dimuat', variant: 'danger' }))
      .finally(() => setPageLoading(false));
  };

  useEffect(() => {
    setNavbarTitle('Transfer Subkon Produksi');
    getInitialData();
  }, []);

  const showModalKontrak = () =>
    setModalForm((prev) => ({
      ...prev,
      action: 'create',
      tab: isSubkonFin ? 'sf' : '',
      showKontrak: true,
      data: dataKontrak,
    }));

  return (
    <CRUDLayout>
      <Row className="d-flex align-items-end justify-content-between mb-2">
        <Col>
          <div>Detail Data Job Order </div>
        </Col>
        <Col className="d-flex justify-content-end align-items-end">
          <BackButton onClick={() => history.goBack()} />
        </Col>
      </Row>

      <Alert
        showCloseButton
        className="mt-2"
        show={!!alert?.text?.length}
        variant={alert.variant}
        text={alert.text}
        onClose={() => setAlert((prev) => ({ ...prev, text: '' }))}
      />

      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <Fragment>
          <InfoDetailJobOrder data={dataJobOrder} />

          <Card className="mt-4">
            <Tab.Container defaultActiveKey="detail">
              {/* Tab Navigation */}
              <Card.Header>
                <Nav variant="tabs" defaultActiveKey="detail">
                  <NavTab eventKey="detail" title={title} />
                </Nav>
              </Card.Header>

              {/* Tab Content */}
              <Card.Body>
                <Tab.Content>
                  <TabPane
                    eventKey="detail"
                    component={
                      <Fragment>
                        <Row className="d-flex justify-content-between align-items-end mb-2">
                          <Col>
                            <div
                              className="font-weight-bold"
                              style={{ fontSize: '.9rem' }}
                            >
                              List Data Permintaan {title}
                            </div>
                          </Col>
                          <Col className="d-flex justify-content-end align-items-end">
                            <ActionButton
                              size="sm"
                              text="Lihat Kontrak"
                              onClick={showModalKontrak}
                            />
                          </Col>
                        </Row>

                        <TablePermintaan
                          tab={isSubkonFin ? 'sf' : ''}
                          tipe="readonly"
                          data={dataPermintaan.sf ?? []}
                        />

                        <div
                          className="font-weight-bold mt-4"
                          style={{ fontSize: '.9rem' }}
                        >
                          List Data Transfer {title}
                        </div>

                        <InfoItemHorizontal
                          width={300}
                          className="mt-1"
                          label="Tgl. Transfer Subkon Produksi"
                          text={
                            dataKontrak.tgl_kontrak_subkon
                              ? DateConvert(
                                  new Date(dataKontrak.tgl_kontrak_subkon)
                                ).detail
                              : '-'
                          }
                        />

                        <InfoItemHorizontal
                          width={300}
                          label="No. Transfer Subkon Produksi"
                          text={dataKontrak.no_kontrak_subkon ?? '-'}
                        />

                        <InfoItemHorizontal
                          width={300}
                          label="Vendor"
                          text={dataKontrak.nama_vendor ?? '-'}
                          className="mb-2"
                        />

                        <Formik initialValues={dataKontrak}>
                          {() => <TableItemKontrak readOnly />}
                        </Formik>
                      </Fragment>
                    }
                  />
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </Card>
        </Fragment>
      )}

      {modalForm.showRegistrasi && (
        <ModalFormRegistrasi generateMockNumber={generateMockNumber} />
      )}

      {modalForm.showKontrak && <ModalFormKontrak readOnly />}
    </CRUDLayout>
  );
};

export default withDropdownTransferSubkonContextProvider(
  withTransferSubkonProduksiProvider(DetailTransferSubkonProduksi)
);
