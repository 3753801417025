import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { IoArrowBackOutline } from 'react-icons/io5';
import Axios from 'axios';

import { CRUDLayout, DataStatus, Alert } from '../../../components';

import { VendorApi } from '../../../api';
import { FormRegisterVendor, FormListItem } from './Tambah';

const TambahVendor = ({ setNavbarTitle }) => {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [dataKode, setDataKode] = useState([]);
  const [idVendor, setIdVendor] = useState('');
  const [jenisVendor, setJenisVendor] = useState([]);
  const [alertHandler, setAlertHandler] = useState({
    show: false,
    variant: 'primary',
    text: 'Data Vendor Berhasil Ditambah',
  });

  // fetch data
  const getInitialData = () => {
    setIsLoading(true);

    Axios.all([VendorApi.getKode()])
      .then(
        Axios.spread((kode) => {
          setDataKode(kode.data.data);
        })
      )
      .catch((err) => alert(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    setNavbarTitle('Tambah Vendor');
    getInitialData();

    return () => {
      setIsLoading(false);
    };
  }, [setNavbarTitle]);

  const PageIndicator = () => {
    const PageIndicatorItems = ({ number, text, active, onClick }) => (
      <div
        className="d-flex flex-column justify-content-center align-items-center mx-2"
        style={{
          width: '80px',
          cursor: onClick ? 'pointer' : 'default',
        }}
        onClick={onClick}
      >
        <div
          className={`d-flex justify-content-center align-items-center mb-2 rounded-pill text-white shadow-sm ${
            active && 'bg-primary'
          }`}
          style={{
            height: '30px',
            width: '30px',
            background: !active && '#C1C1C1',
          }}
        >
          {number}
        </div>
        <small>{text}</small>
      </div>
    );

    return (
      <div
        className="d-flex justify-content-center align-items-center mb-2"
        style={{ position: 'relative' }}
      >
        <div
          className="bg-secondary"
          style={{
            height: '1px',
            width: '280px',
            position: 'absolute',
            marginTop: '-25px',
            opacity: 0.3,
            zIndex: 1,
          }}
        ></div>

        <div
          className="d-flex justify-content-center align-items-center"
          style={{ zIndex: 2 }}
        >
          <PageIndicatorItems
            number="1"
            text="Register"
            active={page === 1 && true}
          />

          <PageIndicatorItems
            number="2"
            text="List Item"
            active={page === 2 && true}
          />
        </div>
      </div>
    );
  };

  const Form = () => {
    return (
      <div className="d-flex flex-column align-items-center">
        <PageIndicator />
        <div className="col col-md-12 p-0">
          <hr />
          <div className="d-flex justify-content-between align-items-center mb-3">
            {page === 1 && <b>Register Vendor</b>}
            {page === 2 && <b>List Item yang disediakan Vendor</b>}

            <div>
              <Button
                variant="outline-secondary"
                size="sm"
                className="mx-1 mb-1"
                onClick={() => history.goBack()}
              >
                <IoArrowBackOutline size={20} />
                <span className="ml-2">Kembali Ke Tabel</span>
              </Button>
            </div>
          </div>
          <Alert
            show={alertHandler.show}
            variant={alertHandler.variant}
            text={alertHandler.text}
            showCloseButton={true}
            onClose={() => setAlertHandler({ ...alertHandler, show: false })}
          />
        </div>
        <div className="col col-md-12 mb-3 p-3 bg-white rounded shadow-sm">
          {page === 1 && (
            <FormRegisterVendor
              setPage={setPage}
              dataKode={dataKode}
              idVendor={idVendor}
              setIdVendor={setIdVendor}
              jenisVendor={jenisVendor}
              setJenisVendor={setJenisVendor}
              setAlertHandler={setAlertHandler}
            />
          )}
          {page === 2 && (
            <FormListItem
              idVendor={idVendor}
              jenisVendor={jenisVendor}
              setAlertHandler={setAlertHandler}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <CRUDLayout>
      {isLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <Form />
      )}
    </CRUDLayout>
  );
};

export default TambahVendor;
