import { useContext } from 'react';
import {
  ActionButton,
  DatePicker,
  InfoItemHorizontal,
  Input,
  InputCurrency,
  SelectSearch,
  TextArea,
} from 'components';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Modal } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import { DateConvert, RupiahConvert } from 'utilities';
import { InputImages } from './InputImages';
import { DropdownSeleksiVendorContext } from '../Context';

const InfoSectionModal = () => {
  const { values } = useFormikContext();
  return (
    <>
      <InfoItemHorizontal
        label="Tgl. Seleksi Vendor"
        text={DateConvert(new Date(values.tgl_seleksi_vendor)).detail}
      />
      <InfoItemHorizontal
        label="No. Seleksi Vendor"
        text={values.no_seleksi_vendor}
      />
      <InfoItemHorizontal label="Kode Item" text={values.kode_item} />
      <InfoItemHorizontal label="Nama Item" text={values.nama_item} />
      <InfoItemHorizontal label="Satuan Beli" text={values.satuan_beli} />
      <InfoItemHorizontal label="Vendor" text={values.nama_vendor} />
      <InfoItemHorizontal label="Qty. Seleksi Vendor" text={values.qty_sv} />
      <InfoItemHorizontal
        label="Harga Satuan Kesepakatan"
        text={RupiahConvert(String(values.harga_kesepakatan)).getWithComaLabel}
      />
      <InfoItemHorizontal
        label="Total Harga"
        text={
          RupiahConvert(String(values.harga_kesepakatan * values.qty_sv))
            .getWithComaLabel
        }
      />
      <InfoItemHorizontal
        label="Catatan Seleksi Vendor"
        text={values.keterangan ?? ''}
      />
    </>
  );
};

const FormSectionModal = ({ generateMockNumber }) => {
  const { dropdown, loading } = useContext(DropdownSeleksiVendorContext);

  const { values, errors, touched, setFieldValue, handleChange } =
    useFormikContext();

  return (
    <>
      <InfoItemHorizontal label="Kode Item" text={values?.kode_item} />
      <InfoItemHorizontal label="Nama Item" text={values?.nama_item} />
      <InfoItemHorizontal label="Satuan Beli" text={values?.satuan_beli} />
      <InfoItemHorizontal
        label="Qty. Purchase Request"
        text={parseFloat(values?.qty_pr)}
      />
      <InfoItemHorizontal
        label="Total Qty. Telah Diseleksi"
        text={parseFloat(values?.qty_ordered)}
      />
      <InfoItemHorizontal
        label="HPS Beli"
        text={
          values?.harga_perkiraan_sendiri
            ? RupiahConvert(values.harga_perkiraan_sendiri).getWithComaLabel
            : '-'
        }
      />

      <Row className="mt-3">
        <Col>
          <DatePicker
            label="Tgl. Seleksi Vendor"
            placeholder="Pilih Tanggal Seleksi Vendor"
            dateFormat="dd/MM/yyyy"
            onChange={async (date) => {
              setFieldValue('tgl_seleksi_vendor', date);

              if (!values.id_seleksi_vendor) {
                const mockNumberResult = await generateMockNumber(date);
                setFieldValue('no_seleksi_vendor', mockNumberResult);
              }
            }}
            selected={
              values?.tgl_seleksi_vendor
                ? new Date(values?.tgl_seleksi_vendor)
                : null
            }
            error={errors.tgl_seleksi_vendor && touched.tgl_seleksi_vendor}
            errorText={errors.tgl_seleksi_vendor}
          />
        </Col>
        <Col>
          <Input
            readOnly
            label="No. Seleksi Vendor"
            placeholder="Pilih Tanggal Seleksi Vendor"
            value={values?.no_seleksi_vendor}
          />
        </Col>
      </Row>
      <SelectSearch
        key={`${values?.id_vendor}${errors.id_vendor}${touched.id_vendor}`}
        loading={loading}
        label="Vendor"
        placeholder="Pilih Vendor"
        option={dropdown.vendor ?? []}
        defaultValue={dropdown.vendor.find(
          (v) => v.value === values?.id_vendor
        )}
        onChange={(vendor) => {
          setFieldValue('id_vendor', vendor.value);
          setFieldValue('nama_vendor', vendor.label);
        }}
        error={errors.id_vendor && touched.id_vendor}
        errorText={!!touched.id_vendor && errors.id_vendor}
      />
      <Row>
        <Col>
          <InputCurrency
            label="Qty. Seleksi Vendor"
            prefix=""
            className="text-right"
            decimalScale={4}
            value={parseFloat(values?.qty_sv)}
            onChange={(v) => setFieldValue('qty_sv', v)}
            error={errors.qty_sv && touched.qty_sv}
            errorText={touched.qty_sv && errors.qty_sv}
          />
        </Col>
        <Col>
          <InputCurrency
            fixedDecimalScale
            label="Harga Satuan Kesepakatan"
            className="text-right"
            value={parseFloat(values?.harga_kesepakatan)}
            onChange={(v) => setFieldValue('harga_kesepakatan', v)}
            error={errors.harga_kesepakatan && touched.harga_kesepakatan}
            errorText={errors.harga_kesepakatan}
          />
        </Col>
      </Row>
      <Row className="d-flex justify-content-end align-items-end">
        <Col md={6}>
          <InputCurrency
            fixedDecimalScale
            key={`${values?.qty_pr}${values?.harga_perkiraan_sendiri}`}
            readOnly
            label="Total Harga"
            value={parseFloat(
              parseFloat(values?.qty_sv) * parseFloat(values?.harga_kesepakatan)
            )}
            className="text-right"
          />
        </Col>
      </Row>
      <TextArea
        name="keterangan"
        value={values?.keterangan}
        onChange={handleChange}
        label="Catatan Seleksi Vendor"
        rows={5}
      />
    </>
  );
};

export const ModalItemSeleksiVendor = ({
  action = 'create',
  type = 'form',
  show,
  data,
  generateMockNumber,
  onSubmitModalItem = null,
  setModal,
}) => {
  const onHide = (resetForm) => {
    resetForm();
    setModal({ show: false, data: null });
  };

  const initialValues = {
    id_seleksi_vendor: data?.id_seleksi_vendor ?? null,
    id_purchase_request_detail: data?.id_purchase_request_detail,
    id_purchase_request: data?.id_purchase_request,
    id_vendor: data?.id_vendor ?? '',
    nama_vendor: data?.nama_vendor ?? '',
    tgl_seleksi_vendor: data?.tgl_seleksi_vendor ?? '',
    no_seleksi_vendor: data?.no_seleksi_vendor ?? '',
    harga_kesepakatan: data?.harga_kesepakatan ?? '',
    qty_sv: data?.qty_sv ?? '',
    keterangan: data?.keterangan ?? '',
    images: data?.images ?? [],

    kode_item: data?.kode_item,
    nama_item: data?.nama_item,
    id_item_buaso: data?.id_item_buaso ?? '',
    satuan_beli: data?.satuan_beli ?? '0',
    qty_pr: data?.qty_pr ?? '0',
    qty_ordered: data?.qty_ordered ?? '0',
    harga_perkiraan_sendiri: data?.harga_perkiraan_sendiri ?? '0',
  };
  const validationSchema = new Yup.ObjectSchema({
    tgl_seleksi_vendor: Yup.string().required('Pilih tanggal seleksi vendor'),
    no_seleksi_vendor: Yup.string().required('Pilih tanggal seleksi vendor'),
    id_vendor: Yup.string().required('Pilih vendor'),
    qty_sv: Yup.string().required('Masukkan Qty. seleksi vendor'),
    harga_kesepakatan: Yup.string().required('Masukkan harga kesepakatan'),
  });

  const onSubmit = async (values, { resetForm }) => {
    await onSubmitModalItem(values);
    onHide(resetForm);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, handleSubmit, resetForm, isSubmitting }) => {
        const ACTION_TITLE = {
          create: 'Tambah',
          update: 'Ubah',
          detail: 'Detail',
        };

        return (
          <Modal show={show} size="lg" onHide={() => onHide(resetForm)}>
            <Modal.Header closeButton>
              {ACTION_TITLE[action] ?? ''} Data Seleksi Vendor
            </Modal.Header>

            <Modal.Body>
              {type === 'form' && (
                <FormSectionModal generateMockNumber={generateMockNumber} />
              )}
              {type === 'readOnly' && <InfoSectionModal />}
              <InputImages
                readOnly={type === 'readOnly'}
                deleteButton={type === 'form'}
                label="Dokumen Seleksi Vendor"
                images={values.images}
                setImages={(updatedFiles) =>
                  setFieldValue('images', updatedFiles)
                }
              />
            </Modal.Body>

            <Modal.Footer>
              <Row className="d-flex justify-content-end align-items-center">
                {type === 'form' && (
                  <ActionButton
                    text="Simpan"
                    onClick={handleSubmit}
                    loading={isSubmitting}
                  />
                )}
                {type === 'readOnly' && (
                  <ActionButton
                    text="Tutup"
                    className="btn-light"
                    onClick={() => onHide(resetForm)}
                  />
                )}
              </Row>
            </Modal.Footer>
          </Modal>
        );
      }}
    </Formik>
  );
};
