import { useEffect, useState } from 'react';
import { ButtonGroup, Col, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import {
  ActionButton,
  Alert,
  ApprovalStatusButton,
  CreateButton,
  DataStatus,
  FilterButton,
  InputSearch,
  Pagination,
  ReadButton,
  Table,
  Td,
  TdFixed,
  Th,
  ThFixed,
  UpdateButton,
} from 'components';
import { useSessionStorage } from 'hooks';
import { debounce } from 'lodash';
import { DateConvert, RupiahConvert, TableNumber } from 'utilities';
import { PurchaseOrderApi } from 'api';
import {
  ItemPurchaseOrderCollapse,
  ModalFilterPurchaseOrder,
  ModalKonfirmasiUbahStatusPO,
} from './Components';
import { withDropdownPurchaseOrderContextProvider } from './Contexts';
import { tglInputFormat, calculatePageGrandTotal } from './Utils';

const PurchaseOrderList = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const paginationKey = `${location.pathname}_pagination`;

  const [isPageLoading, setIsPageLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [data, setData] = useState([]);
  const [modalStatusPO, setModalStatusPO] = useState({
    show: false,
    data: null,
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [pagination, setPagination] = useSessionStorage(paginationKey, {
    q: '',
    page: '1',
    per_page: '10',
    total_page: '1',
    data_count: '0',

    active: false,
    tgl_input_start: undefined,
    tgl_input_end: undefined,
    tgl_purchase_order_start: undefined,
    tgl_purchase_order_end: undefined,
    id_jabatan_pengaju: undefined,
    id_karyawan_pengaju: undefined,
    id_vendor: undefined,
    list_item_po: undefined,
    pembayaran: undefined,
    tgl_jatuh_tempo_start: undefined,
    tgl_jatuh_tempo_end: undefined,
    status_approval: undefined,
    status_purchase_order: undefined,
  });

  const { total_page, data_count, active, ...query } = pagination;

  const getInitialData = () => {
    setIsPageLoading(true);

    PurchaseOrderApi.page({
      ...query,
      list_item_po: query.list_item_po ? `${query.list_item_po}:` : undefined,
    })
      .then(({ data }) => {
        setData(data.data);
        setPagination((prev) => ({
          ...prev,
          data_count: data.data_count,
          total_page: data.total_page,
        }));
      })
      .catch((err) =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: `Data gagal dimuat ${err.response.data.message}`,
        })
      )
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState?.alert) {
      setAlertConfig(locationState.alert);
    }
  };

  const dismissAlert = () => {
    history.replace(location?.pathname, { alert: undefined });
    setAlertConfig({ ...alertConfig, show: false });
  };

  useEffect(() => {
    setNavbarTitle('Purchase Order');
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    pagination.q,
    pagination.page,
    pagination.per_page,
    pagination.tgl_input_start,
    pagination.tgl_input_end,
    pagination.tgl_purchase_order_start,
    pagination.tgl_purchase_order_end,
    pagination.id_jabatan_pengaju,
    pagination.id_karyawan_pengaju,
    pagination.id_vendor,
    pagination.list_item_po,
    pagination.pembayaran,
    pagination.tgl_jatuh_tempo_start,
    pagination.tgl_jatuh_tempo_end,
    pagination.status_approval,
    pagination.status_purchase_order,
  ]);

  const TableContent = () => {
    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <div className="mt-2" style={{ fontSize: '.9rem' }}>
          List Data Purchase Order
        </div>
        <Table>
          <thead>
            <tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Tgl. Input Data</ThFixed>
              <ThFixed>Informasi Purchase Order</ThFixed>
              <ThFixed>Pembuat Purchase Order</ThFixed>
              <Th>Vendor</Th>
              <Th style={{ width: '300px' }}>Item Purchase Order</Th>
              <ThFixed>Grand Total</ThFixed>
              <ThFixed>Metode Pembayaran</ThFixed>
              <ThFixed>Tgl. Jatuh Tempo</ThFixed>
              <ThFixed>Status Approval</ThFixed>
              <ThFixed>Status PO</ThFixed>
            </tr>
          </thead>
          <tbody>
            {data.map((val, index) => {
              const isStatusOpen = val.status_purchase_order === 'open' ?? true;

              return (
                <tr key={index}>
                  <Td className="text-center">
                    {TableNumber(pagination.page, pagination.per_page, index)}
                  </Td>
                  <Td>
                    <ButtonGroup>
                      <ReadButton
                        className="m-0"
                        size="sm"
                        onClick={() =>
                          history.push(
                            `/transaksi/purchase-order/detail/${val.id_purchase_order}`
                          )
                        }
                      />

                      {val.status_approval === 'REV' && (
                        <UpdateButton
                          className="m-0"
                          size="sm"
                          onClick={() =>
                            history.push(
                              `/transaksi/purchase-order/ubah/${val.id_purchase_order}`,
                              { id_vendor: val.id_vendor }
                            )
                          }
                        />
                      )}
                    </ButtonGroup>
                  </Td>

                  <TdFixed className="text-nowrap">
                    {tglInputFormat({ created_at: val.created_at })}
                  </TdFixed>

                  <Td>
                    <div>
                      {val.tgl_purchase_order
                        ? DateConvert(new Date(val.tgl_purchase_order))
                            .defaultDMY
                        : '-'}
                    </div>
                    <div>{val.no_purchase_order ?? '-'}</div>
                  </Td>

                  <Td style={{ minWidth: '160px' }}>
                    <div>{val.nama_karyawan_pengaju}</div>
                    <div>{val.nama_jabatan_pengaju}</div>
                  </Td>

                  <Td style={{ minWidth: '180px' }}>
                    {val.nama_vendor ?? '-'}
                  </Td>

                  <Td style={{ minWidth: '300px' }}>
                    <ItemPurchaseOrderCollapse data={val.detail ?? []} />
                  </Td>

                  <Td textRight>
                    {
                      RupiahConvert(
                        String(calculatePageGrandTotal(val.detail ?? []))
                      ).detail
                    }
                  </Td>

                  <Td>{val.pembayaran ?? '-'}</Td>

                  <TdFixed className="text-nowrap">
                    {val.tgl_jatuh_tempo
                      ? DateConvert(new Date(val.tgl_jatuh_tempo)).defaultDMY
                      : '-'}
                  </TdFixed>

                  <TdFixed>
                    <ApprovalStatusButton
                      status={val.status_approval}
                      defaultStatus="PEN"
                    />
                  </TdFixed>

                  <TdFixed>
                    <ActionButton
                      className="btn-block"
                      variant={isStatusOpen ? 'success' : 'danger'}
                      text={isStatusOpen ? 'OPEN' : 'CLOSED'}
                      onClick={
                        isStatusOpen
                          ? () => setModalStatusPO({ show: true, data: val })
                          : null
                      }
                    />
                  </TdFixed>
                </tr>
              );
            })}
          </tbody>
        </Table>

        <Pagination
          dataLength={pagination.per_page}
          dataNumber={
            pagination.page * pagination.per_page - pagination.per_page + 1
          }
          dataPage={
            pagination.data_count < pagination.per_page
              ? pagination.data_count
              : pagination.page * pagination.per_page
          }
          dataCount={pagination.data_count}
          currentPage={pagination.page}
          totalPage={pagination.total_page}
          onPaginationChange={({ selected }) =>
            setPagination({
              ...pagination,
              page: selected + 1,
            })
          }
          onDataLengthChange={(e) =>
            setPagination({
              ...pagination,
              page: 1,
              per_page: e.target.value,
            })
          }
        />
      </>
    );
  };

  return (
    <>
      <Row className="mb-1">
        <Col className="d-flex flex-row justify-space-between align-items-center">
          <InputSearch
            defaultValue={pagination.q}
            onChange={debounce(
              (e) =>
                setPagination((prv) => ({
                  ...prv,
                  q: e.target.value,
                  page: 1,
                })),
              1500
            )}
          />
          <FilterButton
            active={pagination.active}
            onClick={() => setShowFilter((prev) => !prev)}
          />
        </Col>
        <Col className="d-flex justify-content-end">
          <CreateButton
            onClick={() =>
              history.push('/transaksi/purchase-order/seleksi-vendor-list')
            }
          />
        </Col>
      </Row>

      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => dismissAlert()}
      />

      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <TableContent />
      )}

      <ModalKonfirmasiUbahStatusPO
        show={modalStatusPO.show}
        data={modalStatusPO.data}
        onClose={() => setModalStatusPO({ show: false, data: null })}
        setAlertConfig={setAlertConfig}
        getInitialData={getInitialData}
      />

      <ModalFilterPurchaseOrder
        show={showFilter}
        setShow={setShowFilter}
        filter={pagination}
        setFilter={setPagination}
      />
    </>
  );
};

export default withDropdownPurchaseOrderContextProvider(PurchaseOrderList);
