import { useState } from 'react';
import { DateConvert } from 'utilities';
import { SeleksiVendorApi } from 'api';

const formatMonthYear = (date) => {
  const fmtDate = DateConvert(new Date(date));

  return `${fmtDate.defaultMonth}/${fmtDate.defaultYear}`;
};

const getNomorSeleksiVendor = async (date) => {
  const tanggal = DateConvert(date).default;

  return await SeleksiVendorApi.getNomor({ tanggal })
    .then((res) => res.data.data)
    .catch(() => alert('Gagal memuat nomor Seleksi Vendor'));
};

export const useMockNumber = (purchaseRequestList) => {
  const [mockNumber, setMockNumber] = useState({});

  const generateMockNumber = async (date) => {
    const selectedMonthYear = formatMonthYear(date);

    if (mockNumber.hasOwnProperty(selectedMonthYear)) {
      const count =
        purchaseRequestList?.filter(
          (val) => formatMonthYear(val.tgl_seleksi_vendor) === selectedMonthYear
        ).length ?? 0;

      const splitSeleksiVendorNumber = mockNumber[selectedMonthYear].split('/');
      const lastIndex = splitSeleksiVendorNumber.length - 1;
      const incrementLastNumber = +splitSeleksiVendorNumber[lastIndex] + count;
      const formatIncrementLastNumber = ('000000' + incrementLastNumber).slice(
        -6
      );
      splitSeleksiVendorNumber.splice(lastIndex, 1, formatIncrementLastNumber);

      return splitSeleksiVendorNumber.join('/');
    } else {
      const serverNumber = await getNomorSeleksiVendor(date);
      setMockNumber((prev) => ({ ...prev, [selectedMonthYear]: serverNumber }));
      return serverNumber;
    }
  };

  return [generateMockNumber];
};
