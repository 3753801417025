import Services from 'services';

class HargaKubikasiKayuApi {
  getPage(params) {
    return Services.get('/harga_kubikasi_kayu/page/', { params });
  }
  getDrodpownJenisKayu() {
    return Services.get('/jenis_kayu/');
  }

  getDrodpownPartKayu() {
    return Services.get('/part_kayu/');
  }

  getSingle(params) {
    return Services.get('/harga_kubikasi_kayu/single', { params });
  }
  save(data) {
    return Services.post('/harga_kubikasi_kayu', data);
  }
}

export default new HargaKubikasiKayuApi();
