import Services from 'services';

class KontrakPrelimApi {
  page(value) {
    return Services.get('/kontrak_prelim/page', { params: { ...value } });
  }

  single(value) {
    return Services.get('/kontrak_prelim/single', { params: { ...value } });
  }

  dropdown(value) {
    return Services.get('/kontrak_prelim/dropdown', { params: { ...value } });
  }

  no_baru(value) {
    return Services.get('/kontrak_prelim/no_baru', { params: { ...value } });
  }

  create(value) {
    return Services.post('/kontrak_prelim', value);
  }

  permintaan_page(value) {
    return Services.get('/permintaan_prelim/page', { params: { ...value } });
  }

  permintaan_single(value) {
    return Services.get('/permintaan_prelim/single', { params: { ...value } });
  }
}

export default new KontrakPrelimApi();
