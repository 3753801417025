import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { debounce } from 'lodash';
import { Row, Col } from 'react-bootstrap';
import {
  InputSearch,
  Alert,
  Pagination,
  Th,
  Td,
  DataStatus,
  Table,
  CreateButton,
  ReadButton,
  FilterButton,
} from 'components';
import { DateConvert, RupiahConvert, TableNumber } from 'utilities';
import { PermintaanUpahProduksiApi } from 'api';
import { useIsGuest, useSessionStorage } from 'hooks';
import {
  RincianPekerjaanCollapse,
  ModalFilterTransferUpahProduksi,
} from './Components';
import { calcGrandTotal } from './Helpers';
import { withDropdownTransferUpahContextProvider } from './Context';

const TransferUpahProduksiList = ({ setNavbarTitle }) => {
  const title = 'Transfer Upah Produksi';
  const history = useHistory();
  const location = useLocation();
  const isGuest = useIsGuest();
  const PAGINATION_KEY = `${location.pathname}_pagination`;

  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useSessionStorage(PAGINATION_KEY, {
    q: '',
    page: '1',
    per_page: '10',
    data_count: '0',
    total_page: '1',

    active: false,
    tgl_job_order_start: undefined,
    tgl_job_order_end: undefined,
    tgl_kontrak_upah_start: undefined,
    tgl_kontrak_upah_end: undefined,
    id_barang_jadi: undefined,
    id_vendor: undefined,
  });
  const [showFilter, setShowFilter] = useState(false);
  const [data, setData] = useState([]);
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsLoading(true);

    PermintaanUpahProduksiApi.page({
      q: pagination.q,
      page: pagination.page,
      per_page: pagination.per_page,
      tgl_job_order_start: pagination.tgl_job_order_start,
      tgl_job_order_end: pagination.tgl_job_order_end,
      tgl_kontrak_upah_start: pagination.tgl_kontrak_upah_start,
      tgl_kontrak_upah_end: pagination.tgl_kontrak_upah_end,
      id_barang_jadi: pagination.id_barang_jadi,
      id_vendor: pagination.id_vendor,
    })
      .then((res) => {
        setData(res?.data?.data ?? []);
        setPagination((prev) => ({
          ...prev,
          total_page: res?.data?.total_page,
          data_count: res?.data?.data_count,
        }));
      })
      .catch(() => {
        setAlertConfig({
          variant: 'danger',
          text: 'Data gagal dimuat',
        });
      })
      .finally(() => setIsLoading(false));
  };

  const searchHandler = (e) => {
    const searchKey = e.target.value;

    setPagination({
      ...pagination,
      q: searchKey,
      page: '1',
    });
  };

  const checkAlert = () => {
    if (location?.state?.alert) {
      setAlertConfig(location?.state?.alert);
    }
  };

  const dismissAlert = () => {
    setAlertConfig({ variant: 'primary', text: '' });

    const locationState = location.state;
    let state = { ...locationState };
    delete state.alert;
    history.replace({ ...state });
  };

  useEffect(() => {
    setNavbarTitle(title);
    getInitialData();
    checkAlert();
  }, [
    setNavbarTitle,
    pagination.q,
    pagination.page,
    pagination.per_page,
    pagination.tgl_job_order_start,
    pagination.tgl_job_order_end,
    pagination.tgl_kontrak_upah_start,
    pagination.tgl_kontrak_upah_end,
    pagination.id_barang_jadi,
    pagination.id_vendor,
  ]);

  const TableContent = () => {
    return (
      <>
        <div className="font-weight-bold mt-2" style={{ fontSize: '.9rem' }}>
          List Data Transfer Upah Produksi
        </div>

        <Table>
          <thead>
            <tr className="text-center">
              <Th>No</Th>
              {!isGuest && <Th>Aksi</Th>}
              <Th>Informasi Job Order</Th>
              <Th style={{ minWidth: 300 }}>Item Job Order</Th>
              <Th>Informasi Transfer Upah</Th>
              <Th style={{ minWidth: 180 }}>Vendor</Th>
              <Th style={{ minWidth: 250 }}>Rincian Pekerjaan</Th>
              <Th>PPN</Th>
              <Th style={{ minWidth: 150 }}>Total Harga</Th>
              <Th>DP</Th>
              <Th>Retensi</Th>
            </tr>
          </thead>

          <tbody>
            {data.map((val, index) => (
              <tr key={index}>
                <Td className="text-center align-middle">
                  {TableNumber(pagination.page, pagination.per_page, index)}
                </Td>

                <Td className="text-center align-middle">
                  <ReadButton
                    size="sm"
                    onClick={() =>
                      history.push(
                        `/transaksi/transfer-upah-produksi/detail/${val.id_permintaan_produksi}/${val.id_kontrak_upah}`
                      )
                    }
                  />
                </Td>

                <Td className="align-middle">
                  <div>
                    {val.tgl_job_order
                      ? DateConvert(new Date(val.tgl_job_order)).detail
                      : '-'}
                  </div>
                  <div>{val.no_job_order}</div>
                </Td>

                <Td className="align-middle">{val.nama_barang_jadi}</Td>

                <Td className="align-middle">
                  <div>
                    {val.tgl_kontrak_upah
                      ? DateConvert(new Date(val.tgl_kontrak_upah)).detail
                      : '-'}
                  </div>
                  <div>{val.no_kontrak_upah}</div>
                </Td>

                <Td className="align-middle">{val.nama_vendor}</Td>

                <Td>
                  <RincianPekerjaanCollapse data={val.detail ?? []} />
                </Td>

                <Td textRight>{parseFloat(val?.ppn ?? 0)}%</Td>

                <Td textRight>
                  {
                    RupiahConvert(String(calcGrandTotal(val?.detail ?? [])))
                      .detail
                  }
                </Td>

                <Td textRight>{parseFloat(val?.dp ?? 0)}%</Td>

                <Td textRight>{parseFloat(val?.retensi ?? 0)}%</Td>
              </tr>
            ))}
          </tbody>
        </Table>

        <Pagination
          dataLength={pagination.per_page}
          dataNumber={
            pagination.page * pagination.per_page - pagination.per_page + 1
          }
          dataCount={pagination.data_count}
          currentPage={pagination.page}
          totalPage={pagination.total_page}
          onPaginationChange={({ selected }) =>
            setPagination((prev) => ({ ...prev, page: selected + 1 }))
          }
          onDataLengthChange={(e) =>
            setPagination((prev) => ({
              ...prev,
              page: 1,
              per_page: e.target.value,
            }))
          }
        />
      </>
    );
  };

  return (
    <>
      <Row className="d-flex align-items-center mb-2">
        <Col md="10" className="d-flex">
          <InputSearch
            defaultValue={pagination.q}
            onChange={debounce(searchHandler, 1500)}
          />
          <FilterButton
            active={pagination.active}
            onClick={() => setShowFilter(true)}
          />
        </Col>

        <Col className="d-flex justify-content-end align-items-end">
          <CreateButton
            onClick={() =>
              history.push(
                '/transaksi/transfer-upah-produksi/list-permintaan-produksi'
              )
            }
          />
        </Col>
      </Row>

      <Alert
        show={Boolean(alertConfig?.text?.length)}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={dismissAlert}
      />

      {isLoading === true ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : data.length > 0 ? (
        <TableContent />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      <ModalFilterTransferUpahProduksi
        show={showFilter}
        setShow={setShowFilter}
        filter={pagination}
        setFilter={setPagination}
      />
    </>
  );
};

export default withDropdownTransferUpahContextProvider(
  TransferUpahProduksiList
);
