import { Fragment, useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col, Tab, Nav, Card } from 'react-bootstrap';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  BackButton,
  ActionButton,
} from 'components';
import { PermintaanSubkonProduksiApi } from 'api';
import {
  InfoDetailJobOrder,
  ModalFormKontrak,
  ModalFormRegistrasi,
  ModalKonfirmasi,
  TabLabourCost,
  TabSubkonFinishing,
} from './Components';
import {
  TransferSubkonProduksiContext,
  withDropdownTransferSubkonContextProvider,
  withTransferSubkonProduksiProvider,
} from './Context';
import { useMockNumber } from './Hooks';
import axios from 'axios';

const NavTab = ({ eventKey, title }) => (
  <Nav.Item>
    <Nav.Link eventKey={eventKey}>{title}</Nav.Link>
  </Nav.Item>
);

const TabPane = ({ eventKey, component }) => (
  <Tab.Pane eventKey={eventKey}>{component}</Tab.Pane>
);

const TambahTransferSubkonProduksi = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id_permintaan_produksi } = useParams();
  const [generateMockNumber] = useMockNumber();
  const {
    dataTransfer,
    dataJobOrder,
    setJobOrder,
    modalForm,
    dataPermintaan,
    setDataPermintaan,
    setDataHistory,
    setModalKonfirmasi,
  } = useContext(TransferSubkonProduksiContext);

  const [isPageLoading, setPageLoading] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [alert, setAlert] = useState({
    variant: 'primary',
    text: '',
  });

  const onSubmit = () => {
    setSubmitting(true);

    const dataStatusPermintaan =
      dataPermintaan?.sf?.map((item) => ({
        id_permintaan_produksi_sf: item.id_permintaan_produksi_sf,
        status_permintaan: item.status_permintaan,
      })) ?? [];

    const dataKontrakSubkon = {
      data: Object.values(dataTransfer)
        .filter((item) => item.length)
        .flat(),
    };

    axios
      .all([
        PermintaanSubkonProduksiApi.updateStatusPermintaan(
          dataStatusPermintaan
        ),
        PermintaanSubkonProduksiApi.saveKontrakSubkon(dataKontrakSubkon),
      ])
      .then(() =>
        history.push('/transaksi/transfer-subkon-produksi', {
          alert: {
            variant: 'primary',
            text: 'Tambah Data Transfer Subkon Produksi Berhasil',
          },
        })
      )
      .catch(({ response }) =>
        setAlert({
          variant: 'danger',
          text: `Gagal Menyimpan Data Transfer Subkon Produksi ${
            response?.data?.message ?? ''
          }`,
        })
      )
      .finally(() => {
        setSubmitting(false);
        setModalKonfirmasi(false);
      });
  };

  const getInitialData = () => {
    setPageLoading(true);

    PermintaanSubkonProduksiApi.single({ id_permintaan_produksi })
      .then((res) => {
        const { subcon_finishing, history, ...jobOrder } = res?.data?.data;

        setJobOrder(jobOrder ?? {});
        setDataPermintaan({
          sf: subcon_finishing?.map((item) => ({
            ...item,
            status_permintaan: item.status_permintaan ?? 'waiting',
          })),
        });
        setDataHistory({
          sf: history ?? [],
        });
      })
      .catch(() => setAlert({ text: 'Data gagal dimuat', variant: 'danger' }))
      .finally(() => setPageLoading(false));
  };

  useEffect(() => {
    setNavbarTitle('Transfer Subkon Produksi');
    getInitialData();
  }, []);

  return (
    <CRUDLayout>
      <Row className="d-flex align-items-end justify-content-between mb-2">
        <Col>
          <div>Detail Data Job Order </div>
        </Col>
        <Col className="d-flex justify-content-end align-items-end">
          <BackButton onClick={() => history.goBack()} />
        </Col>
      </Row>

      <Alert
        showCloseButton
        className="mt-2"
        show={!!alert?.text?.length}
        variant={alert.variant}
        text={alert.text}
        onClose={() => setAlert((prev) => ({ ...prev, text: '' }))}
      />

      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : (
        <Fragment>
          <InfoDetailJobOrder data={dataJobOrder} />

          <Card className="mt-4">
            <Tab.Container defaultActiveKey="sf">
              {/* Tab Navigation */}
              <Card.Header>
                <Nav variant="tabs" defaultActiveKey="sf">
                  <NavTab eventKey="sf" title="Subkon Finishing" />
                </Nav>
              </Card.Header>

              {/* Tab Content */}
              <Card.Body>
                <Tab.Content>
                  <TabPane eventKey="sf" component={<TabSubkonFinishing />} />
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </Card>

          <Row className="d-flex justify-content-end align-items-center px-3 pt-4 pb-2 ">
            <ActionButton
              text="Simpan"
              disable={isSubmitting}
              loading={isSubmitting}
              onClick={() => setModalKonfirmasi(true)}
            />
          </Row>
        </Fragment>
      )}

      {modalForm.showRegistrasi && (
        <ModalFormRegistrasi generateMockNumber={generateMockNumber} />
      )}

      {modalForm.showKontrak && <ModalFormKontrak />}

      <ModalKonfirmasi handleSubmit={onSubmit} isSubmitting={isSubmitting} />
    </CRUDLayout>
  );
};

export default withDropdownTransferSubkonContextProvider(
  withTransferSubkonProduksiProvider(TambahTransferSubkonProduksi)
);
